import { useState } from 'react';
import { Right } from './atoms';
import { LabeledValue } from 'aqTypes';
import { Header4 } from '@ardoq/typography';
import { Select } from '@ardoq/select';
import { FlexBox } from '@ardoq/layout';

type ConvertAllSelectionRowProps = {
  intersectReferenceOptions: LabeledValue[];
  setAllEntryRows: (label: string) => void;
};

export const ConvertAllSelectionRow = ({
  intersectReferenceOptions,
  setAllEntryRows,
}: ConvertAllSelectionRowProps) => {
  const [label, setLabel] = useState('');

  const updateAllEntryRows = (newValue: number) => {
    const label = intersectReferenceOptions.find(
      ({ value }) => value === newValue
    )!.label;
    setLabel(label);
    setAllEntryRows(label);
  };

  return (
    <FlexBox gap="medium">
      <Header4>Set all selection boxes</Header4>
      <Right>
        <Select
          options={intersectReferenceOptions}
          backspaceRemovesValue={false}
          isClearable={false}
          value={label}
          onChange={({ value }: any) => updateAllEntryRows(value)}
        />
      </Right>
    </FlexBox>
  );
};
