import { DatasourceItem, RowItem } from 'components/EntityBrowser/types';
import {
  APIAttachment,
  APIDocumentArchiveFolder,
  ArdoqId,
} from '@ardoq/api-types';
import { RowType } from '@ardoq/table';

export type Document = APIAttachment &
  DatasourceItem<RowType.DOCUMENT | RowType.IMAGE | RowType.UNKOWN_FILE>;

export type DocumentBrowserEntity = Document | DocumentFolder;

export type DocumentBrowserRow = DocumentBrowserEntity | EmptyFolder;

export type DocumentFolder = APIDocumentArchiveFolder &
  RowItem & {
    children: DocumentBrowserRow[];
  };

export type EmptyFolder = DatasourceItem<RowType.EMPTY_FOLDER>;

export enum DocumentArchiveContext {
  ORG = 'DocumentArchiveContext/ORG',
  WS = 'DocumentArchiveContext/WS',
}

export enum DocumentArchiveMode {
  DEFAULT = 'DocumentArchiveMode/DEFAULT',
  FILE_PICKER = 'DocumentArchiveMode/FILE_PICKER',
}

export type FilterByType = {
  [RowType.IMAGE]: boolean;
  [RowType.DOCUMENT]: boolean;
  [RowType.UNKOWN_FILE]: boolean;
};

export type DocumentBrowserUIState = {
  context: DocumentArchiveContext;
  mode: DocumentArchiveMode;
  renameId: ArdoqId | null;
  previewId: ArdoqId | null;
  selected: ArdoqId[];
  expandedFoldersIds: ArdoqId[];
  searchPhrase: string;
  filterByType: FilterByType;
};

export type AttachmentWithContext = APIAttachment & {
  context: DocumentArchiveContext;
};
