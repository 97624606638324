import {
  StreamCollection,
  createStreamFromBackboneCollection,
} from 'streams/utils/streamUtils';
import workspaces from 'collections/workspaces';
import { Workspace } from '@ardoq/api-types';

export type WorkspacesState = StreamCollection<Workspace>;

export default createStreamFromBackboneCollection<Workspace>(
  workspaces.collection,
  [
    'name',
    'views',
    'lastModifiedByName',
    'permissions',
    'startView',
    'componentModel',
    'subdivisionMembership',
    'created',
    'comp-counter',
  ]
);
