import { ComponentType, useCallback, useEffect, useState } from 'react';
import { css } from 'styled-components';
import { omit } from 'lodash';
import { colors } from '@ardoq/design-tokens';

export enum Highlight {
  ACTIVE = 'Highlight/ACTIVE',
  FADE = 'Highlight/FADE',
  NONE = 'Highlight/NONE',
}

type HighlightMxinProps = {
  highlight?: Highlight;
};
export const highlightCss = css<HighlightMxinProps>`
  ${(props: HighlightMxinProps) =>
    props.highlight === Highlight.ACTIVE &&
    css`
      background-color: ${colors.blue95} !important;
    `}

  ${(props: HighlightMxinProps) =>
    props.highlight === Highlight.FADE &&
    css`
      transition: background-color 0.7s ease-out;
    `}
`;

export type HighlightStatusMap = Record<string, Highlight>;

export type highlightStatusMapProps = {
  setHighlights: (ids: string[]) => void;
  highlightStatusMap: HighlightStatusMap;
};

export const withHighlightStatusMap = <P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P & highlightStatusMapProps>
) => {
  return (props: P) => {
    const [highlightStatusMap, setHighlightStatusMap] =
      useState<HighlightStatusMap>({});

    const setActiveHighlights = useCallback((ids: string[]) => {
      setHighlightStatusMap(previousHighlightStatusMap => {
        const hashPartial = Object.fromEntries(
          ids.map(id => [id, Highlight.ACTIVE])
        );
        const nextHighlightStatusMap = {
          ...previousHighlightStatusMap,
          ...hashPartial,
        };

        return nextHighlightStatusMap;
      });
    }, []);

    useEffect(() => {
      const runTimer = (id: string) => {
        return setTimeout(() => {
          const currentHighlight = highlightStatusMap[id];

          if (currentHighlight === Highlight.ACTIVE) {
            const nextHighlightStatusMap = {
              ...highlightStatusMap,
              [id]: Highlight.FADE,
            };
            setHighlightStatusMap(nextHighlightStatusMap);
          }

          if (currentHighlight === Highlight.FADE) {
            const nextHighlightStatusMap = omit(highlightStatusMap, id);
            setHighlightStatusMap(nextHighlightStatusMap);
          }
        }, 700);
      };
      const timers = Object.keys(highlightStatusMap).map(runTimer);
      return () => {
        timers.forEach(clearTimeout);
      };
    }, [highlightStatusMap]);

    return (
      <WrappedComponent
        {...props}
        setHighlights={setActiveHighlights}
        highlightStatusMap={highlightStatusMap}
      />
    );
  };
};
