import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  Document,
  DocumentArchiveContext,
  DocumentBrowserRow,
  DocumentFolder,
  FilterByType,
} from 'components/DocumentBrowser/types';
import { RowType } from '@ardoq/table';
import { EmptyFolder } from './types';
import { groupBy } from 'lodash';
import { combineLatest } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  APIAttachment,
  APIFolderAttributes,
  ArdoqId,
  AssetType,
} from '@ardoq/api-types';
import { getAttachmentRowType } from './utils';
import documentArchiveAttachments$ from 'streams/documentArchive/documentArchiveAttachments$';
import documentArchiveFolders$ from 'streams/documentArchive/documentArchiveFolders$';
import { logError } from '@ardoq/logging';
import documentBrowserUI$ from './documentBrowserUI$';
import { logIfUsed } from '@ardoq/sentry';

const orEmptyFolder = (
  folder: DocumentFolder,
  children: DocumentBrowserRow[]
) =>
  children.length
    ? children
    : [
        {
          rowType: RowType.EMPTY_FOLDER,
          meta: { ...folder.meta, folderId: folder._id },
        } as EmptyFolder,
      ];

const buildFolderTree = (
  folders: DocumentFolder[],
  foldersByParent: {
    [k: string]: DocumentFolder[];
  },
  documentsByFolder: {
    [k: string]: Document[];
  },
  hideEmptyFolders: boolean
): DocumentFolder[] =>
  (folders || [])
    .map(folder => {
      const children = [
        ...buildFolderTree(
          foldersByParent[folder._id] || [],
          foldersByParent,
          documentsByFolder,
          hideEmptyFolders
        ),
        ...(documentsByFolder[folder._id] || []),
      ];
      if (hideEmptyFolders && children.length === 0) return null;
      return { ...folder, children: orEmptyFolder(folder, children) };
    })
    .filter(ExcludeFalsy);

const filterByContext =
  (context: DocumentArchiveContext) =>
  ({ context: itemContext }: { context: DocumentArchiveContext }) =>
    context === itemContext;

const getShowAllTypes = (filterByType: FilterByType) => {
  const numberOfFilters = Object.values(filterByType).length;
  const numberOfEnabledFilters =
    Object.values(filterByType).filter(ExcludeFalsy).length;
  return (
    numberOfEnabledFilters === numberOfFilters || numberOfEnabledFilters === 0
  );
};

const filterByTypeFn =
  (filterByType: FilterByType) =>
  ({ rowType }: { rowType: RowType }) =>
    Boolean(filterByType[rowType as RowType.DOCUMENT]) ||
    getShowAllTypes(filterByType);

const composeDocumentArchiveDataSource = (
  attachmentsById: {
    [key: string]: APIAttachment & { context: DocumentArchiveContext };
  },
  foldersById: {
    [key: string]: APIFolderAttributes & { context: DocumentArchiveContext };
  },
  context: DocumentArchiveContext,
  filterByType: FilterByType
) => {
  const documents = Object.values(attachmentsById)
    .filter(filterByContext(context))
    .map(enhanceAttachment)
    .filter(filterByTypeFn(filterByType));
  const folders = Object.values(foldersById)
    .filter(filterByContext(context))
    .map(enhanceFolder);

  /**
   * This function in needed because we do not support moving folders from workspace to org. The issue is that
   * attachment in ORG context is attempted to be put in a folder that only exists in WORKSPACE context, which
   * makes the attachment not show up in the document browser. This function allows ORG attachments which are in a folder
   * that is in the WORKSPACE context to be put in the root of the document browser so that they can be interacted with.
   */

  const hasFolderAndFolderIsInOrgContext = (folderId: ArdoqId | null) => {
    if (folderId === null) {
      return false;
    }
    const folder = foldersById[folderId];
    if (!folder) {
      return false;
    }
    if ('context' in folder && folder.context !== undefined) {
      return folder.context === context;
    }
    // Adding this line as folders usually have a context, but it is not enforced in the API.
    // If the logging shows that this is not the case, we can remove this line, and rework how we handle folders.
    logIfUsed('folder.context is undefined');
    return folder.resource.type === 'org';
  };

  const hideEmptyFolders = !getShowAllTypes(filterByType);
  const allFolderIds = Object.keys(foldersById);
  const documentsByFolder = groupBy(documents, doc =>
    hasFolderAndFolderIsInOrgContext(doc.folder) ? doc.folder : 'root'
  );
  const foldersByParent = groupBy(folders, folder =>
    allFolderIds.includes(folder.parent || '') ? folder.parent : 'root'
  );
  const folderTree = buildFolderTree(
    foldersByParent.root,
    foldersByParent,
    documentsByFolder,
    hideEmptyFolders
  );
  return [...folderTree, ...(documentsByFolder.root || [])];
};

const documentBrowserDataSource$ = combineLatest([
  documentArchiveAttachments$,
  documentArchiveFolders$,
  documentBrowserUI$,
]).pipe(
  map(
    ([
      { byId: attachmentsById },
      { byId: foldersById },
      { context, filterByType },
    ]) => ({
      dataSource: composeDocumentArchiveDataSource(
        attachmentsById,
        foldersById,
        context,
        filterByType
      ),
    })
  ),
  catchError((error, caught) => {
    logError(error, 'Error on composing document archive folder tree');
    return caught;
  })
);

const enhanceFolder = (folder: APIFolderAttributes): DocumentFolder => ({
  ...folder,
  rowType: RowType.FOLDER,
  children: [],
  meta: {
    folderId: folder.parent,
    level: 0,
    permissions: { canUpdate: true },
    assetType: AssetType.METAMODEL, // this makes no sense
  },
});

const enhanceAttachment = (attachment: APIAttachment): Document => ({
  ...attachment,
  rowType: getAttachmentRowType(attachment['content-type']),
  name: attachment.filename,
  meta: {
    folderId: attachment.folder,
    level: 0,
    permissions: { canUpdate: true },
    assetType: AssetType.METAMODEL, // this makes no sense
  },
});

export default documentBrowserDataSource$;
