import * as encodingUtils from '@ardoq/html';
import { info, modal } from '@ardoq/modal';
import {
  FAILED_UPDATE_ERROR_MESSAGE,
  NO_ACESS_TO_RESOURCE_MESSAGE,
} from 'consts';
import { ArdoqId } from '@ardoq/api-types';
import ForceReloadDialog from './ForceReloadDialog';
import { ForceReloadReason } from './actions';
import { showSupport } from 'utils/support';
import { logWarn } from '@ardoq/logging';
import { Reference } from 'aqTypes';

type ModelData = {
  id: ArdoqId;
  cid: string;
  name: string;
  url: string;
};

type UpdateProps = Pick<ModelData, 'name' | 'id' | 'url'>;

type GenericProps = {
  status: number;
  message: string;
} & ModelData;

enum ERROR_TYPE {
  UPDATE,
  GENERIC,
}

type ErrorMessageData = {
  errorType: ERROR_TYPE;
  status: number;
  modelData: ModelData;
  message: string;
};

const UpdateErrorMessage = ({ name, id, url }: UpdateProps) => (
  <>
    <p>
      The object {name} with the id {id},<br />
      and URL: {url} <br />
      was deleted by someone.
      <br />
      We cannot update it and have removed it.
    </p>
    <p>
      <strong>If this error continues, please refresh your browser.</strong>
    </p>
  </>
);

const GenericErrorExplanation = ({
  status,
  url,
  name,
  id,
  cid,
  message,
}: GenericProps) => (
  <>
    {status === 502 && (
      <>
        <p>
          We cannot connect to the server. This might be due to that the server
          is down or we are restarting the server
        </p>
        <p>
          Please wait and click retry and if it doesn&apos;t work you can notify
          us of this error.
        </p>
      </>
    )}
    <p>
      We will investigate this incident and learn what we did wrong so we can
      improve <b>Ardoq</b>.
    </p>
    <>
      <p>
        <strong>Additional details:</strong>
        <br />
        Object type: {url} <br />
        Object name: {encodingUtils.escapeHTML(name)}
        <br />
        Object IDs: {id} / {cid}
        {message ? (
          <>
            <br />
            <span>Description: {message}</span>
          </>
        ) : null}
      </p>
    </>
  </>
);

const getErrorMessage = ({
  errorType,
  status,
  modelData,
  message,
}: ErrorMessageData) => {
  const { name, id, cid, url } = modelData;
  return (
    <div>
      <p>We are very sorry for the inconvenience.</p>
      {errorType === ERROR_TYPE.UPDATE ? (
        <UpdateErrorMessage name={name} id={id} url={url} />
      ) : (
        <GenericErrorExplanation
          status={status}
          url={url}
          name={name}
          id={id}
          cid={cid}
          message={message}
        />
      )}
    </div>
  );
};

export const renderEventForbiddenError = () =>
  info({ title: 'No access', text: NO_ACESS_TO_RESOURCE_MESSAGE });

export const renderForceReload = (reason: ForceReloadReason) => {
  logWarn(
    Error(`Prompted user to refresh Ardoq ${reason}`),
    `Prompted user to refresh Ardoq ${reason}`,
    {
      reason: reason,
    }
  );
  modal<void>(() => <ForceReloadDialog reason={reason} />);
};

type SyncResponseErrorProps = {
  errorType: ERROR_TYPE;
  modelData: ModelData;
  status: number;
  responseText: string;
  message: string;
};

const getModelDetails = (model: ModelData, message: string) =>
  [
    '<strong>Additional details:</strong><br>',
    `Object type: ${model.url}<br/>Object name: `,
    encodingUtils.escapeHTML(model.name),
    `<br />Object ids: ${model.id}  / `,
    `${model.cid}'<br /><br />`,
    message ? `<br />Description: ${message}` : '',
  ]
    .join('')
    .replace(/<.*?>/gi, '\n');

type ValidationError = {
  field: string;
  message: string;
};

const parseMessage = (model: any) => {
  const validationErrors: ValidationError[] = model.validationErrors ?? [];

  const validationErrorDescriptionMessage = validationErrors
    .find(error => error.field === 'description')
    ?.message.replace(FAILED_UPDATE_ERROR_MESSAGE, '');

  const parsedMessage = parseMessageWithTryCatch(
    validationErrorDescriptionMessage ?? ''
  );

  return parsedMessage.message;
};

const parseMessageWithTryCatch = (message: string): { message: string } => {
  try {
    const parsedMessage = JSON.parse(message);
    return parsedMessage;
  } catch (e) {
    return { message: 'Missing error message' };
  }
};

export const toRenderSyncResponseProps = (
  model: any,
  updateError: boolean,
  status: number,
  responseText: string | undefined
) => ({
  errorType: updateError ? ERROR_TYPE.UPDATE : ERROR_TYPE.GENERIC,
  modelData: {
    id: model.id,
    cid: model.cid,
    name: model.name
      ? model.name()
      : (model as Reference).getName
        ? (model as Reference).getName()
        : 'Unknown name.',
    url: updateError ? model.url() : model.urlRoot,
  },
  status,
  responseText: responseText || '',
  message: parseMessage(model),
});

export const renderSyncResponseError = ({
  errorType,
  modelData,
  status,
  responseText,
  message,
}: SyncResponseErrorProps) =>
  info({
    title: 'Sorry! An error occurred while saving.',
    text: getErrorMessage({ errorType, status, modelData, message }),
    primaryButtonText: 'Contact Support',
    onPrimaryButtonClick: () => {
      showSupport({
        message: `Technical error [${status} / ${responseText}]\n${getModelDetails(
          modelData,
          message
        )}`,
      });
    },
  });
