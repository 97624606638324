import { dispatchAction } from '@ardoq/rxbeach';
import {
  notifyTagAdded,
  notifyTagRemoved,
  notifyTagUpdated,
} from './TagActions.ts';

export default Tags => {
  Tags.collection.on('add', tag => {
    dispatchAction(notifyTagAdded({ tag }));
  });

  Tags.collection.on('remove', tag => {
    dispatchAction(notifyTagRemoved({ tag }));
  });

  Tags.collection.on('change', tag => {
    dispatchAction(notifyTagUpdated({ tag }));
  });
};
