import { type ExtractPayload, reducer } from '@ardoq/rxbeach';
import { ResponseLogStreamShape } from './types';
import {
  fetchResponseLogData,
  notifyFetchedResponseLogData,
  notifyFailedToFetchResponseLogData,
} from './actions';

export const defaultState: ResponseLogStreamShape = {
  responseLogData: null,
  responseLogSurveyId: null,
  responseLogIsLoading: false,
  responseLogErrorMessage: null,
};

const handleFetchResponseLogData = (
  state: ResponseLogStreamShape,
  { surveyId }: ExtractPayload<typeof fetchResponseLogData>
): ResponseLogStreamShape => {
  return {
    ...state,
    responseLogData: null,
    responseLogIsLoading: true,
    responseLogSurveyId: surveyId,
    responseLogErrorMessage: null,
  };
};

const handleNotifyFetchedResponseLogData = (
  state: ResponseLogStreamShape,
  { responseLog, surveyId }: ExtractPayload<typeof notifyFetchedResponseLogData>
): ResponseLogStreamShape => {
  return {
    ...state,
    responseLogData: responseLog,
    responseLogSurveyId: surveyId,
    responseLogIsLoading: false,
    responseLogErrorMessage: null,
  };
};

const handleNotifyFailedToFetchResponseLogData = (
  state: ResponseLogStreamShape,
  { message }: ExtractPayload<typeof notifyFailedToFetchResponseLogData>
): ResponseLogStreamShape => {
  return {
    ...state,
    responseLogData: null,
    responseLogErrorMessage: message,
    responseLogIsLoading: false,
  };
};

export const reducers = [
  reducer(fetchResponseLogData, handleFetchResponseLogData),
  reducer(notifyFetchedResponseLogData, handleNotifyFetchedResponseLogData),
  reducer(
    notifyFailedToFetchResponseLogData,
    handleNotifyFailedToFetchResponseLogData
  ),
];
