import { actionCreator } from '@ardoq/rxbeach';

interface PayloadCreatePopover {
  readonly popoverContent: any;
  readonly popoverClassName?: string;
  readonly targetElement?: HTMLElement;
  readonly offsetX?: number;
  readonly offsetY?: number;
}
export const createPopover = actionCreator<PayloadCreatePopover>(
  '[popover] CREATE_POPOVER'
);

export const removePopover = actionCreator('[popover] REMOVE_POPOVER');
