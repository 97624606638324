import { APP_VERSION, isDevelopmentMode } from 'appConfig';
import {
  getBrowserName,
  getBrowserPlatform,
  getBrowserVersionNumber,
  isArdoqError,
} from '@ardoq/common-helpers';
import { api, logApi } from '@ardoq/api';

const clientLoggingMetadata = () => ({
  browser: `${getBrowserName()} ${getBrowserVersionNumber()}`,
  platform: getBrowserPlatform(),
  userAgent: navigator.userAgent,
  uri: window.location.href,
  buildVersion: APP_VERSION,
});

export const logWebsocketStatusMessage = (statusMessage: string) => {
  const message = `[Websockets] ${statusMessage}`;

  if (isDevelopmentMode()) {
    // eslint-disable-next-line no-console
    console.debug(message);
  }

  const result = logApi.post({
    level: 'debug',
    message,
    ...clientLoggingMetadata(),
  });
  if (isArdoqError(result) && !api.isServiceUnavailable(result)) {
    throw result;
  }
};
