import { RowType } from '@ardoq/table';
import { Document, DocumentBrowserEntity } from './types';
import { ConfirmDeleteWithYesInputDialog, modal } from '@ardoq/modal';
import { IconName } from '@ardoq/icons';
import { Named } from '@ardoq/api-types';

const getIcon = (rowType: RowType) => {
  switch (rowType) {
    case RowType.FOLDER:
      return IconName.FOLDER;
    case RowType.IMAGE:
      return IconName.COLLECTIONS;
    case RowType.UNKOWN_FILE:
      return IconName.MISC;
    default:
      return IconName.FILE;
  }
};

export const openDeleteDocumentModal = async (
  row: DocumentBrowserEntity,
  descendants: DocumentBrowserEntity[]
) => {
  return modal<boolean>(resolve => (
    <ConfirmDeleteWithYesInputDialog
      title="Are you sure?"
      subtitle={`Are you sure you want to delete "${row.name}"
              ${
                row.rowType === RowType.FOLDER
                  ? ' folder and all its contents'
                  : ' file'
              }
              ?
            `}
      listOptions={descendants.map(descendant => {
        return {
          iconName: getIcon(descendant.rowType),
          strongText: `${
            descendant.name || (descendant as Document).filename
          } ${descendant.rowType === RowType.FOLDER ? 'folder' : 'file'}`,
        };
      })}
      onConfirm={() => resolve(true)}
      onCancel={() => resolve(false)}
      confirmButtonClickId="confirm-delete"
    />
  ));
};

export const openDeleteMultipleDocumentsModal = async (
  rows: (Named & { rowType: RowType })[]
) => {
  return await modal<boolean>(resolve => (
    <ConfirmDeleteWithYesInputDialog
      title="Are you sure?"
      subtitle={`Are you sure you want to delete ${rows.length} selected files?`}
      listOptions={rows.map(({ name, rowType }) => {
        return {
          iconName: getIcon(rowType),
          strongText: `${name} ${
            rowType === RowType.FOLDER ? 'folder' : 'file'
          }`,
        };
      })}
      onConfirm={() => resolve(true)}
      onCancel={() => resolve(false)}
      confirmButtonClickId="confirm-delete"
    />
  ));
};
