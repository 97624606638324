import { ViewIds } from '@ardoq/api-types';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  PayloadContainerCreated,
  PayloadViewChanged,
  rightContentContainerCreated,
  viewChanged,
} from './actions';
import { MAIN_PANE_LEFT } from './types';
import { closePane } from 'appLayout/actions';

export type ActiveViewState = {
  mainViewId: ViewIds;
  secondaryViewId: ViewIds | null;
};

const rightContentContainerCreatedReducer = (
  state: ActiveViewState,
  { containerElement }: PayloadContainerCreated
) => (containerElement === null ? { ...state, secondaryViewId: null } : state);

const viewChangedReducer = (
  state: ActiveViewState,
  { location, viewId }: PayloadViewChanged
) => ({
  ...state,
  ...(location === MAIN_PANE_LEFT
    ? { mainViewId: viewId }
    : { secondaryViewId: viewId }),
});

const closePaneReducer = (state: ActiveViewState): ActiveViewState => ({
  ...state,
  secondaryViewId: null,
});

const activeView$ = persistentReducedStream<ActiveViewState>(
  'activeView$',
  {
    mainViewId: ViewIds.NONE,
    secondaryViewId: null,
  },
  [
    reducer(rightContentContainerCreated, rightContentContainerCreatedReducer),
    reducer(viewChanged, viewChangedReducer),
    reducer(closePane, closePaneReducer),
  ]
);

export default activeView$;
