import { ArdoqId, DiffMode } from '@ardoq/api-types';
import {
  ScenarioModeState,
  MaybeIsScenarioMode,
  PayloadActiveScenarioId,
  PayloadUpdateScopeDiff,
  ActiveScenarioState,
  InactiveScenarioState,
} from 'scope/types';

const getDefaultState = (): ScenarioModeState => ({
  scenarioId: null,
  isScenarioMode: false,
  isInDiffMode: false,
  activeDiffMode: DiffMode.MAIN,
});

/* ------------------------------------------------------------------------------
 * ------------------------------------------------------------------------------
 * -----------------------    Scenario mode operations    -----------------------
 * ------------------------------------------------------------------------------
 * --------------------------------------------------------------------------- */

const setActiveScenarioId = (
  state: ScenarioModeState,
  { scenarioId }: PayloadActiveScenarioId
): ScenarioModeState => {
  if (state.scenarioId === scenarioId) return state;
  if (scenarioId === null) {
    return { ...state, scenarioId, isScenarioMode: false, isInDiffMode: false };
  }
  return { ...state, scenarioId, isScenarioMode: true };
};

const isInScenarioMode = (
  state: MaybeIsScenarioMode & PayloadActiveScenarioId
): state is ActiveScenarioState => state.isScenarioMode && !!state.scenarioId;

function getActiveScenarioId(state: ActiveScenarioState): ArdoqId;
function getActiveScenarioId(state: InactiveScenarioState): null;
function getActiveScenarioId(state: ScenarioModeState): null | ArdoqId;
function getActiveScenarioId(state: {
  isScenarioMode: boolean;
  scenarioId: ArdoqId | null;
}): ArdoqId | null {
  if (!isInScenarioMode(state)) {
    return null;
  }
  return state.scenarioId;
}

/* ------------------------------------------------------------------------------
 * ------------------------------------------------------------------------------
 * -------------------------    Diff mode operations    -------------------------
 * ------------------------------------------------------------------------------
 * --------------------------------------------------------------------------- */

const setIsDiffMode = (
  state: ScenarioModeState,
  { scopeDiff }: PayloadUpdateScopeDiff
): ScenarioModeState => {
  if (!isInScenarioMode(state)) {
    return { ...state, isInDiffMode: false };
  }
  return {
    ...state,
    isInDiffMode: !!scopeDiff,
  };
};
const isInDiffMode = (state: ScenarioModeState): boolean => {
  if (!isInScenarioMode(state)) {
    return false;
  }
  return state.isInDiffMode;
};

const setDiffMode = (
  state: ScenarioModeState,
  diffMode: DiffMode
): ScenarioModeState => ({ ...state, activeDiffMode: diffMode });

const getDiffMode = (state: ScenarioModeState): DiffMode =>
  state.activeDiffMode;

/* ------------------------------------------------------------------------------
 * ------------------------------------------------------------------------------
 * ----------------    Scenario mode and diff mode operations    ----------------
 * ------------------------------------------------------------------------------
 * --------------------------------------------------------------------------- */

/**
 * Check if a scenario id is in diff mode.
 */
const scenarioIsInDiffMode = (
  activeScenarioState: ScenarioModeState,
  scenarioId: ArdoqId
) => {
  if (!isInScenarioMode(activeScenarioState)) {
    return false;
  }
  return (
    getActiveScenarioId(activeScenarioState) === scenarioId &&
    isInDiffMode(activeScenarioState)
  );
};

export const activeScenarioOperations = {
  // Scenario mode operations
  getDefaultState,
  setActiveScenarioId,
  isInScenarioMode,
  getActiveScenarioId,
  // Diff mode operations
  setIsDiffMode,
  isInDiffMode,
  setDiffMode,
  getDiffMode,
  // Scenario mode and diff mode operations
  /**
   * Check if a scenario id is in diff mode.
   */
  scenarioIsInDiffMode,
};
