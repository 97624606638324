import { FC } from 'react';
import SelectAllCheckbox from 'components/EntityBrowser/SelectAllCheckbox';
import EntityBrowser from 'components/EntityBrowser/EntityBrowser';
import { HeaderCell, RowType } from '@ardoq/table';
import EmptyFolderRow from 'components/EntityBrowser/EmptyFolderRow';
import SelectCell from 'components/EntityBrowser/SelectCell';
import NameCell from 'components/EntityBrowser/NameCell';
import {
  NameCellWrapper,
  TextEllipsisWrapper,
  getPopoverConfig,
} from 'components/EntityBrowser/atoms';
import { ColumnsOptions } from 'components/EntityBrowser/types';
import {
  extractAssetsIdsList,
  findIsSelected,
  getSelectRowsFn,
  getSelectableIdsFromDataSource,
  searchDatasourceByName,
  sortDataSource,
  useSorting,
} from 'components/EntityBrowser/utils';
import { formatDateTime } from '@ardoq/date-time';
import { withErrorBoundaries } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';
import SortHeader, {
  SortHeaderWithOffset,
} from 'components/EntityBrowser/SortHeader';
import {
  DocumentArchiveMode,
  DocumentBrowserEntity,
  DocumentBrowserRow,
  DocumentBrowserUIState,
} from './types';
import documentBrowserDataSource$ from 'components/DocumentBrowser/documentBrowserDataSource$';
import { getMenuOptions, splitFilename } from './utils';
import EntityBrowserActionsMenu from 'components/EntityBrowser/EntityBrowserActionsMenu';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import documentBrowserUI$ from './documentBrowserUI$';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { ArdoqId, SortOrder } from '@ardoq/api-types';
import { withHighlightStatusMap } from 'utils/withHighlightStatusMap';
import { browserWithDndRows } from './browserWithDndRows';
import { FilterWrapper } from './atoms';
import { setFilterByType, setPreviewId, setSearchPhrase } from './actions';
import { Document } from './types';
import { s40_DEPRECATED } from '@ardoq/design-tokens';
import { ContextMenuProvider } from '@ardoq/dropdown-menu';
import { SearchInput } from '@ardoq/forms';
import { Chip, ChipsList, ChipsSeparator } from '@ardoq/status-ui';
import { IconName } from '@ardoq/icons';
import RowIcon from 'components/EntityBrowser/RowIcon';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { getCurrentLocale } from '@ardoq/locale';
import { context$ } from 'streams/context/context$';
import { FlexBox } from '@ardoq/layout';

type DocumentBrowser$Props = {
  dataSource: DocumentBrowserRow[];
  isScenarioMode: boolean;
  workspaceId: ArdoqId | null;
} & DocumentBrowserUIState;

type DocumentBrowserProps = {
  isDndEnabled?: boolean;
  tableHeight: number;
  setHighlights: (ids: string[]) => void;
  setSelected: (ids: string[]) => void;
  onEditNameConfirm: (name: string, row: DocumentBrowserEntity) => void;
  onEditNameCancel: () => void;
  setExpandedFoldersIds?: (ids: string[]) => void;
  onAssetsMove: (assetIds: ArdoqId[], folderId: ArdoqId | null) => void;
};

export const DocumentBrowser = ({
  dataSource,
  filterByType,
  searchPhrase,
  renameId,
  selected,
  expandedFoldersIds,
  context,
  mode,
  isScenarioMode,
  workspaceId,
  ...baseProps
}: DocumentBrowserProps & DocumentBrowser$Props) => {
  const isFilePickerMode = mode === DocumentArchiveMode.FILE_PICKER;
  const onSortChange = () => {
    // TODO handle it!
  };

  const [sortOrder, sortByField, setSortByField] = useSorting({
    sortOrder: SortOrder.ASC,
    sortByField: 'name',
    skipDefaultSortOrder: true,
    onSortChange,
  });

  const locale = getCurrentLocale();

  const filteredDataSource = searchDatasourceByName(
    dataSource,
    searchPhrase,
    locale
  );

  const sortedAndFilteredDataSource = sortDataSource(
    filteredDataSource,
    sortByField,
    sortOrder
  );

  const selectRows = getSelectRowsFn(
    baseProps.setSelected,
    selected,
    sortedAndFilteredDataSource
  );

  // TODO consider to add here useCallback or useMemo or something similar
  const getColumns = ({ onRowOpen }: ColumnsOptions<DocumentBrowserEntity>) =>
    [
      {
        title: 'File name',
        dataIndex: 'name',
        headerStyle: { paddingLeft: s40_DEPRECATED },
        cellStyle: { paddingLeft: s40_DEPRECATED },
        headerRender: ({ title, dataIndex = 'name' }: HeaderCell) => (
          <FlexBox align="center">
            <SelectAllCheckbox
              selected={selected}
              setSelected={baseProps.setSelected}
              selectableIds={getSelectableIdsFromDataSource(dataSource)}
            />
            <SortHeaderWithOffset
              dataIndex={dataIndex}
              title={title}
              dataClickId="sort-documents-by-name"
              sortByField={sortByField}
              sortOrder={sortOrder}
              onSortChanged={setSortByField}
              $offsetMargin={4} // aligns with margin-left of the file icons in the table
            />
          </FlexBox>
        ),
        valueRender: (name: string, row: DocumentBrowserRow) => {
          const noAssetIds = extractAssetsIdsList([row]).length === 0;
          const isDisabled =
            row.rowType === RowType.SURVEY && !row.meta.permissions.canUpdate;
          const popoverConfig = getPopoverConfig({
            showPopover: row.rowType === RowType.SURVEY && isDisabled,
            reason: 'insufficientSurveyPermission',
          });
          return row.rowType === RowType.EMPTY_FOLDER ? (
            <EmptyFolderRow row={row as any} />
          ) : (
            <NameCellWrapper>
              <SelectCell
                disabled={isDisabled || noAssetIds}
                isSelected={findIsSelected(row, selected)}
                indentationLevel={row.meta.level}
                rowId={row._id}
                onCheckboxClick={e => selectRows(row, e.shiftKey)}
              />
              <RowIcon
                isDisabled={isDisabled}
                rowType={row.rowType}
                isOpened={expandedFoldersIds?.includes(row._id)}
                onClick={
                  row.rowType === RowType.FOLDER
                    ? () => onRowOpen(row)
                    : undefined
                }
                popoverConfig={popoverConfig}
              />
              <NameCell
                rowData={row}
                isEditing={row._id !== undefined && row._id === renameId}
                onEditNameConfirm={(name: string) => {
                  const { filenameExtension } = splitFilename(
                    (row as Document).filename
                  );
                  baseProps.onEditNameConfirm(
                    [name, filenameExtension].join(''),
                    row
                  );
                  baseProps.setHighlights([row._id]);
                }}
                onEditNameCancel={baseProps.onEditNameCancel}
                name={splitFilename(name).filenameBase}
                rowId={row._id}
                rowType={row.rowType}
                matchGroups={row.meta.matchGroups}
                onClick={
                  row.rowType === RowType.FOLDER
                    ? () => onRowOpen(row)
                    : undefined
                }
                popoverConfig={popoverConfig}
              />
            </NameCellWrapper>
          );
        },
      },
      {
        title: 'Size',
        dataIndex: 'size',
        headerStyle: { width: '200px' },
        headerRender: ({ title, dataIndex = 'size' }: HeaderCell) => (
          <SortHeader
            dataIndex={dataIndex}
            title={title}
            dataClickId="file-size"
            sortByField={sortByField}
            sortOrder={sortOrder}
            onSortChanged={setSortByField}
          />
        ),
        valueRender: (value: string, row: DocumentBrowserRow) =>
          row.rowType !== RowType.EMPTY_FOLDER &&
          row.rowType !== RowType.FOLDER ? (
            <TextEllipsisWrapper $width="200px">
              {`${Math.round(Number(value) / 1024)}KB`}
            </TextEllipsisWrapper>
          ) : null,
      },
      {
        title: 'Last modified',
        dataIndex: 'lastUpdated',
        headerStyle: { width: '200px' },
        headerRender: ({ title, dataIndex = 'lastUpdated' }: HeaderCell) => (
          <SortHeader
            dataIndex={dataIndex}
            title={title}
            dataClickId="last-modified"
            sortByField={sortByField}
            sortOrder={sortOrder}
            onSortChanged={setSortByField}
          />
        ),
        valueRender: (value: string) => (
          <TextEllipsisWrapper $width="200px">
            {formatDateTime(value, getCurrentLocale())}
          </TextEllipsisWrapper>
        ),
      },
      {
        headerStyle: {
          width: '200px',
        },
        title: 'Modified by',
        dataIndex: 'lastModifiedByName',
        headerRender: ({
          title,
          dataIndex = 'lastModifiedByName',
        }: HeaderCell) => (
          <SortHeader
            dataIndex={dataIndex}
            title={title}
            dataClickId="last-modified-by-name"
            sortByField={sortByField}
            sortOrder={sortOrder}
            onSortChanged={setSortByField}
          />
        ),
        valueRender: (value: string) => (
          <TextEllipsisWrapper $width="200px">
            {value !== 'Unknown' ? value : ''}
          </TextEllipsisWrapper>
        ),
      },
      !isFilePickerMode && {
        headerStyle: { width: 70 },
        valueRender: (_: unknown, row: DocumentBrowserRow) => (
          <EntityBrowserActionsMenu
            menuOptions={getMenuOptions(
              row,
              context,
              selected,
              workspaceId,
              isScenarioMode
            )}
          />
        ),
      },
    ].filter(ExcludeFalsy);

  const toggleFilterByType = (
    rowType: RowType.IMAGE | RowType.DOCUMENT | RowType.UNKOWN_FILE
  ) => {
    dispatchAction(
      setFilterByType({
        ...filterByType,
        [rowType]: !filterByType[rowType],
      })
    );
  };

  return (
    <>
      <FilterWrapper>
        {isFilePickerMode ? (
          <div />
        ) : (
          <ChipsList>
            <Chip onClick={() => dispatchAction(setFilterByType(null))}>
              Clear
            </Chip>
            <ChipsSeparator />
            <Chip
              iconName={IconName.COLLECTIONS}
              isActive={filterByType[RowType.IMAGE]}
              onClick={() => toggleFilterByType(RowType.IMAGE)}
            >
              Images
            </Chip>
            <Chip
              iconName={IconName.SURVEYS}
              isActive={filterByType[RowType.DOCUMENT]}
              onClick={() => toggleFilterByType(RowType.DOCUMENT)}
            >
              Documents
            </Chip>
            <Chip
              iconName={IconName.MISC}
              isActive={filterByType[RowType.UNKOWN_FILE]}
              onClick={() => toggleFilterByType(RowType.UNKOWN_FILE)}
            >
              Misc
            </Chip>
          </ChipsList>
        )}
        <SearchInput
          value={searchPhrase}
          onValueChange={value => dispatchAction(setSearchPhrase(value))}
        />
      </FilterWrapper>
      <ContextMenuProvider>
        {({ openContextMenu }) => (
          <EntityBrowser
            {...baseProps}
            searchPhrase={searchPhrase}
            expandedFoldersIds={expandedFoldersIds}
            selectRows={selectRows}
            selected={selected}
            getColumns={getColumns}
            dataSource={sortedAndFilteredDataSource}
            onTableClick={(e, row) => {
              if (
                isFilePickerMode ||
                !row ||
                row.rowType === RowType.EMPTY_FOLDER
              ) {
                return;
              }
              e.preventDefault();

              const menuOptions = getMenuOptions(
                row as DocumentBrowserRow,
                context,
                selected,
                workspaceId,
                isScenarioMode
              );
              if (e.type === 'contextmenu' && menuOptions.length) {
                dispatchAction(setPreviewId(row._id));
                openContextMenu(e, {
                  options: menuOptions,
                  onClose: () => dispatchAction(setPreviewId(null)),
                });
              }
            }}
          />
        )}
      </ContextMenuProvider>
    </>
  );
};

const documentBrowser$ = combineLatest([
  context$,
  documentBrowserDataSource$,
  documentBrowserUI$,
  activeScenario$,
]).pipe(
  map(
    ([
      { workspaceId },
      { dataSource },
      documentBrowserUIState,
      { isScenarioMode },
    ]): DocumentBrowser$Props => ({
      dataSource,
      isScenarioMode,
      workspaceId,
      ...documentBrowserUIState,
    })
  )
);

export default withErrorBoundaries({
  errorContextDescription: 'Error at <DocumentBrowser />',
  logError,
})(
  withHighlightStatusMap(
    connect(browserWithDndRows(DocumentBrowser), documentBrowser$)
  )
) as unknown as FC<Omit<DocumentBrowserProps, 'setHighlights'>>;
