import { PopoverWrapper } from '@ardoq/popovers';

interface PopoverProps {
  popoverContent?: any;
  children?: any;
}

const Popover = ({
  popoverContent,
  children,
  ...rest
}: PopoverProps): JSX.Element => (
  <PopoverWrapper {...rest}>
    {popoverContent}
    {children}
  </PopoverWrapper>
);

export default Popover;
