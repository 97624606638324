import { trackEvent } from 'tracking/tracking';

interface TrackDynamicFilterReFetch {
  filterId: string;
}

// Track when applied Dynamic Filter automatically refetches on component or reference update
export const trackDynamicFilterReFetch = (
  metadata: TrackDynamicFilterReFetch
) => trackEvent('Dynamic Filter automatically refetched', metadata);
