import { clamp } from 'lodash';

export const takeFrom = <T>(
  inputData: T[],
  requestedNumberItemsToTake: number,
  fromIndex = 0
): T[] => {
  const numberItemsToTake = Math.min(
    requestedNumberItemsToTake,
    inputData.length
  );
  const toIndex = Math.min(fromIndex + numberItemsToTake, inputData.length);
  return inputData.slice(toIndex - numberItemsToTake, toIndex);
};

type CalculateDerivedStateParams = {
  dataSource: any[];
  tableHeight: number;
  rowHeight: number;
  scrollTop: number;
  prerender?: boolean;
};

export const calculateVirtualTableParams = (
  params: CalculateDerivedStateParams
) => {
  const {
    dataSource,
    tableHeight,
    rowHeight,
    scrollTop,
    prerender = false,
  } = params;
  const visibleNumberOfRows = Math.ceil(tableHeight / rowHeight);
  const numberOfRowsToRender = visibleNumberOfRows * (prerender ? 3 : 1);
  const showFromIndex = Math.min(
    Math.max(0, dataSource.length - visibleNumberOfRows),
    Math.floor(scrollTop / rowHeight)
  );

  const topPlaceholderHeight = prerender
    ? Math.min(
        Math.max(0, showFromIndex - visibleNumberOfRows),
        Math.max(0, dataSource.length - numberOfRowsToRender)
      ) * rowHeight
    : showFromIndex * rowHeight;

  const renderFromIndex = prerender
    ? clamp(showFromIndex - visibleNumberOfRows, 0, showFromIndex)
    : showFromIndex;

  const dataSourcePartialToRender = takeFrom(
    dataSource,
    numberOfRowsToRender,
    renderFromIndex
  );

  const bottomPlaceholderHeight =
    dataSource.length * rowHeight -
    topPlaceholderHeight -
    dataSourcePartialToRender.length * rowHeight;

  return {
    topPlaceholderHeight,
    bottomPlaceholderHeight,
    dataSourcePartialToRender,
  };
};
