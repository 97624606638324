import { DynamicFilterStates } from './types';
import {
  DynamicFilterErrorPayload,
  DynamicFilterIncludesPayload,
  DynamicFilterParametersPayload,
  ReplaceDynamicFiltersPayload,
  dynamicFilterError,
  replaceDynamicFilters,
  setDynamicFilterActive,
  setDynamicFilterInactive,
  setDynamicFilterIncludes,
  setDynamicFilterLoading,
  setDynamicFilterParameters,
} from './actions';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  DynamicFilterId,
  DynamicFilterSelectState,
  DynamicFilterState,
} from '@ardoq/data-model';

export const defaultFilterState: DynamicFilterState = {
  id: '',
  params: {},
  state: DynamicFilterSelectState.INACTIVE,
  components: new Set(),
  references: new Set(),
};

type DynamicFilterSelectStatePayload = {
  id: string;
  state: DynamicFilterSelectState;
};

const applyChanges = (
  state: DynamicFilterStates,
  changes:
    | DynamicFilterSelectStatePayload
    | DynamicFilterParametersPayload
    | DynamicFilterIncludesPayload
    | DynamicFilterErrorPayload
) => {
  const changedFilter = {
    ...defaultFilterState,
    ...state.find(f => f.id === changes.id),
    error: undefined,
    ...changes,
    id: changes.id,
  };

  return [...state.filter(f => f.id !== changedFilter.id), changedFilter];
};

const setDynamicFilterState = (filterState: DynamicFilterSelectState) => {
  return (state: DynamicFilterStates, { id }: DynamicFilterId) => {
    return applyChanges(state, { id, state: filterState });
  };
};

const replaceState = (
  _: DynamicFilterStates,
  filters: ReplaceDynamicFiltersPayload
) => filters;

const reducers = [
  reducer(setDynamicFilterParameters, applyChanges),
  reducer(setDynamicFilterIncludes, applyChanges),
  reducer(dynamicFilterError, applyChanges),
  reducer(replaceDynamicFilters, replaceState),
  reducer(
    setDynamicFilterActive,
    setDynamicFilterState(DynamicFilterSelectState.ACTIVE)
  ),
  reducer(
    setDynamicFilterLoading,
    setDynamicFilterState(DynamicFilterSelectState.LOADING)
  ),
  reducer(
    setDynamicFilterInactive,
    setDynamicFilterState(DynamicFilterSelectState.INACTIVE)
  ),
];

const initialState: DynamicFilterState[] = [];
const dynamicFilterStates$ = persistentReducedStream<DynamicFilterState[]>(
  'dynamicFilterStates$',
  initialState,
  reducers
);

export default dynamicFilterStates$;
