import {
  APIComponentType,
  APICurrentUser,
  OrgAccessLevel,
  PersonalSetting,
} from '@ardoq/api-types';
import CurrentUser from 'models/currentUser';
import {
  GetPersonalSetting,
  LastUsedReferenceTypeTriple,
  ReferenceTypeName,
  SetPersonalSetting,
} from 'aqTypes';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { PermissionContext } from '@ardoq/access-control';

/**
 * UNSAFE: This returns a direct reference to the object used by Backbone, which
 * means that it can be mutated. For anything by looking up values, use
 * `getCurrentUserAttributesClone` instead.
 */
const getCurrentUserAttributes = (): APICurrentUser => {
  return CurrentUser.attributes;
};

const getCurrentUserAttributesClone = (): APICurrentUser => {
  return structuredClone(getCurrentUserAttributes());
};

const getPermissionContext = (): PermissionContext => {
  return currentUserPermissionContext$.state;
};

const currentUserIsOrgAdmin = () => {
  return CurrentUser.isOrgAdmin();
};

const currentUserHasWriteAccess = () => {
  return CurrentUser.hasWriteAccess();
};

const getOrgCurrentUserRole = (): OrgAccessLevel | null => {
  const currentOrgRole = CurrentUser.getOrganization().role;
  return (currentOrgRole as OrgAccessLevel) || null;
};

const getCurrentOrgId = () => CurrentUser.getOrganization()._id;

const getCurrentUserId = () => CurrentUser.getId();

const setCurrentUserAttributes = (
  currentUserAttributes: APICurrentUser,
  options: { silent?: boolean } = { silent: false }
) => {
  CurrentUser.set(currentUserAttributes);
  if (!options.silent) {
    CurrentUser.trigger('sync');
  }
};

const getPersonalSetting: GetPersonalSetting = key =>
  CurrentUser.getPersonalSetting(key);

const setPersonalSetting: SetPersonalSetting = (key, value, forceUpdate) =>
  CurrentUser.setPersonalSetting(key, value, forceUpdate);

const setLastUsedReferenceTypeTriple = (
  sourceComponentType: APIComponentType,
  targetComponentType: APIComponentType,
  referenceTypeName: string
) => {
  const previousSetting: LastUsedReferenceTypeTriple =
    getPersonalSetting(PersonalSetting.LAST_USED_REFERENCE_TYPE_NAME_TRIPLES) ??
    {};
  setPersonalSetting(PersonalSetting.LAST_USED_REFERENCE_TYPE_NAME_TRIPLES, {
    ...previousSetting,
    [sourceComponentType.name]: {
      ...(previousSetting[sourceComponentType.name] ?? {}),
      [targetComponentType.name]: referenceTypeName,
    },
  });

  // This will be deprecated when the above function is tested and working fine.
  currentUserInterface.setPersonalSetting(
    PersonalSetting.LAST_USED_REFERENCE_TYPE_NAME,
    referenceTypeName
  );
};

const getLastUsedReferenceTypeTriple = (
  sourceComponentType: APIComponentType,
  targetComponentType: APIComponentType
): ReferenceTypeName | null => {
  const setting: LastUsedReferenceTypeTriple =
    getPersonalSetting(PersonalSetting.LAST_USED_REFERENCE_TYPE_NAME_TRIPLES) ??
    {};
  return setting[sourceComponentType.name]?.[targetComponentType.name] ?? null;
};

export const currentUserInterface = {
  getCurrentUserAttributesClone,
  getCurrentUserAttributes,
  getPermissionContext,
  currentUserIsOrgAdmin,
  getOrgCurrentUserRole,
  getCurrentOrgId,
  setPersonalSetting,
  setCurrentUserAttributes,
  getPersonalSetting,
  getCurrentUserId,
  currentUserHasWriteAccess,
  setLastUsedReferenceTypeTriple,
  getLastUsedReferenceTypeTriple,
};
