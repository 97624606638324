import { actionCreator } from '@ardoq/rxbeach';
import { ParameterOptions, ParameterQueryId } from './types';

export const updateParameterOptions = actionCreator<Readonly<ParameterOptions>>(
  '[parameterOptions] UPDATE_PARAMETER_OPTIONS'
);

export const fetchParameterOptions = actionCreator<Readonly<ParameterQueryId>>(
  '[parameterOptions] FETCH_PARAMETER_OPTIONS'
);
