import { GroupEntryProps, SingleEntryProps } from './types';
import { Label, Text } from './atoms';
import { Header4 } from '@ardoq/typography';
import { Box } from '@ardoq/layout';

const EntryRowItem = ({ label, text }: { label: string; text: string }) => (
  <>
    <Label>{label}</Label>
    <Text>{text}</Text>
  </>
);

export const SingleEntryRow = ({
  sourceComponentName,
  targetComponentName,
  referenceType,
}: SingleEntryProps) => (
  <>
    <Box paddingBottom="small">
      <Header4>Change single reference</Header4>
    </Box>
    <EntryRowItem label={'Source component'} text={sourceComponentName} />
    <EntryRowItem label={'Reference type'} text={referenceType} />
    <EntryRowItem label={'Target component'} text={targetComponentName} />
  </>
);

export const GroupEntryRow = ({
  sourceWorkspaceName,
  numberOfReferences,
  referenceType,
}: GroupEntryProps) => (
  <>
    <Box paddingBottom="small">
      <Header4>Change multiple references</Header4>
    </Box>
    <EntryRowItem label={'Workspace source'} text={sourceWorkspaceName} />
    <EntryRowItem label={'Reference type'} text={referenceType} />
    <EntryRowItem
      label={'Number of reference'}
      text={`${numberOfReferences}`}
    />
  </>
);
