import Context from 'context';
import Field, { fieldNameToIdMap } from 'models/field';
import Backbone from 'backbone';
import { SortAttribute } from '@ardoq/common-helpers';
import { getDefaultAttributeLabel } from '@ardoq/renderers';
import FieldLookupCache from 'cache/FieldLookupCache';
import ViewCollection from './ViewCollection';
import { getCurrentLocale, localeCompareNumeric } from '@ardoq/locale';
import {
  ComponentBackboneModel,
  FieldBackboneModel,
  Model,
  Reference as ReferenceBackboneModel,
} from 'aqTypes';
import { getApiUrl } from 'backboneExtensions';
import { APIFieldAttributes } from '@ardoq/api-types';
import { debounce, isString } from 'lodash';

export class Fields extends ViewCollection<FieldBackboneModel> {
  lookupCache: FieldLookupCache;

  constructor(...args: any) {
    super(...args);

    this.model = Field.model;
    this.url = `${getApiUrl(Backbone.Collection)}/api/field`;

    this.lookupCache = new FieldLookupCache(this);

    this.comparator = (a: FieldBackboneModel, b: FieldBackboneModel) => {
      const locale = getCurrentLocale();
      const byOrder = a.get(SortAttribute.ORDER) - b.get(SortAttribute.ORDER);
      const aLabel = a.get('label') || '';
      const bLabel = b.get('label') || '';
      const byLabel = localeCompareNumeric(aLabel, bLabel, locale);
      return byOrder || byLabel;
    };
  }

  /**
   * Return fields that are applicable to a component
   */
  getByComponent(comp: ComponentBackboneModel) {
    return this.lookupCache.getFieldsByComponentType(
      comp.getModelId(),
      comp.getTypeId()
    );
  }

  /**
   * Return fields that are applicable to a reference
   */
  getByReference(ref: ReferenceBackboneModel) {
    const model = ref.getModel();
    if (model) {
      return this.lookupCache.getFieldsByReferenceType(
        model.id,
        String(ref.getType())
      );
    }
    return [];
  }

  /**
   * Return fields for a given reference type (within a model)
   */
  getByComponentType(typeId: string | number, modelId: string) {
    return this.lookupCache.getFieldsByComponentType(modelId, String(typeId));
  }

  /**
   * Return fields for a given reference type (within a model)
   */
  getByReferenceType(typeId: string | number, modelId: string) {
    return this.lookupCache.getFieldsByReferenceType(modelId, String(typeId));
  }

  /**
   * inclueTemplateFields includes fields that are defined in templates
   * (they might not be used in any workspaces)
   */
  getByName(
    fieldName: string,
    {
      acrossWorkspaces,
      includeTemplateFields,
    }: { acrossWorkspaces?: boolean; includeTemplateFields?: boolean } = {
      includeTemplateFields: true,
    }
  ): FieldBackboneModel | null | undefined {
    return fieldName
      ? this.findByNameInCollection(
          fieldName,
          this.models,
          acrossWorkspaces,
          includeTemplateFields
        )
      : null;
  }

  /**
   * Return label for fieldName. Works for default attributes or custom fields
   */
  getFieldLabel(fieldName: string) {
    const defaultAttributeLabel = getDefaultAttributeLabel(fieldName);
    if (defaultAttributeLabel) return defaultAttributeLabel;
    const customField = this.getByName(fieldName, { acrossWorkspaces: true });
    return customField ? customField.getLabel() : fieldName;
  }

  getByModel(model: Model | string) {
    if (model) {
      return new Fields(
        this.filter(function (field) {
          return field.getModelId() === (isString(model) ? model : model.id);
        })
      );
    }
    return new Fields();
  }

  getByNameAndComponent(fieldName: string, component: ComponentBackboneModel) {
    return this.findByNameInCollection(
      fieldName,
      this.getByComponent(component)
    );
  }

  private findGlobalFieldByNameWithoutCache(fieldName: string) {
    const field = this.find(currentField => currentField.name() === fieldName);
    if (field) {
      fieldNameToIdMap.set(fieldName, field.id);
    } else {
      fieldNameToIdMap.delete(fieldName);
    }
    return field;
  }

  findGlobalFieldByName(fieldName: string) {
    const cachedId = fieldNameToIdMap.get(fieldName);
    const cachedField = cachedId ? this.get(cachedId) : null;
    return cachedField || this.findGlobalFieldByNameWithoutCache(fieldName);
  }

  findByNameInCollection(
    fieldName: string,
    collection: FieldBackboneModel[],
    acrossWorkspaces?: boolean | undefined,
    includeTemplateFields = true
  ) {
    if (acrossWorkspaces && includeTemplateFields) {
      return this.findGlobalFieldByName(fieldName);
    }
    const activeModelId = Context.activeModelId();
    const lowerCaseFieldName = fieldName.toLowerCase();
    return collection?.find(field => {
      const fieldAttr = field.attributes as APIFieldAttributes;
      if (!fieldAttr.name) {
        return false;
      }
      return (
        fieldAttr.name.toLowerCase() === lowerCaseFieldName &&
        (acrossWorkspaces || fieldAttr.model === activeModelId) &&
        (includeTemplateFields || !field.getModel()?.isTemplate())
      );
    });
  }

  loadFields(fields: APIFieldAttributes[]) {
    for (const field of fields) {
      this.add(field).fetch();
    }
  }

  getUniqueModelFields(modelIds: string[] = []) {
    const discoveredFieldNames = new Set();
    return this.filter(
      /** @param {FieldBackboneModel} field */ field =>
        modelIds.includes(field.get('model'))
    ).filter(
      /** @param {FieldBackboneModel} field */ field => {
        const name = field.name();
        if (discoveredFieldNames.has(name)) {
          return false;
        }
        discoveredFieldNames.add(name);
        return true;
      }
    );
  }

  getAllOpenWorkspaceFields() {
    const openWsModelIds = Context.workspaces().map(ws =>
      ws.get('componentModel')
    );
    return this.getUniqueModelFields(openWsModelIds);
  }

  getAllWorkspaceFields(wsId: string) {
    const openWsModelId = Context.workspaces()
      .find(ws => ws.id === wsId)
      ?.get('componentModel');
    return this.getUniqueModelFields([openWsModelId]);
  }

  invalidateFieldsCache() {
    this.lookupCache.invalidateCache();
    fieldNameToIdMap.clear();
  }
}

function createFields(
  attributes: Record<string, unknown>,
  options: Record<string, unknown>
) {
  return new Fields(attributes, options);
}

const globalFields = new Fields();

globalFields.listenTo(
  globalFields,
  'change:_order',
  debounce(() => {
    globalFields.sort();
  }, 200)
);

export default {
  collection: globalFields,
  create: createFields,
  createView: (name: string, includedSets = []) =>
    globalFields.createCollectionView(name, includedSets),
};
