import {
  ExtractPayload,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import {
  notifyFiltersChanged,
  notifyPerspectivesDestroyed,
  setActivePerspective,
} from './FilterActions';
import { ActiveFilterState } from './types';

const defaultState: ActiveFilterState = {
  selectedPerspectiveId: null,
  selectedPerspectiveQueryString: '',
  activeFilters: null,
  activeFiltersQueryString: '',
};

const handleSetActivePerspective = (
  state: ActiveFilterState,
  { id, queryString }: ExtractPayload<typeof setActivePerspective>
) => ({
  ...state,
  selectedPerspectiveId: id,
  selectedPerspectiveQueryString: queryString,
});

const handlePerspectivesDestroyed = (
  state: ActiveFilterState,
  { perspectiveIds }: ExtractPayload<typeof notifyPerspectivesDestroyed>
) => {
  if (
    state.selectedPerspectiveId &&
    perspectiveIds.includes(state.selectedPerspectiveId)
  ) {
    return {
      ...state,
      selectedPerspectiveId: null,
    };
  }
  return state;
};

const handleFiltersChanged = (
  state: ActiveFilterState,
  { filters, queryString }: ExtractPayload<typeof notifyFiltersChanged>
): ActiveFilterState => {
  return {
    ...state,
    activeFilters: filters,
    activeFiltersQueryString: queryString,
  };
};

const activeFilter$ = persistentReducedStream('activeFilter$', defaultState, [
  reducer(setActivePerspective, handleSetActivePerspective),
  reducer(notifyPerspectivesDestroyed, handlePerspectivesDestroyed),
  reducer(notifyFiltersChanged, handleFiltersChanged),
]);

export default activeFilter$;
