import { PermissionContext } from '@ardoq/access-control';
import { ArdoqId, Workspace } from '@ardoq/api-types';
import {
  subdivisionsComponentsOperations,
  SubdivisionsContext,
} from '@ardoq/subdivisions';
import { subdivisionAccessControlInterface } from 'resourcePermissions/accessControlHelpers/subdivisions';

const isSubdivisionBoundToWorkspace = (
  workspace: Workspace,
  subdivisionId: ArdoqId
): boolean =>
  subdivisionsComponentsOperations
    .getSubdivisionsMembership(workspace)
    .includes(subdivisionId);

const getWritableSubdivisionMemberships = (
  workspace: Workspace,
  {
    permissionContext,
    subdivisionContext,
  }: {
    permissionContext: PermissionContext;
    subdivisionContext: SubdivisionsContext;
  }
): ArdoqId[] =>
  subdivisionsComponentsOperations
    .getSubdivisionsMembership(workspace)
    .filter(subdivisionId =>
      subdivisionAccessControlInterface.canEditSubdivision(
        permissionContext,
        subdivisionContext,
        subdivisionId
      )
    );

const hasWritableSubdivisionMembership = (
  workspace: Workspace,
  {
    permissionContext,
    subdivisionContext,
  }: {
    permissionContext: PermissionContext;
    subdivisionContext: SubdivisionsContext;
  }
): boolean =>
  getWritableSubdivisionMemberships(workspace, {
    permissionContext,
    subdivisionContext,
  }).length > 0;

export const workspaceOperations = {
  isSubdivisionBoundToWorkspace,
  getWritableSubdivisionMemberships,
  hasWritableSubdivisionMembership,
};
