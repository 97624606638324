import currentUser$ from './currentUser/currentUser$';
import { map } from 'rxjs';
import { getDefaultLocale, getLocale } from '@ardoq/locale';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { PersonalSetting } from '@ardoq/api-types';

export const locale$ = currentUser$.pipe(
  map(user => {
    const usersLocale = user.settings[PersonalSetting.LOCALE];

    return getLocale(usersLocale) ?? getDefaultLocale();
  }),
  distinctUntilChanged(),
  shareReplay({ refCount: true, bufferSize: 1 })
);
