import Fields from 'collections/fields';
import Components from 'collections/components';
import References from 'collections/references';
import Workspaces from 'collections/workspaces';
import Tags from 'collections/tags';
import GroupByCollection from 'collections/groupByCollection';
import Perspectives from 'collections/perspectives';
import Models from 'collections/models';
import subscribeToFields from 'streams/fields/backboneDispatchers';
import subscribeToComponents from 'streams/components/backboneDispatchers';
import subscribeToReferences from 'streams/references/backboneDispatchers';
import subscribeToWorkspaces from 'streams/workspaces/backboneDispatchers';
import subscribeToTags from 'streams/tags/backboneDispatchers';
import subscribeToFilters from 'streams/filters/backboneDispatchers';
import subscribeToGrouping from 'streams/grouping/backboneDispatchers';
import subscribeToModels from 'streams/models/backboneDispatchers';

export default function startBackboneDispatchers() {
  subscribeToWorkspaces(Workspaces);
  subscribeToFields(Fields);
  subscribeToComponents(Components);
  subscribeToReferences(References);
  subscribeToTags(Tags);
  subscribeToFilters(Perspectives);
  subscribeToGrouping(GroupByCollection);
  subscribeToModels(Models);
}
