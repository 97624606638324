import { RefObject, createRef, Component, ChangeEvent } from 'react';
import { AqInlineEditInput, AqInlineEditInputWrapper } from './atoms';
import { isElementInTargetPath } from 'utils/domUtils';

type EditableNameProps = {
  name: string;
  onEditConfirm: (newName: string) => void;
  onEditCancel: () => void;
};

type EditableNameState = {
  newName: string;
};

class EditableName extends Component<EditableNameProps, EditableNameState> {
  inputRef: RefObject<HTMLInputElement>;
  constructor(props: EditableNameProps) {
    super(props);
    this.inputRef = createRef();
    this.state = {
      newName: props.name,
    };
  }
  setNewName = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ newName: event.target.value });
  };

  onKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      this.props.onEditCancel();
    } else if (event.code === 'Enter') {
      this.editConfirm();
    }
  };

  onMouseDown = (event: MouseEvent) => {
    if (
      this.inputRef.current &&
      !isElementInTargetPath(event.target as HTMLElement, this.inputRef.current)
    ) {
      this.editConfirm();
    }
  };

  editConfirm = () => {
    const { newName } = this.state;
    if (newName && newName !== this.props.name) {
      this.props.onEditConfirm(newName);
    } else {
      this.props.onEditCancel();
    }
  };

  componentDidMount() {
    const inputEl = this.inputRef.current;
    // actually shouldn't happen
    if (!inputEl) return;
    inputEl.focus();
    inputEl.select();

    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('mousedown', this.onMouseDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('mousedown', this.onMouseDown);
  }

  render() {
    return (
      <AqInlineEditInputWrapper>
        <AqInlineEditInput
          ref={this.inputRef}
          value={this.state.newName}
          onChange={this.setNewName}
        />
      </AqInlineEditInputWrapper>
    );
  }
}

export default EditableName;
