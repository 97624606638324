import {
  collectRoutines,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { tap } from 'rxjs/operators';
import { createPopover, removePopover } from './actions';
import Popover from './Popover';

let activePopover: HTMLElement | undefined;

const removeActivePopover = () => activePopover && activePopover.remove();

const handleCreatePopover = routine(
  ofType(createPopover),
  extractPayload(),
  tap(
    ({
      popoverContent,
      popoverClassName = '',
      targetElement = document.body,
      offsetX,
      offsetY,
    }) => {
      removeActivePopover();
      activePopover = document.createElement('div');
      activePopover.className = popoverClassName;
      Object.assign(activePopover.style, {
        pointerEvents: 'none',
        position: 'absolute',
        zIndex: '9999',
        left: typeof offsetX === 'number' ? `${offsetX}px` : undefined,
        top: typeof offsetY === 'number' ? `${offsetY}px` : undefined,
      });

      targetElement.appendChild(activePopover);
      const root = createRoot(activePopover);
      // flushSync to skip React render batching
      flushSync(() => {
        root.render(Popover({ popoverContent }));
      });
    }
  )
);

const handleRemovePopover = routine(
  ofType(removePopover),
  tap(removeActivePopover)
);

export const popoverRoutines = collectRoutines(
  handleCreatePopover,
  handleRemovePopover
);
