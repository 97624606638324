import {
  StreamCollection,
  emptyCollectionStream,
  toCollectionStream,
} from 'streams/utils/streamUtils';
import {
  APIScenarioAttributes,
  ArdoqId,
  CreateScenarioResponse,
  ResourceType,
} from '@ardoq/api-types';

import {
  reducedStream,
  streamReducer,
  toPersistentStream,
} from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import { fetchAllReducer } from 'streams/crud/reducers';
import { toResourceTypeStream, websocket$ } from 'sync/websocket$';
import { scenarioNamespace } from './actions';
import { ArdoqEvent, EventType } from 'sync/types';
import { logWarn } from '@ardoq/logging';

const resetScenarios = (
  _: APIScenarioAttributes[],
  scenarios: APIScenarioAttributes[]
): APIScenarioAttributes[] => scenarios;

const deleteScenario = (
  state: APIScenarioAttributes[],
  id: ArdoqId
): APIScenarioAttributes[] => state.filter(m => m._id !== id);

const updateScenario = (
  state: APIScenarioAttributes[],
  scenario: APIScenarioAttributes
): APIScenarioAttributes[] => {
  const newModels = state.map(existingScenario =>
    existingScenario._id === scenario._id ? scenario : existingScenario
  );

  return resetScenarios(state, newModels);
};

const createScenario = (
  state: APIScenarioAttributes[],
  response: CreateScenarioResponse
): APIScenarioAttributes[] =>
  resetScenarios(state, [response.scenario, ...state]);

export type ScenariosState = StreamCollection<APIScenarioAttributes>;

// It's a bit sucky to not be able to use the premade websocketReducer, but because
// create returns both a permission and a scenario, we need to specialize on this
// Should probs make some support for this over in `streams/reducers`
const websocketReducer = (
  state: APIScenarioAttributes[],
  event: ArdoqEvent<any>
): APIScenarioAttributes[] => {
  const eventType = event['event-type'];
  switch (eventType) {
    case EventType.DELETE:
      return deleteScenario(state, event.data._id);
    case EventType.CREATE:
      return createScenario(state, event.data);
    case EventType.UPDATE:
      return updateScenario(state, event.data);
    default:
      logWarn(new Error(`Unknown event-type "${eventType}"`));
  }
  return state;
};

const scenariosList$ = reducedStream<APIScenarioAttributes[]>(
  'scenarios$',
  [],
  [
    fetchAllReducer(resetScenarios),
    streamReducer(
      toResourceTypeStream<APIScenarioAttributes>(
        websocket$,
        ResourceType.SCENARIO
      ),
      websocketReducer
    ),
  ],
  { namespace: scenarioNamespace }
);

export default toPersistentStream(
  'scenarioState$',
  scenariosList$.pipe(map(toCollectionStream)),
  emptyCollectionStream()
);
