import { Component } from 'react';
import { DataSourceTableProps, DatasourceTable } from '@ardoq/table';
import { calculateVirtualTableParams } from './utils';

type VirtualDatasourceTableProps<TData extends object> =
  DataSourceTableProps<TData> & {
    rowHeight: number;
    virtualTableHeight: number;
  };

type VirtualDatasourceTableState = {
  scrollTop: number;
};

class VirtualDatasourceTable<TData extends object> extends Component<
  VirtualDatasourceTableProps<TData>,
  VirtualDatasourceTableState
> {
  state = { scrollTop: 0 };

  _onScrollHandler = (e: any) => {
    const { scrollTop } = e.target;
    this.setState({ scrollTop });
  };

  render() {
    const { dataSource, virtualTableHeight, rowHeight, ...props } = this.props;
    const { scrollTop } = this.state;
    const {
      dataSourcePartialToRender,
      topPlaceholderHeight,
      bottomPlaceholderHeight,
    } = calculateVirtualTableParams({
      dataSource,
      rowHeight,
      scrollTop,
      tableHeight: virtualTableHeight,
      prerender: true,
    });

    return (
      <DatasourceTable
        {...props}
        onScroll={this._onScrollHandler}
        topPlaceholderHeight={topPlaceholderHeight}
        bottomPlaceholderHeight={bottomPlaceholderHeight}
        dataSource={dataSourcePartialToRender}
      />
    );
  }
}

export default VirtualDatasourceTable;
