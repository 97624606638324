import {
  APIAttachment,
  APIDocumentArchiveFolder,
  ArdoqId,
  RenameRequest,
} from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { dispatchAction } from '@ardoq/rxbeach';
import { RowType } from '@ardoq/table';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { context$ } from 'streams/context/context$';
import currentUser$, {
  CurrentUserState,
} from 'streams/currentUser/currentUser$';
import documentArchiveAttachments$ from 'streams/documentArchive/documentArchiveAttachments$';
import documentArchiveFolders$ from 'streams/documentArchive/documentArchiveFolders$';
import {
  moveAttachmentsToFolder,
  moveFoldersToFolder,
  renameAttachment,
  renameFolder,
} from './actions';
import documentBrowserUI$ from './documentBrowserUI$';
import { DocumentArchiveContext } from './types';
import { prompt } from '@ardoq/modal';
import { createFolder } from './actions';

const getOrganizationBaseRequest = (orgId: ArdoqId) => {
  return {
    type: 'org' as const,
    typeId: orgId,
  };
};

const getWorkspaceBaseRequest = (workspaceId: ArdoqId) => {
  return {
    type: 'workspace' as const,
    typeId: workspaceId,
  };
};

const doCreateFolder = async (
  context: DocumentArchiveContext,
  currentUser: CurrentUserState,
  workspaceId: ArdoqId
) => {
  const baseRequest =
    context === DocumentArchiveContext.ORG
      ? getOrganizationBaseRequest(currentUser.organization._id)
      : getWorkspaceBaseRequest(workspaceId);
  const nameOrDismissed = await prompt({
    title: 'Create a new folder',
    inputLabel: 'Folder Name',
  });
  if (nameOrDismissed) {
    dispatchAction(createFolder({ name: nameOrDismissed, ...baseRequest }));
  }
};

const doRename = (
  context: DocumentArchiveContext.ORG | DocumentArchiveContext.WS,
  rowType: RowType,
  renameRequest: RenameRequest,
  currentUser: CurrentUserState,
  workspaceId: ArdoqId
) => {
  const payload = {
    ...(context === DocumentArchiveContext.ORG
      ? getOrganizationBaseRequest(currentUser.organization._id)
      : getWorkspaceBaseRequest(workspaceId)),
    renameRequest,
  };
  if (rowType === RowType.FOLDER) {
    dispatchAction(renameFolder(payload));
  } else {
    dispatchAction(renameAttachment(payload));
  }
};

type WithDocumentArchiveContext<T = DocumentArchiveContext | APIAttachment> =
  Record<string, (T & { context: DocumentArchiveContext }) | undefined>;
type FoldersById = WithDocumentArchiveContext<APIDocumentArchiveFolder>;
type AttachmentsById = WithDocumentArchiveContext<APIAttachment>;

export type MoveAssetsPayload = {
  assetIds: ArdoqId[];
  folderId: ArdoqId | null;
  context: DocumentArchiveContext;
  currentUser: CurrentUserState;
  workspaceId: ArdoqId;
};

/**
 * Because the API is not currently supporting moving assets based purely on their IDs,
 * we need to use attachment/folders byIds to get the actual objects and then move them.
 * This is a workaround until the API is updated.
 */
const moveAssets =
  (attachmentsById: AttachmentsById, foldersById: FoldersById) =>
  ({
    assetIds,
    folderId,
    context,
    currentUser,
    workspaceId,
  }: MoveAssetsPayload) => {
    const baseRequest =
      context === DocumentArchiveContext.ORG
        ? getOrganizationBaseRequest(currentUser.organization._id)
        : getWorkspaceBaseRequest(workspaceId);

    const attachmentsToBeMoved = assetIds
      .map(id => attachmentsById[id])
      .filter(ExcludeFalsy);
    const attachmentsWithNewFolder = attachmentsToBeMoved.map(attachment => {
      return {
        ...attachment,
        folder: folderId,
      };
    });
    const foldersToBeMoved = assetIds
      .map(id => foldersById[id])
      .filter(ExcludeFalsy);
    const foldersWithNewParent = foldersToBeMoved.map(folder => ({
      ...folder,
      parent: folderId,
    }));

    if (attachmentsWithNewFolder.length) {
      dispatchAction(
        moveAttachmentsToFolder({
          ...baseRequest,
          attachmentsWithNewFolder,
        })
      );
    }
    if (foldersWithNewParent.length) {
      dispatchAction(
        moveFoldersToFolder({
          ...baseRequest,
          foldersWithNewParent,
        })
      );
    }
  };

export const documentBrowserStackPage$ = combineLatest([
  documentBrowserUI$,
  activeScenario$,
  currentUser$,
  context$,
  documentArchiveAttachments$,
  documentArchiveFolders$,
]).pipe(
  map(
    ([
      { context, mode, selected },
      { isScenarioMode },
      currentUser,
      { workspaceId },
      { byId: attachmentsById },
      { byId: foldersById },
    ]) => ({
      context,
      mode,
      selected,
      isScenarioMode,
      doRename,
      moveAssets: moveAssets(attachmentsById, foldersById),
      doCreateFolder: () => doCreateFolder(context, currentUser, workspaceId),
      currentUser,
      workspaceId,
    })
  )
);
