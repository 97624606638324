class CommandQueue<
  TResult,
  TFunc extends (...args: any[]) => Promise<TResult>,
> {
  private queue: Promise<TResult> | null = null;

  push(command: TFunc, args: Parameters<TFunc>) {
    this.queue = (this.queue ?? Promise.resolve()).then(() => command(...args));
    return this.queue;
  }
}

/**
 * @param func An async function which is not safe for parallel execution.
 * @returns An async function which, when invoked, calls `func` in sequence, with subsequent calls awaiting the completion of those currently executing.
 */
const preventConcurrency = <
  TResult,
  TFunc extends (...args: any[]) => Promise<TResult>,
>(
  func: TFunc
) => {
  const queue = new CommandQueue<TResult, TFunc>();
  return (...args: Parameters<TFunc>) => queue.push(func, args);
};
export default preventConcurrency;
