import * as React from 'react';
import { clearSelection } from '@ardoq/common-helpers';
import { FlyWheelTable } from '@ardoq/table';
import { Column, ExpandableTableProvider } from '@ardoq/table';
import VirtualDatasourceTable from 'components/VirtualDatasourceTable/VirtualDatasourceTable';
import { ColumnsOptions, Row, RowItem } from './types';
import { Selected } from 'aqTypes';
import { findIsSelected, isFolder, isNotFolder } from './utils';
import { ArdoqId } from '@ardoq/api-types';
import { colors } from '@ardoq/design-tokens';
import { HighlightStatusMap } from '../../utils/withHighlightStatusMap';
import { isEmpty } from 'lodash';

export type EntityBrowserProps<T extends RowItem> = {
  selected: string[];
  loading?: boolean;
  skipExpandOnSearch?: boolean;
  dataSource: { [key: string]: any }[];
  scrollableSectionHeight?: number | string;
  searchPhrase?: string;
  onRowClick?: (row: T) => void;
  onRowPreviewClick?: (row: T) => void;
  onOpenInViewpointModeClick?: (row: T) => void;
  renderHeader?: () => JSX.Element | null;
  components?: Record<string, React.ComponentType>;
  dragDropProps?: {
    previewId?: ArdoqId | null;
    isDragging?: boolean;
    hoverId?: string;
    overFolderId?: string;
    draggedIds?: string[];
    dragStartedOnFolderId?: string;
    highlightStatusMap?: HighlightStatusMap;
    renameId?: ArdoqId;
    isUpdating?: boolean;
    selected?: ArdoqId[];
  };
  setExpandedFoldersIds?: (ids: string[]) => void;
  expandedFoldersIds?: string[];
  onTableClick?: (
    e: React.MouseEvent<HTMLTableElement, MouseEvent>,
    trElement: Row<T> | null
  ) => void;
  tableHeight?: number | null;
  getColumns: (columnsOptions: ColumnsOptions<T>) => Column<T>[];
  selectRows: (row: RowItem, rangeSelect?: boolean) => void;
  emptyState?: JSX.Element;
  style?: React.CSSProperties;
};

const EntityBrowser = <T extends RowItem>({
  dataSource,
  searchPhrase,
  selected,
  skipExpandOnSearch,
  scrollableSectionHeight,
  onRowClick,
  onRowPreviewClick,
  onOpenInViewpointModeClick,
  components = {},
  dragDropProps,
  loading,
  renderHeader,
  expandedFoldersIds,
  setExpandedFoldersIds,
  onTableClick,
  tableHeight = 0,
  getColumns,
  selectRows,
  emptyState,
  style,
}: EntityBrowserProps<T>) => (
  <ExpandableTableProvider
    idIndex="_id"
    skipExpandOnSearch={skipExpandOnSearch}
    searchPhrase={searchPhrase}
    dataSource={dataSource}
    setExpandedFoldersIds={setExpandedFoldersIds}
    expandedFoldersIds={expandedFoldersIds}
    render={({ dataSource, expandFolder }) => {
      const onRowOpen = (row: Row<T>) => {
        if (onRowClick && isNotFolder(row)) {
          onRowClick(row);
        } else if (isFolder(row)) {
          expandFolder(row._id);
        }
      };
      const onPreviewOpen = (row: Row<T>) => {
        if (onRowPreviewClick && isNotFolder(row)) {
          onRowPreviewClick(row);
        }
      };
      const openInViewpointMode = onOpenInViewpointModeClick
        ? (row: Row<T>) => {
            if (isNotFolder(row)) {
              onOpenInViewpointModeClick(row);
            }
          }
        : undefined;
      return (
        <VirtualDatasourceTable
          fixedHeader
          rowHeight={40}
          virtualTableHeight={tableHeight || 0}
          style={style ?? { height: tableHeight || 0 }}
          onTableClick={(
            e: React.MouseEvent<HTMLTableElement, React.MouseEvent>
          ) => {
            const targetEl = e.target as HTMLElement;
            const trElement = targetEl && targetEl.closest('tr');
            if (!trElement) return;
            const { rowId } = trElement.dataset;
            const row = dataSource.find(
              ({ _id }) => _id === rowId
            ) as unknown as RowItem;
            if (e.shiftKey) {
              selectRows(row, true);
              clearSelection();
            } else if (onTableClick) {
              // @ts-expect-error issues with generic param
              onTableClick(e, row || null);
            }
          }}
          loading={loading}
          scrollableSectionHeight={scrollableSectionHeight}
          components={{ ...FlyWheelTable, ...components }}
          intercomTarget="workspaces browser"
          dragDropProps={
            !isEmpty(dragDropProps)
              ? {
                  ...dragDropProps,
                  expandedFoldersIds,
                  searchPhrase,
                }
              : {}
          }
          rowStyle={(rowData: RowItem) => ({
            height: '40px',
            backgroundColor:
              findIsSelected(rowData, selected) === Selected.SELECTED
                ? colors.grey90
                : 'inherit',
          })}
          renderEmptyTable={
            emptyState
              ? () => emptyState
              : searchPhrase
                ? {
                    title: 'No results found',
                    subtitle: `There is no item with name matching "${searchPhrase}" `,
                  }
                : undefined
          }
          columns={getColumns({
            onRowOpen,
            onRowPreviewClick: onPreviewOpen,
            onOpenInViewpointModeClick: openInViewpointMode,
          })}
          // @ts-expect-error TODO: Fix it, issue found when better TS notation for DatasourceTable was added
          dataSource={dataSource}
          renderHeader={renderHeader}
        />
      );
    }}
  />
);

export default EntityBrowser;
