import { actionCreator } from '@ardoq/rxbeach';
import { GroupByBackboneModel } from 'aqTypes';

interface GroupingPayload {
  readonly grouping: GroupByBackboneModel;
}

export const notifyGroupingRemoved = actionCreator<GroupingPayload>(
  '[GroupingActions] NOTIFY_GROUPING_REMOVED'
);

export const notifyGroupingAdded = actionCreator<GroupingPayload>(
  '[GroupingActions] NOTIFY_GROUPING_ADDED'
);

export const notifyGroupingUpdated = actionCreator<GroupingPayload>(
  '[GroupingActions] NOTIFY_GROUPING_UPDATED'
);

export const notifyGroupingSynced = actionCreator<GroupingPayload>(
  '[GroupingActions] NOTIFY_GROUPING_SYNCED'
);
