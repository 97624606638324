import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  setDocumentArchiveContext,
  setDocumentArchiveUIDefaultState,
  setRenameFocus,
  setExpandedFoldersIds,
  setFilterByType,
  setPreviewId,
  setSearchPhrase,
  setSelected,
  deleteSuccess,
  renameSuccess,
  transferToGlobalSuccess,
} from './actions';
import {
  DocumentArchiveContext,
  DocumentArchiveMode,
  DocumentBrowserUIState,
  FilterByType,
} from './types';
import { RowType } from '@ardoq/table';

const defaultState: DocumentBrowserUIState = {
  context: DocumentArchiveContext.ORG,
  mode: DocumentArchiveMode.DEFAULT,
  previewId: null,
  renameId: null,
  searchPhrase: '',
  selected: [],
  expandedFoldersIds: [],
  filterByType: {
    [RowType.IMAGE]: false,
    [RowType.DOCUMENT]: false,
    [RowType.UNKOWN_FILE]: false,
  },
};

const setDocumentArchiveContextReducer = (
  state: DocumentBrowserUIState,
  context: DocumentArchiveContext
) => ({
  ...state,
  context,
});

const setRenameId = (
  state: DocumentBrowserUIState,
  renameId: string | null
) => ({
  ...state,
  renameId,
});

const setExpandedFoldersIdsReducer = (
  state: DocumentBrowserUIState,
  expandedFoldersIds: string[]
) => ({
  ...state,
  expandedFoldersIds,
});

const setSelectedReducer = (
  state: DocumentBrowserUIState,
  selected: string[]
) => ({
  ...state,
  selected,
});

const setSearchPhraseReducer = (
  state: DocumentBrowserUIState,
  searchPhrase: string
) => ({
  ...state,
  searchPhrase,
});

const setFilterByTypeReducer = (
  state: DocumentBrowserUIState,
  filterByType: FilterByType | null
) => ({
  ...state,
  filterByType: filterByType || defaultState.filterByType,
});

const setPreviewIdReducer = (
  state: DocumentBrowserUIState,
  previewId: string | null
) => ({
  ...state,
  previewId,
});

const setDocumentArchiveUIDefaultStateReducer = (
  _state: DocumentBrowserUIState,
  defaultStatePartial?: Partial<DocumentBrowserUIState>
) => ({
  ...defaultState,
  ...(defaultStatePartial || {}),
});

const deselectAllReducer = (state: DocumentBrowserUIState) => {
  return setSelectedReducer(state, []);
};

const renameSuccessReducer = (state: DocumentBrowserUIState) => {
  return setRenameId(state, null);
};

const reducers = [
  reducer(setRenameFocus, setRenameId),
  reducer(setExpandedFoldersIds, setExpandedFoldersIdsReducer),
  reducer(setSelected, setSelectedReducer),
  reducer(setDocumentArchiveContext, setDocumentArchiveContextReducer),
  reducer(setSearchPhrase, setSearchPhraseReducer),
  reducer(setFilterByType, setFilterByTypeReducer),
  reducer(setPreviewId, setPreviewIdReducer),
  reducer(
    setDocumentArchiveUIDefaultState,
    setDocumentArchiveUIDefaultStateReducer
  ),
  reducer(deleteSuccess, deselectAllReducer),
  reducer(renameSuccess, renameSuccessReducer),
  reducer(transferToGlobalSuccess, deselectAllReducer),
];

const documentBrowserUI$ = persistentReducedStream(
  'documentBrowserUI$',
  defaultState,
  reducers
);

export default documentBrowserUI$;
