import {
  persistentReducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import { ScenarioModeState } from 'scope/types';
import * as scopeActions from 'scope/actions';
import { activeDiffMode$ } from 'scope/activeDiffMode$';
import { activeScenarioOperations } from './activeScenarioOperations';

export const activeScenario$ = persistentReducedStream<ScenarioModeState>(
  'activeScenario$',
  activeScenarioOperations.getDefaultState(),
  [
    reducer(
      scopeActions.setScenarioId,
      activeScenarioOperations.setActiveScenarioId
    ),
    reducer(
      scopeActions.updateScopeDiff,
      activeScenarioOperations.setIsDiffMode
    ),
    streamReducer(activeDiffMode$, activeScenarioOperations.setDiffMode),
  ]
);

export const getActiveScenarioId = () => activeScenario$.state.scenarioId;
export const getActiveScenarioState = () => activeScenario$.state;
