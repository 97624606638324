import { EmptyFolder } from './types';
import { Indentation } from './atoms';
import { colors } from '@ardoq/design-tokens';

type EmptyFolderRowProps = {
  row: EmptyFolder;
};

const EmptyFolderRow = ({ row }: EmptyFolderRowProps) => (
  <>
    <Indentation level={row.meta.level} />
    <i style={{ color: colors.grey80 }}>This folder is empty</i>
  </>
);

export default EmptyFolderRow;
