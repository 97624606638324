import {
  ClickableName,
  LinkOverAssetName,
  NameWrapper,
  SearchPhrase,
  TextEllipsisWrapperWithPopover,
} from './atoms';
import EditableName from './EditableName';
import { ArdoqId } from '@ardoq/api-types';
import { Row } from './types';
import {
  getReportInDiscoverURL,
  ReportEventLocations,
  ReportTrackingEvents,
} from '@ardoq/report-reader';
import { ExternalLink, InternalLink } from 'atomicComponents/Table/PaddedLinks';
import {
  getPresentationURL,
  getPresentationWindowFeatures,
} from '../../presentation/utils';
import { trackEvent } from '../../tracking/tracking';
import { DashboardTrackingEventsNames } from '@ardoq/dashboard';
import { WithPopover, WithPopoverProps } from '@ardoq/popovers';
import { AssetRow } from 'components/AssetsBrowser/types';
import { SurveyRow } from 'components/AssetsBrowser/types';
import { DocumentBrowserRow } from 'components/DocumentBrowser/types';
import { trackOpenExistingSurveyInSurveysApp } from 'surveyAdmin/tracking';
import { MatchGroups } from '@ardoq/common-helpers';
import { MatchGroupsRenderer } from '@ardoq/renderers';
import { IconName } from '@ardoq/icons';
import { RowType } from '@ardoq/table';
import { Features, hasFeature } from '@ardoq/features';

type NameCellProps = {
  onClick?: () => void;
  onPreviewClick?: () => void;
  onOpenInViewpointModeClick?: () => void;
  name: string;
  rowId: ArdoqId;
  rowType: RowType;
  matchGroups?: MatchGroups;
  isEditing?: boolean;
  isClickDisabled?: boolean;
  onEditNameCancel?: () => void;
  onEditNameConfirm?: (newName: string) => void;
  popoverConfig?: Omit<WithPopoverProps, 'children'>;
  rowData?: Row<AssetRow> | DocumentBrowserRow;
};

const shouldShowAnswerSurvey = (surveyRow: SurveyRow) => {
  const surveyHasErrors = surveyRow.errors && surveyRow.errors.length > 0;
  return (
    (surveyRow.published || surveyRow.meta.permissions.canUpdate) &&
    !surveyHasErrors
  );
};

const NameCell = ({
  rowData,
  name,
  onClick,
  rowId,
  rowType,
  matchGroups,
  isEditing,
  isClickDisabled = false,
  onEditNameCancel,
  onEditNameConfirm,
  popoverConfig,
  onPreviewClick,
  onOpenInViewpointModeClick,
}: NameCellProps) => {
  if (isEditing)
    return (
      <EditableName
        name={name}
        onEditCancel={onEditNameCancel || (() => {})}
        onEditConfirm={onEditNameConfirm || (() => {})}
      />
    );

  const handleClick = () => {
    if (isClickDisabled) {
      return;
    }
    trackEvent('Assets Browser: clicked asset name', { assetType: rowType });
    onClick?.();
  };
  return (
    <NameWrapper $indentationLevel={rowData?.meta.level ?? 0}>
      <WithPopover {...popoverConfig} content={popoverConfig?.content}>
        <ClickableName
          data-test-id="asset-manager-name-click"
          data-test-row-id={rowId}
          onClick={handleClick}
          $isClickDisabled={isClickDisabled}
        >
          <TextEllipsisWrapperWithPopover lineClamp={1} $width="calc(100%)">
            <MatchGroupsRenderer
              matchGroups={matchGroups}
              defaultValue={name}
              Emphasis={SearchPhrase}
            />
          </TextEllipsisWrapperWithPopover>
        </ClickableName>
      </WithPopover>

      <LinkOverAssetName>
        {rowType === RowType.PRESENTATION && (
          <ExternalLink
            URL={getPresentationURL(rowId)}
            target="presentation"
            windowFeatures={getPresentationWindowFeatures()}
          >
            Play
          </ExternalLink>
        )}
        {rowType === RowType.SURVEY &&
          shouldShowAnswerSurvey(rowData as SurveyRow) && (
            <ExternalLink
              trackClick={() =>
                trackOpenExistingSurveyInSurveysApp(rowId, 'assetsManager')
              }
              URL={`/surveys/survey/${rowId}`}
            >
              {(rowData as SurveyRow).published
                ? 'Answer Survey'
                : 'Preview Survey'}
            </ExternalLink>
          )}
        {rowType === RowType.REPORT && (
          <ExternalLink
            URL={getReportInDiscoverURL(rowId, undefined, 'reportOverview')}
            trackClick={() => {
              // For some reason, using createReportUserEventTracking here causes a bug.
              trackEvent(
                `Report Builder - Overview0 ${ReportTrackingEvents.OPEN_REPORT_IN_DISCOVER}`,
                {
                  from: ReportEventLocations.REPORT_OVERVIEW,
                }
              );
            }}
          >
            Open in Discover
          </ExternalLink>
        )}
        {rowType === RowType.DASHBOARD && (
          <ExternalLink
            URL={`/discover/dashboard/${rowId}`}
            trackClick={() =>
              trackEvent(DashboardTrackingEventsNames.OPENED_IN_DISCOVER)
            }
          >
            Open in Discover
          </ExternalLink>
        )}
        {rowType === RowType.TRAVERSAL && rowData && (
          <InternalLink onClick={onPreviewClick} iconName={IconName.VISIBILITY}>
            Preview
          </InternalLink>
        )}
        {hasFeature(Features.CANVAS_PROTOTYPE) &&
          rowType === RowType.WORKSPACE &&
          rowData &&
          onOpenInViewpointModeClick && (
            <InternalLink
              onClick={onOpenInViewpointModeClick}
              iconName={IconName.VISIBILITY}
            >
              Open in viewpoint mode
            </InternalLink>
          )}
      </LinkOverAssetName>
    </NameWrapper>
  );
};

export default NameCell;
