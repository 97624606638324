import {
  APIComponentAttributes,
  APIReferenceAttributes,
  APIReferenceAttributesLite,
  ArdoqId,
} from '@ardoq/api-types';
import {
  PermissionContext,
  permissionsOperations,
} from '@ardoq/access-control';
import { workspaceAccessControlInterface } from './workspace';
import { componentAccessControlOperation } from './component';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { subdivisionsInterface } from 'streams/subdivisions/subdivisionInterface';

type ReferenceCanDoArgs = {
  permissionContext: PermissionContext;
  subdivisionsContext: SubdivisionsContext;
  reference: Pick<
    APIReferenceAttributes,
    '_id' | 'rootWorkspace' | 'source' | 'target'
  >;
  component: Pick<
    APIComponentAttributes,
    '_id' | 'rootWorkspace' | 'subdivisionMembership' | 'model' | 'typeId'
  >;
};

const canEditReference = ({
  reference,
  permissionContext,
  subdivisionsContext,
}: Pick<
  ReferenceCanDoArgs,
  'reference' | 'permissionContext' | 'subdivisionsContext'
>): boolean => {
  if (!reference) {
    return false;
  }
  const canEditWorkspace = workspaceAccessControlInterface.canEditWorkspace(
    permissionContext,
    reference.rootWorkspace,
    // TODO - RAFAA: For now, we ignore it, so the PR doesn't get too big
    null
  );

  if (canEditWorkspace) {
    return true;
  }

  const component = componentInterface.getComponentData(reference.source);
  if (!component) {
    return false;
  }

  return componentAccessControlOperation.canEditComponent({
    component,
    permissionContext,
    subdivisionsContext,
  });
};

const canAddReferenceToComponent = ({
  component,
  permissionContext,
  subdivisionsContext,
}: Pick<
  ReferenceCanDoArgs,
  'component' | 'permissionContext' | 'subdivisionsContext'
>): boolean => {
  return componentAccessControlOperation.canEditComponent({
    component,
    permissionContext,
    subdivisionsContext,
  });
};

const canEditComponentReferences =
  componentAccessControlOperation.canEditComponent;

const canEditReferencesTargetComponent = ({
  permissionContext,
  reference,
  components,
  subdivisionsContext,
}: {
  reference: APIReferenceAttributes;
  permissionContext: PermissionContext;
  components: APIComponentAttributes[];
  subdivisionsContext: SubdivisionsContext;
}): boolean => {
  const componentData = components.find(({ _id }) => reference.target === _id);
  return (
    !!componentData &&
    canEditComponentReferences({
      component: componentData,
      permissionContext,
      subdivisionsContext,
    })
  );
};

/**
 * Checks if the user can unlock the reference.
 * @param config  - The configuration object.
 */
const canUnlock = ({
  permissionContext: { user },
  reference,
}: {
  permissionContext: PermissionContext;
  reference: APIReferenceAttributesLite;
}): boolean => {
  if (!user) {
    return false;
  }
  return user._id === reference.lock || permissionsOperations.isOrgAdmin(user);
};

/* ---------------------------------------------------------------------------------------------------
 * ---------------------------------------------------------------------------------------------------
 * --------------------------------         Impure Functions        ----------------------------------
 * ---------------------------------------------------------------------------------------------------
 * ------------------------------------------------------------------------------------------------ */

/**
 * Checks if the user can edit component references by id.
 * @deprecated use canEditComponentReferences instead.
 */
const canEditComponentReferencesBySourceComponentId = (
  componentId: ArdoqId
): boolean => {
  const componentData = componentInterface.getComponentData(componentId);

  return (
    !!componentData &&
    canEditComponentReferences({
      component: componentData,
      permissionContext: currentUserInterface.getPermissionContext(),
      subdivisionsContext: subdivisionsInterface.getSubdivisionsStreamState(),
    })
  );
};

/**
 * Checks if the user can edit references target component by id.
 * @deprecated use canEditReferencesTargetComponent instead.
 */
const canEditReferencesTargetComponentByReferenceId = (
  referenceId: ArdoqId
): boolean => {
  const reference = referenceInterface.getReferenceData(referenceId);
  if (!reference) {
    return false;
  }
  const componentData = componentInterface.getComponentData(reference.target);
  return (
    !!componentData &&
    canEditReferencesTargetComponent({
      reference,
      components: [componentData],
      permissionContext: currentUserInterface.getPermissionContext(),
      subdivisionsContext: subdivisionsInterface.getSubdivisionsStreamState(),
    })
  );
};

/* ---------------------------------------------------------------------------------------------------
 * ---------------------------------------------------------------------------------------------------
 * --------------------------------          API Definition         ----------------------------------
 * ---------------------------------------------------------------------------------------------------
 * ------------------------------------------------------------------------------------------------ */

export const referenceAccessControlOperation = {
  /**
   * Checks if the user can edit reference.
   */
  canEditReference,
  /**
   * Checks if the user can add reference to a component.
   */
  canAddReferenceToComponent,
  /**
   * Checks if the user can unlock the reference.
   */
  canUnlock,
  /**
   * Checks if the user can edit component references.
   */
  canEditComponentReferences,
  /**
   * Checks if the user can edit references target component.
   */
  canEditReferencesTargetComponent,
  /**
   * @deprecated use canEditComponentReferences instead.
   * Checks if the user can edit component references.
   */
  canEditComponentReferencesBySourceComponentId,
  /**
   * @deprecated use canEditReferencesTargetComponent instead.
   * Checks if the user can edit component references by id.
   */
  canEditReferencesTargetComponentByReferenceId,
};
