import {
  BookmarkIcon,
  DashboardIcon,
  DocumentIcon,
  FolderIcon,
  ImageIcon,
  ManagedWorkspaceIcon,
  MetamodelIcon,
  PresentationIcon,
  ReportIcon,
  RowIconWrapper,
  ScenarioIcon,
  SurveyIcon,
  TraversalIcon,
  UnknownFileIcon,
  WorkspaceIcon,
  ViewpointIcon,
  BroadcastIcon,
} from './atoms';
import { RowType } from '@ardoq/table';
import { WithPopover, WithPopoverProps } from '@ardoq/popovers';

type RowIconProps = {
  rowType: RowType;
  onClick?: () => void;
  isOpened?: boolean;
  isDisabled?: boolean;
  popoverConfig?: Omit<WithPopoverProps, 'children'>;
};

const RowIcon = ({
  rowType,
  onClick,
  isOpened,
  isDisabled = false,
  popoverConfig,
}: RowIconProps) => {
  return (
    <WithPopover {...popoverConfig} content={popoverConfig?.content}>
      <RowIconWrapper
        onClick={!isDisabled ? onClick : undefined}
        $isDisabled={isDisabled}
      >
        {rowType === RowType.FOLDER && <FolderIcon isOpened={isOpened} />}
        {rowType === RowType.TRAVERSAL && (
          <TraversalIcon data-tooltip-text="Traversal" />
        )}
        {rowType === RowType.BOOKMARK && (
          <BookmarkIcon data-tooltip-text="Bookmark" />
        )}
        {rowType === RowType.WORKSPACE && (
          <WorkspaceIcon data-tooltip-text="Workspace" />
        )}
        {rowType === RowType.MANAGED_WORKSPACE && (
          <ManagedWorkspaceIcon data-tooltip-text="Managed Workspace" />
        )}
        {rowType === RowType.PRESENTATION && (
          <PresentationIcon data-tooltip-text="Presentation" />
        )}
        {rowType === RowType.METAMODEL && (
          <MetamodelIcon data-tooltip-text="Metamodel" />
        )}
        {rowType === RowType.SURVEY && (
          <SurveyIcon
            {...(!isDisabled ? { 'data-tooltip-text': 'Survey' } : undefined)}
          />
        )}
        {rowType === RowType.SCENARIO && (
          <ScenarioIcon data-tooltip-text="Scenario" />
        )}
        {rowType === RowType.REPORT && (
          <ReportIcon data-tooltip-text="Report" />
        )}
        {rowType === RowType.DASHBOARD && (
          <DashboardIcon data-tooltip-text="Dashboard" />
        )}
        {rowType === RowType.VIEWPOINT && (
          <ViewpointIcon data-tooltip-text="Viewpoint" />
        )}
        {rowType === RowType.BROADCAST && (
          <BroadcastIcon data-tooltip-text="Broadcast" />
        )}
        {rowType === RowType.IMAGE && <ImageIcon data-tooltip-text="Image" />}
        {rowType === RowType.DOCUMENT && (
          <DocumentIcon data-tooltip-text="Document" />
        )}
        {rowType === RowType.UNKOWN_FILE && (
          <UnknownFileIcon data-tooltip-text="Miscellaneous" />
        )}
      </RowIconWrapper>
    </WithPopover>
  );
};

export default RowIcon;
