enum MessageType {
  // Client-bound / Incoming
  WELCOME = 0,
  EVENT = 8,
  // Server-bound / Outgoing
  SET_PREFIX = 1,
  SUBSCRIBE_TOPIC = 5,
  UNSUBSCRIBE_TOPIC = 6,
}

/**
 * A topic prefix, is basically a shorthand for a TopicURI
 */
type TopicPrefix = string;
/**
 * A TopicURI is a URI for namespacing topics
 */
type TopicURI = string;
/**
 * A Topic is a named channel that can receive messages
 */
type Topic = string;
/**
 * The SessionId is set by the server on each connection
 */
type SessionId = number;
type Version = number; // Not quite sure about this one
type ServerName = string;

type UnknownMessage = Readonly<[MessageType, ...unknown[]]>;
type WelcomeMessage = [MessageType.WELCOME, SessionId, Version, ServerName];
type EventMessage<Data = unknown> = [MessageType.EVENT, Topic, Data];

const createPrefixMessage = (prefix: string, uri: string) =>
  [MessageType.SET_PREFIX, prefix, uri] as const;

const createSubscribeMessage = (prefix: string, topic: string) =>
  [MessageType.SUBSCRIBE_TOPIC, `${prefix}:${topic}`] as const;

const createUnsubscribeMessage = (prefix: string, topic: string) =>
  [MessageType.UNSUBSCRIBE_TOPIC, `${prefix}:${topic}`] as const;

class ResubscribeError extends Error {
  constructor() {
    super('Cannot resubscribe to topic');
  }
}

export enum ConnectionStatus {
  CREATED,
  CONNECTING,
  CONNECTED,
  RECONNECTING,
  DEAD,
}

const RECONNECT_INTERVAL = 3000;
const MAX_RETRIES = 10;

/**
 * SimpleWAMP is a very simple Websocket wrapper, that tries to be the smallest
 * possible implementation of the subset of WAMP that Ardoq uses.
 *
 * As a simplification, topic URIs are ignored, so only the topic itself is
 * considered. The topics must still be registered to the server.
 *
 * In the context of Ardoq, the job of SimpleWAMP is to speak the WAMP protocol
 * and allow subscriptions to events. SimpleWAMP enforces a maximum of one
 * subscriber per event. If the connection is broken, reconnection is
 * automatically handled and events are resubscribed.
 */
export class SimpleWAMP {
  status = ConnectionStatus.CREATED;

  prefix?: [TopicPrefix, TopicURI];
  subscriptions = new Map<Topic, (event: any) => void>();

  uri: string;

  websocket?: WebSocket;
  sessionId?: number;
  retries = 0;

  connectedCallback?: (wamp: this) => void;
  reconnectingCallback?: (wamp: this, event: CloseEvent) => void;
  disconnectedCallback?: (wamp: this) => void;
  errorCallback?: (wamp: this, event: Event) => void;

  constructor(uri: string) {
    this.uri = uri;
  }

  connect() {
    if (
      this.status !== ConnectionStatus.CREATED &&
      this.status !== ConnectionStatus.DEAD
    ) {
      return;
    }

    this.status = ConnectionStatus.CONNECTING;
    this.connectWs();
  }

  close() {
    this.status = ConnectionStatus.DEAD;
    this.websocket?.close();
    this.disconnectedCallback?.(this);
  }

  private connectWs() {
    if (
      this.status !== ConnectionStatus.CONNECTING &&
      this.status !== ConnectionStatus.RECONNECTING
    ) {
      return;
    }

    this.websocket?.close();
    this.websocket = new WebSocket(this.uri, 'wamp');
    this.websocket.onclose = this.handleClose.bind(this);
    this.websocket.onmessage = this.handleMessage.bind(this);
    this.websocket.onopen = this.handleOpen.bind(this);
    this.websocket.onerror = e => this.errorCallback?.(this, e);
  }

  private handleClose(event: CloseEvent) {
    if (this.status === ConnectionStatus.DEAD) {
      return;
    }
    this.status = ConnectionStatus.RECONNECTING;
    this.reconnectingCallback?.(this, event);
    this.reconnect();
  }

  private reconnect() {
    if (this.retries >= MAX_RETRIES) {
      return this.close();
    }
    this.retries++;

    setTimeout(this.connectWs.bind(this), RECONNECT_INTERVAL);
  }

  private handleOpen(_event: Event) {
    this.retries = 0;
  }

  private handleWelcome([_, sessionId, __, ___]: WelcomeMessage) {
    this.sessionId = sessionId;
    this.status = ConnectionStatus.CONNECTED;

    if (this.prefix) {
      this.send(createPrefixMessage(...this.prefix));
      for (const [topic] of this.subscriptions) {
        this.send(createSubscribeMessage(this.prefix[0], topic));
      }
    }

    this.connectedCallback?.(this);
  }

  private handleEvent<Event>([_, longTopic, event]: EventMessage<Event>) {
    const topicParts = longTopic.split('#');
    const shortTopic = topicParts[topicParts.length - 1];

    this.subscriptions.get(shortTopic)?.(event);
  }

  private handleMessage(event: MessageEvent<any>) {
    const message = JSON.parse(event.data) as UnknownMessage;
    switch (message[0]) {
      case MessageType.WELCOME:
        return this.handleWelcome(message as WelcomeMessage);
      case MessageType.EVENT:
        return this.handleEvent(message as EventMessage);
      default:
        this.errorCallback?.(
          this,
          new CustomEvent('unknown-message', {
            detail: { type: message[0], message },
          })
        );
    }
  }

  private send(message: UnknownMessage) {
    if (this.websocket?.readyState === WebSocket.OPEN) {
      this.websocket.send(JSON.stringify(message));
    }
  }

  setPrefix(prefix: TopicPrefix, uri: TopicURI) {
    this.prefix = [prefix, uri];

    this.send(createPrefixMessage(prefix, uri));
  }

  subscribe<Event>(
    prefix: TopicPrefix,
    topic: Topic,
    callback: (event: Event) => void
  ) {
    if (prefix !== this.prefix?.[0]) {
      throw new Error('Cannot subscribe to event with other prefix');
    }

    if (this.subscriptions.has(topic)) {
      throw new ResubscribeError();
    }

    this.subscriptions.set(topic, callback);

    this.send(createSubscribeMessage(prefix, topic));
  }

  unsubscribe(prefix: TopicPrefix, topic: Topic) {
    this.subscriptions.delete(topic);
    this.send(createUnsubscribeMessage(prefix, topic));
  }

  /**
   * Callback for when the connection is successfully established.
   * Will also be called when the socket is reconnected.
   */
  onConnected(callback: (wamp: this) => void) {
    this.connectedCallback = callback;
  }

  /**
   * Callback for when the connection is dropped, and we start reconnection
   * attempts.
   */
  onReconnecting(callback: (wamp: this, event: CloseEvent) => void) {
    this.reconnectingCallback = callback;
  }

  /**
   * Callback for when the socket is disconnected and no longer tries to
   * reconnect.
   * Called when max number of retries is reached for reconnection, or when
   * `.close()` is called.
   */
  onDisconnected(callback: (wamp: this) => void) {
    this.disconnectedCallback = callback;
  }

  /**
   * Callback for Websocket errors. This is typically when the client cannot
   * establish a connection.
   */
  onError(callback: (wamp: this, event: Event) => void) {
    this.errorCallback = callback;
  }
}
