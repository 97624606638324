import { ArdoqId } from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { logWarn } from '@ardoq/logging';
import { componentApi, scenarioApi } from '@ardoq/api';
import { Features, hasFeature } from '@ardoq/features';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';

export const bulkDeleteComponents = async (components: ArdoqId[]) => {
  if (components.length === 0) return Promise.resolve();

  const componentIds = components.filter(ExcludeFalsy);
  if (componentIds.length !== components.length) {
    logWarn(
      Error(
        'bulkDeleteComponents called with components array containing null ids'
      )
    );
  }
  if (componentIds.length === 0) return Promise.resolve();

  const scenarioId =
    hasFeature(Features.SCENARIOS_BETA) && getActiveScenarioId();
  if (scenarioId) {
    return await scenarioApi.deleteComponents(scenarioId, componentIds);
  }
  return await componentApi.deleteComponents(componentIds);
};
