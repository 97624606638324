import { differenceBy, without } from 'lodash';
import { dispatchAction } from '@ardoq/rxbeach';
import { AppModules } from 'appContainer/types';
import { hideRightPane, requestShowAppModule } from 'appContainer/actions';
import { isPresentationMode } from 'appConfig';
import {
  ArdoqError,
  ContextSort,
  getErrorForLogging,
  NumericSortOrder,
  SortAttribute,
} from '@ardoq/common-helpers';
import { ComponentBackboneModel, Reference, Workspace } from 'aqTypes';
import { logError, logWarn } from '@ardoq/logging';
import { onFirstAction, subscribeToAction } from 'streams/utils/streamUtils';
import { notifyComponentsRemoved } from 'streams/components/ComponentActions';
import { notifyReferencesRemoved } from 'streams/references/ReferenceActions';
import { partition } from 'lodash';
import {
  APIPresentationAssetAttributes,
  ArdoqId,
  ViewIds,
} from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  notifyComponentChanged,
  notifyContextWorkspaceOrderChanged,
  notifyPresentationChanged,
  notifyReferenceContextChanged,
  notifyScenarioChanged,
  notifySortChanged,
  notifyWorkspaceChanged,
  notifyWorkspaceClosed,
  notifyWorkspaceOpened,
  WorkspaceChanged,
} from 'streams/context/ContextActions';
import { loadAggregateWorkspaces, workspacesClosed } from 'sync/actions';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import {
  notifyPerspectivesProcessedChangedWorkspace,
  triggerFiltersChangedEvent,
} from 'streams/filters/FilterActions';
import { isSameSet } from 'utils/isSameSet';
import { allWorkspacesClosed } from 'streams/views/mainContent/actions';
import { getConnectedWorkspaceIds } from 'streams/linkedWorkspaces$';
import { notifyWorkspaceAggregatedLoaded } from 'streams/workspaces/actions';
import * as profiling from '@ardoq/profiling';
import { TrackingLocation } from './tracking/types';
import { clearLoadedState } from 'loadedState/actions';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { uniqBy } from 'lodash';
import { getDefaultSort } from 'getDefaultSort';
import { notifyPresentationCreatedByUser } from 'streams/presentations/actions';
import { EventType } from 'sync/types';
import { websocket$ } from 'sync/websocket$';
import { isPresentationEvent } from 'streams/presentations/presentations$';
import { filter, tap } from 'rxjs';
import { replaceViewIfNotEligible } from 'viewDeprecation/restrictedViews';

interface SetContextWorkspaceArgs {
  workspace: Workspace | null;
  resetComponentContext?: boolean;
  /**
   * A flag to indicate if the new context workspace was loaded as part of the
   * current context update.
   */
  wasContextWorkspaceLoaded: boolean;
  shouldAwaitPerspectives?: boolean;
}

export type StateOpts = { keepCurrentViewPointMode?: boolean };
const { ASC, DESC } = NumericSortOrder;

const dispatchNotifyWorkspaceChanged = async (
  payload: WorkspaceChanged,
  shouldAwaitPerspectives = false
) => {
  if (shouldAwaitPerspectives) {
    await dispatchActionAndWaitForResponse(
      notifyWorkspaceChanged(payload),
      notifyPerspectivesProcessedChangedWorkspace,
      notifyPerspectivesProcessedChangedWorkspace
    );
  } else {
    dispatchAction(notifyWorkspaceChanged(payload));
  }
};

const replaceViews = (workspace: Workspace) => {
  workspace.setViews([
    ...new Set(
      workspace
        .getWorkspaceViews()
        .map(viewId => replaceViewIfNotEligible(viewId)!)
    ),
  ]);
};

type ContextWSPreLoadDetails = {
  shouldPreserveView: boolean;
  oldActiveViewIds: ViewIds[];
  wasContextWorkspaceLoaded: boolean;
};
/** Called before loading aggregated workspace data. If there is an active
 * visualization, ensure that the same visualization is visible after we switch
 * workspaces. */
const getContextWSPreLoadDetails = (
  workspace: Workspace | null
): ContextWSPreLoadDetails => ({
  shouldPreserveView: false,
  oldActiveViewIds: [],
  wasContextWorkspaceLoaded: Boolean(workspace && !workspace.aggregateLoaded),
});

export type LoadWorkspacesOpts = {
  /**
   * Additional loaded workspaces
   */
  workspaces?: Workspace[];
  /**
   * The "selected" workspace
   */
  contextWorkspace?: Workspace;
  resetComponentContext?: boolean;
  shouldAwaitPerspectives?: boolean;
  workspaceOrder?: ArdoqId[];
  transaction?: profiling.Transaction;
  trackingLocation?: TrackingLocation;
};

export type CloseWorkspaceOpts = {
  isRegisterLoadedState?: boolean;
};

type SetOpenWorkspacesArgs = {
  /**
   * All the workspaces which are supposed to be set or stay open.
   */
  workspaces: Workspace[];
  activeWorkspace?: Workspace | null;
  shouldAwaitPerspectives?: boolean;
  trackingLocation?: TrackingLocation | null;
  workspaceOrder?: ArdoqId[] | null;
};

const getNewActiveWorkspace = ({
  activeWorkspace,
  currentlyActiveWorkspace,
  workspacesToClose,
  openWorkspaces,
}: {
  activeWorkspace: Workspace | null;
  currentlyActiveWorkspace: Workspace | null;
  workspacesToClose: Workspace[];
  openWorkspaces: Workspace[];
}) => {
  if (activeWorkspace && activeWorkspace !== currentlyActiveWorkspace) {
    return activeWorkspace;
  }
  if (!currentlyActiveWorkspace) {
    return openWorkspaces[0] ?? null;
  }
  if (workspacesToClose.includes(currentlyActiveWorkspace)) {
    return openWorkspaces[0] ?? null;
  }
  return null;
};

export class ContextBackboneModel {
  sort: ContextSort;
  defaultSort = getDefaultSort();
  contextMessage: any;
  _component: ComponentBackboneModel | null;
  _reference: Reference | null;
  _workspace: Workspace | null;
  // This is a bit a workaround, trying to follow as closely as possible the
  // existing patterns, but scenario is only set if it's the selected node
  // in the navigator, so more like the selected component, even though
  // all the workspaces are actualluy hierachically nodes of the scenario.
  _scenarioId: ArdoqId | null;
  _workspaces: Workspace[];
  _presentationId: ArdoqId | null;
  _isExploreMode: boolean;
  constructor() {
    this._component = null;
    this._workspace = null;
    this._scenarioId = null;
    this._workspaces = [];
    this._reference = null;
    this._presentationId = null;
    this._isExploreMode = false;
    this.sort = getDefaultSort();
    subscribeToAction(notifyComponentsRemoved, ({ componentIds }) => {
      if (this._component && componentIds.includes(this._component.id)) {
        this.setComponent(null);
      }
    });
    subscribeToAction(notifyReferencesRemoved, ({ referenceIds }) => {
      if (this._reference && referenceIds.includes(this._reference.id)) {
        this.setReference(null);
      }
    });
    websocket$
      .pipe(
        filter(isPresentationEvent),
        tap(event => {
          const presentation = event.data;
          switch (event['event-type']) {
            case EventType.DELETE:
              if (this.presentationId() === presentation._id) {
                this.setPresentation(null);
              }
              break;
            case EventType.UPDATE:
              if (this.presentationId() === presentation._id) {
                this.setPresentation(presentation);
              }
              break;
            default:
              break;
          }
        })
      )
      .subscribe();
    subscribeToAction(notifyPresentationCreatedByUser, presentation => {
      this.setPresentation(presentation);
    });
  }

  /**
   * Sets the open workspaces in viewpoint mode, preventing accidental workspace
   * loads. It compares the provided workspaces against those currently open,
   * updating the open workspaces to include only those not already open.
   * Workspaces not in the provided list are closed. Additionally, it sets the
   * active workspace if specified or required.
   */
  setOpenWorkspacesInViewpointMode({
    workspaces,
    activeWorkspace = null,
    shouldAwaitPerspectives = false,
    trackingLocation,
    workspaceOrder,
  }: SetOpenWorkspacesArgs) {
    // Ensure there are no duplicates.
    const openWorkspaces = uniqBy(workspaces, 'id');
    const workspacesToOpen = differenceBy(
      openWorkspaces,
      this._workspaces,
      'id'
    );
    const workspacesToClose = differenceBy(
      this._workspaces,
      openWorkspaces,
      'id'
    );

    workspacesToOpen.forEach(workspace => {
      workspace.aggregateLoaded = true;
      this._workspaces.push(workspace);
      dispatchAction(
        notifyWorkspaceOpened({
          id: workspace.id,
          useCase:
            workspaceInterface.getAttribute(workspace.id, 'ardoq-persistent')?.[
              'use-case'
            ] ?? undefined,
          trackingLocation: trackingLocation ?? undefined,
        })
      );
      dispatchAction(
        notifyWorkspaceAggregatedLoaded({ workspaceId: workspace.getId() })
      );
    });

    const newActiveWorkspace = getNewActiveWorkspace({
      activeWorkspace,
      currentlyActiveWorkspace: this._workspace,
      workspacesToClose,
      openWorkspaces,
    });

    if (newActiveWorkspace) {
      this.setContextWorkspace({
        workspace: newActiveWorkspace,
        resetComponentContext: true,
        wasContextWorkspaceLoaded:
          workspacesToOpen.includes(newActiveWorkspace),
        shouldAwaitPerspectives,
      });
    }

    workspacesToClose.forEach(workspace => this.justCloseWorkspace(workspace));

    if (workspaceOrder) {
      this.setWorkspaceOrder(workspaceOrder);
    }
  }

  setSort(attr?: string | null, name?: string, order?: number) {
    const newSort = attr
      ? {
          attr: attr,
          name: name,
          order: order || ASC,
        }
      : this.defaultSort;
    if (!this.sort) {
      this.sort = newSort;
    } else if (
      this.sort.attr !== newSort.attr ||
      this.sort.name !== newSort.name ||
      this.sort.order !== newSort.order
    ) {
      this.sort = newSort;
    }
    if (this.sort === newSort) {
      this.triggerSortChangeEvent();
    }
  }
  triggerSortChangeEvent() {
    if (this.getSort()) {
      dispatchAction(notifySortChanged({ sort: this.sort }));
      dispatchAction(triggerFiltersChangedEvent());
    }
  }
  sortComponentsByReferences(sort: ContextSort) {
    this.sort = sort;
  }
  toggleSort(attr: string, name: string, skipDefault: boolean) {
    // Toggles from ASC/DESC/No order
    const sort = this.getSort();
    if (sort !== null && sort.attr === attr) {
      if (sort.order === ASC) {
        this.setSort(attr, name, DESC);
      } else if (skipDefault) {
        this.setSort(attr, name, ASC);
      } else {
        this.setSort(null);
      }
    } else {
      this.setSort(attr, name, ASC);
    }
  }

  isSortedByOrder() {
    return this.getSort().attr === SortAttribute.ORDER;
  }

  isSortedAscending() {
    return this.getSort().order === ASC;
  }

  getSort() {
    return this.sort;
  }

  activeWorkspaceId(): string | null {
    return this._workspace?.getId() ?? null;
  }

  activeModelId(): string | null {
    return this._workspace?.getModel()?.id ?? null;
  }

  /**
   * @deprecated Use `activeWorkspaceId` instead
   */
  workspace() {
    return this._workspace;
  }

  /**
   * @deprecated Use `componentId` instead
   */
  component() {
    return this._component;
  }
  componentId(): string | null {
    return this._component?.getId() ?? null;
  }

  scenarioId() {
    return this._scenarioId;
  }

  referenceId(): string | null {
    return this._reference?.id || null;
  }

  prepopulateWithWorkspaces(workspaces: Workspace[]) {
    this._workspaces = workspaces;
  }

  async clearContextWorkspace() {
    await this.setContextWorkspace({
      workspace: null,
      resetComponentContext: false,
      ...getContextWSPreLoadDetails(null),
    });
  }

  /** Sets the context to a loaded workspace, or clears workspace context */
  private async setContextWorkspace({
    workspace,
    resetComponentContext = true,
    wasContextWorkspaceLoaded,
    shouldAwaitPerspectives = false,
  }: SetContextWorkspaceArgs) {
    const transaction = profiling.startTransaction(
      'setContextWorkspace',
      500,
      profiling.Team.CORE
    );

    const workspaceActuallyChanged = workspace !== this._workspace;
    const hasScenarioChanged = this._scenarioId !== null;
    this._scenarioId = null;
    this._workspace = workspace;

    if (resetComponentContext || !workspace) {
      this.setComponent(null);
      this.setReference(null);
    }

    const workspaceId = workspace?.getId() ?? null;
    const payload = { workspaceId };
    if (workspaceActuallyChanged) {
      await dispatchNotifyWorkspaceChanged(payload, shouldAwaitPerspectives);
    } else if (hasScenarioChanged) {
      dispatchAction(notifyWorkspaceChanged(payload));
    }

    if (workspace) {
      const defaultSort = workspace.get('defaultSort');

      if (
        wasContextWorkspaceLoaded &&
        defaultSort &&
        this.getSort().attr !== defaultSort
      ) {
        this.setSort(defaultSort);
      }

      if (
        !wasContextWorkspaceLoaded ||
        !defaultSort ||
        this.getSort().attr === defaultSort
      ) {
        // if this.setSort(defaultSort) wasn't called, sync the view with the current context sort
        this.triggerSortChangeEvent();
      }
    }

    profiling.endTransaction(transaction, {
      metadata: {
        shouldAwaitPerspectives,
        hasScenarioChanged,
        resetComponentContext,
        wasContextWorkspaceLoaded,
        workspaceActuallyChanged,
        workspaceId: workspace?.id,
      },
    });
  }

  private openLoadedWorkspace(
    workspace: Workspace,
    trackingLocation?: TrackingLocation
  ) {
    this._workspaces.push(workspace);
    replaceViews(workspace);
    dispatchAction(
      notifyWorkspaceOpened({
        id: workspace.id,
        useCase:
          workspaceInterface.getAttribute(workspace.id, 'ardoq-persistent')?.[
            'use-case'
          ] ?? undefined,
        trackingLocation,
      })
    );
  }

  // Loads workspaces in the background (i.e. doesn't set them as context)
  async loadWorkspaces({
    workspaces = [],
    contextWorkspace,
    resetComponentContext = true,
    shouldAwaitPerspectives = false,
    workspaceOrder,
    transaction = profiling.noOpTransaction,
    trackingLocation,
  }: LoadWorkspacesOpts) {
    const loadWorkspacesSpan = profiling.startSpan(
      transaction,
      'load workspaces'
    );

    let actualWorkspaceOrder = workspaceOrder;
    if (actualWorkspaceOrder && this._workspaces.length > 0) {
      const currentWorkspaceOrder = this._workspaces.map(({ id }) => id);
      actualWorkspaceOrder = [
        ...currentWorkspaceOrder,
        ...actualWorkspaceOrder.filter(
          id => !currentWorkspaceOrder.includes(id)
        ),
      ];
    }
    const preloadDetails = getContextWSPreLoadDetails(contextWorkspace || null);

    const allWorkspacesToLoad = [...workspaces, contextWorkspace].filter(
      ExcludeFalsy
    );
    const unloadedWorkspaces = allWorkspacesToLoad.filter(
      ws => !this._workspaces.includes(ws)
    );
    const [unloadedWithAggData, unloadedWithoutAggData] = partition(
      unloadedWorkspaces,
      ws => ws.aggregateLoaded || isPresentationMode()
    );

    unloadedWithAggData.forEach(ws =>
      this.openLoadedWorkspace(ws, trackingLocation)
    );

    const unloadedWithoutAggregateDataPromises = unloadedWithoutAggData.map(
      ws =>
        new Promise<void>((resolve, reject) => {
          ws.once('errorLoading', (ardoqError: ArdoqError) => {
            reject(ardoqError);
          });
          onFirstAction(notifyWorkspaceAggregatedLoaded, () => {
            // There may be other requests to load this workspace before the logic below
            // had time to complete, therefore the same workspace could get loaded multiple times.
            if (!this._workspaces.includes(ws)) {
              this.openLoadedWorkspace(ws, trackingLocation);
            }
            resolve();
          });
        })
    );

    dispatchAction(
      loadAggregateWorkspaces({
        workspaces: unloadedWithoutAggData,
        transaction,
      })
    );

    const results = await Promise.allSettled(
      unloadedWithoutAggregateDataPromises
    );
    const rejected = results.filter(res => res.status === 'rejected');
    if (rejected.length) {
      const errors = rejected
        .map(({ reason }) => reason as ArdoqError)
        .map(getErrorForLogging);
      const error = new AggregateError(errors, 'Could not load workspaces');
      logError(error, null, {
        numFailedWorkspaceLoads: rejected.length,
        numTotalAttemptedToLoad: unloadedWithoutAggregateDataPromises.length,
      });
      throw error;
    }

    if (contextWorkspace)
      await this.setContextWorkspace({
        workspace: contextWorkspace,
        resetComponentContext,
        shouldAwaitPerspectives,
        ...preloadDetails,
      });

    if (actualWorkspaceOrder) {
      this.setWorkspaceOrder(actualWorkspaceOrder);
    }
    profiling.endSpan(loadWorkspacesSpan);
  }

  setWorkspaceOrder(workspaceOrder: ArdoqId[]) {
    const loadedWorkspaceIds = this._workspaces.map(({ id }) => id);
    if (!isSameSet(workspaceOrder, loadedWorkspaceIds)) {
      logError(
        Error("Ids of workspace order doesn't match loaded workspaces"),
        null,
        { workspaceOrder, loadedWorkspaceIds }
      );
      return;
    }

    if (
      this._workspaces.every(
        (workspace, index) => workspace.id === workspaceOrder[index]
      )
    ) {
      return;
    }

    const dict = Object.fromEntries(
      this._workspaces.map(workspace => [workspace.id, workspace])
    );
    this._workspaces = workspaceOrder.map(id => dict[id]);
    dispatchAction(notifyContextWorkspaceOrderChanged());
  }

  isExploreMode() {
    return this._isExploreMode;
  }
  toggleExploreMode(isExploreMode: boolean) {
    this._isExploreMode = isExploreMode;
  }
  /**
   * This method is used when closing all workspaces and resetting the collections
   * manually. An event to notify the views must also be triggered.
   *
   */
  closeAllWorkspacesSilently() {
    this._workspaces.forEach(ws => {
      ws.aggregateLoaded = false;
    });
    this._workspaces = [];
    this._workspace = null;
  }
  justCloseWorkspace(ws: Workspace) {
    this._workspaces = without(this._workspaces, ws);
    ws.aggregateLoaded = false;
    dispatchAction(notifyWorkspaceClosed({ workspaceId: ws.id }));
    dispatchAction(
      workspacesClosed({
        openWorkspaceIds: this._workspaces.map(({ id }) => id),
        closedWorkspaceIds: [ws.id],
      })
    );
  }

  async closeWorkspace(
    ws: Workspace,
    { isRegisterLoadedState = true }: CloseWorkspaceOpts = {}
  ) {
    const moreThanOneWorkspaceLoaded = this._workspaces.length > 1;

    if (moreThanOneWorkspaceLoaded) {
      const isClosingActiveWs = ws === this._workspace;
      this.justCloseWorkspace(ws);

      const newWS = isClosingActiveWs
        ? this._workspaces[this._workspaces.length - 1]
        : this._workspace;
      if (this._workspace === newWS) {
        // The context hasn't really changed, but since the old event
        // was triggering a workspace change when a workspace
        // was unloaded, we need to keep this around
        const workspaceId = newWS?.getId() ?? null;
        await dispatchNotifyWorkspaceChanged({ workspaceId });
      } else {
        this.loadWorkspaces({ contextWorkspace: newWS || undefined });
      }
    } else {
      await this.closeAllWorkspaces({ isRegisterLoadedState });
    }
  }

  async closeWorkspacesAndClearContext(closedWorkspaces: Workspace[]) {
    this.closeAllWorkspacesSilently();
    await this.clearContextWorkspace();
    closedWorkspaces.forEach(({ id: workspaceId }) =>
      dispatchAction(notifyWorkspaceClosed({ workspaceId }))
    );

    dispatchAction(
      workspacesClosed({
        openWorkspaceIds: [],
        closedWorkspaceIds: closedWorkspaces.map(({ id }) => id),
      })
    );
  }

  /**
   * Used to reset the state before we load a search or traversal slide.
   *
   * When we load a traversal slide we close all workspaces to reset the
   * collections because a traversal loads only a subset of all the
   * workspace entities (component or references). This is needed because for
   * a slide loaded with workspace loading we have an optimization to not reload
   * all workspaces, but only the ones which are new compared to the previous
   * slide, keeping all loaded workspace entities of these workspaces in the
   * according collections.
   */

  async resetState({ keepCurrentViewPointMode = false }: StateOpts = {}) {
    const closedWorkspaces = [...this._workspaces];
    await this.closeWorkspacesAndClearContext(closedWorkspaces);

    dispatchAction(allWorkspacesClosed({ keepCurrentViewPointMode }));
  }

  async closeAllWorkspaces({
    isRegisterLoadedState = true,
  }: CloseWorkspaceOpts = {}) {
    await this.resetState();

    if (!isPresentationMode()) {
      this.setPresentation(null);
    }

    dispatchAction(requestShowAppModule({ selectedModule: AppModules.HOME }));
    dispatchAction(hideRightPane());

    if (isRegisterLoadedState) {
      dispatchAction(clearLoadedState());
    }
  }

  workspaces() {
    return this._workspaces;
  }

  getConnectedWorkspaceIds() {
    return getConnectedWorkspaceIds(this.workspaces().map(({ id }) => id));
  }

  forceUpdateComponent(
    comp: ComponentBackboneModel,
    trackingLocation?: TrackingLocation
  ) {
    this._component = null;
    this.setComponent(comp, trackingLocation);
  }
  async setComponent(
    c?: ComponentBackboneModel | null,
    trackingLocation?: TrackingLocation
  ) {
    const comp = c === undefined ? null : c;
    if (this._component !== comp || this._reference !== null) {
      const wrongWorkspaceSet =
        this._workspace &&
        comp?.get &&
        comp.get('rootWorkspace') !== this._workspace.id &&
        Boolean(comp.get('rootWorkspace'));

      if (comp && (!this._workspace || wrongWorkspaceSet)) {
        if (!comp.getWorkspace) {
          logWarn(
            Error('Context.setComponent invoked with non-component argument')
          );
          return;
        }
        const workspace = comp.getWorkspace();

        if (workspace) {
          await this.loadWorkspaces({
            contextWorkspace: workspace,
            resetComponentContext: false,
            trackingLocation,
          });
          this.setComponent(comp);
          return;
        }
      }
      this.setReference(null, true);
      this._scenarioId = null;
      this._component = comp;
      const workspaceId = this.workspace()?.getId() ?? null;
      const action = comp
        ? notifyComponentChanged({ component: comp })
        : notifyWorkspaceChanged({ workspaceId });
      dispatchAction(action);
    }
  }

  setScenarioId(scenarioId: ArdoqId | null) {
    if (this._scenarioId !== scenarioId) {
      this.setComponent(null);
      this._scenarioId = scenarioId;
      dispatchAction(notifyScenarioChanged(scenarioId));
    }
  }

  setReference(ref: Reference | null, skipEvent?: boolean) {
    if (this._reference !== ref) {
      if (ref) {
        if (this._component !== ref.getTarget()) {
          this._component = ref.getSource();
          this._workspace = this._component.getWorkspace();
        }
      }

      this._reference = ref;
      if (!skipEvent) {
        dispatchAction(notifyReferenceContextChanged(this._reference));
      }
    }
  }

  setPresentation(presentation: APIPresentationAssetAttributes | null) {
    if (this._presentationId !== presentation?._id) {
      this._presentationId = presentation?._id ?? null;
      dispatchAction(notifyPresentationChanged({ presentation }));
    }
  }

  presentationId() {
    return this._presentationId;
  }
}

export default new ContextBackboneModel();
