import { documentArchiveAttachments } from 'collections/documentArchive';
import { DocumentArchiveContext } from 'components/DocumentBrowser/types';
import { APIAttachment } from '@ardoq/api-types';
import { createStreamFromBackboneCollection } from 'streams/utils/streamUtils';
import { map } from 'rxjs';
import { keyBy } from 'lodash';

const documentArchiveAttachments$ = createStreamFromBackboneCollection<
  APIAttachment & { context: DocumentArchiveContext }
>(documentArchiveAttachments, [
  '_id',
  'description',
  'lastUpdated',
  'lastModifiedByName',
  'folder',
  'createdByEmail',
  'created',
  'rootWorkspace',
  'size',
  'filename',
  'createdBy',
  'lastModifiedBy',
  'locked',
  'content-type',
  'lastModifiedByEmail',
  'uri',
  '_version',
  'ardoq',
  'createdByName',
  'context',
]).pipe(
  map(state => {
    const models = state.models.map(normalizeAttachmentContext);
    return {
      byId: keyBy(models, '_id'),
      sortIds: models.map(m => m._id),
      models,
    };
  })
);
// Because context is not part of our backend, we need to add it to the attachment object.
// We should eventually be able to remove context from the object, as it is probably not needed as
// context can be inferred from the rootWorkspace field.
const normalizeAttachmentContext = (attachment: APIAttachment) => {
  return {
    ...attachment,
    context: attachment.rootWorkspace
      ? DocumentArchiveContext.WS
      : DocumentArchiveContext.ORG,
  };
};

export default documentArchiveAttachments$;
