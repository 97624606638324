import {
  GroupByBackboneModel,
  PerspectiveBackboneModel,
  PerspectiveFilters,
} from 'aqTypes';
import {
  getModifiersAsQueryString,
  getPerspectiveModifiersAsQueryString,
} from 'perspectives/util';
import { ArdoqId } from '@ardoq/api-types';
import { getCurrentLocale, localeCompareNumericLowercase } from '@ardoq/locale';
import Backbone from 'backbone';
import BaseBackboneCollection from 'BaseBackboneCollection';
import { DynamicPerspectiveFilter } from '@ardoq/perspectives';
import Filters from 'collections/filters';
import GroupByCollection from 'collections/groupByCollection';
import Perspective from 'models/perspective';
import activeFilter$ from 'streams/filters/activeFilter$';
import { allWorkspacesClosed } from 'streams/views/mainContent/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import dynamicFilters from 'filters/dynamicFilters';
import { getApiUrl } from 'backboneExtensions';
import { setActivePerspective } from 'streams/filters/FilterActions';
import { subscribeToAction } from 'streams/utils/streamUtils';
import clearPerspectives from './helpers/clearPerspectives';

const perspectiveIdQueryString = (perspectiveId: ArdoqId) =>
  `ps_@id=${perspectiveId}&`;

interface CreateAttributes {
  filters: PerspectiveFilters;
  groupBys: GroupByBackboneModel[];
  dynamicFilters: DynamicPerspectiveFilter[];
  name?: string | number | string[];
  workspace: string;
}
interface CreateOptions {
  setAsCurrent?: boolean;
  collection?: Perspectives;
}
interface CreateFromCurrentModifiersOptions {
  name?: string | number | string[];
  workspaceId: string;
  setAsCurrent: boolean;
}

function getCurrentGroupByAttributes() {
  return GroupByCollection.filter(filter => filter.isValid()).map(
    filter => filter.attributes
  );
}

class Perspectives extends BaseBackboneCollection<PerspectiveBackboneModel>({
  comparator: (a: PerspectiveBackboneModel, b: PerspectiveBackboneModel) =>
    localeCompareNumericLowercase(
      a.get('name'),
      b.get('name'),
      getCurrentLocale()
    ),
  model: Perspective,
  url: `${getApiUrl(Backbone.Collection)}/api/perspective`,
}) {
  constructor() {
    super();

    subscribeToAction(allWorkspacesClosed, () => {
      clearPerspectives({ shouldTriggerChangeEvent: true });
    });
  }
  create(
    attributes: CreateAttributes,
    options: Backbone.ModelSaveOptions & CreateOptions = {}
  ) {
    options.collection = this;

    const perspective = new Perspective(attributes, options);
    const setAsCurrent = options.setAsCurrent;

    return perspective.save(undefined, {
      wait: true,
      success: () => {
        this.add(perspective);
        if (setAsCurrent) {
          dispatchAction(
            setActivePerspective({
              id: perspective.id,
              queryString: getModifiersAsQueryString(),
            })
          );
        }
      },
    });
  }
  createFromCurrentModifiers(options: CreateFromCurrentModifiersOptions) {
    return this.create(
      {
        filters: Filters.getPersistedFilters(),
        groupBys: getCurrentGroupByAttributes(),
        dynamicFilters: dynamicFilters.getDefinitions(),
        name: options.name,
        workspace: options.workspaceId,
      },
      { setAsCurrent: options.setAsCurrent }
    );
  }

  getCurrentSet() {
    const selectedPerspectiveId = activeFilter$.state.selectedPerspectiveId;
    if (selectedPerspectiveId === null) {
      return undefined;
    }
    return this.get(selectedPerspectiveId);
  }

  updateCurrentPerspective() {
    const currentSet = this.getCurrentSet();
    if (currentSet) {
      currentSet.set({
        filters: Filters.getPersistedFilters(),
        groupBys: getCurrentGroupByAttributes(),
        dynamicFilters: dynamicFilters.getDefinitions(),
      });

      dispatchAction(
        setActivePerspective({
          id: currentSet.id,
          queryString: getModifiersAsQueryString(),
        })
      );
      return currentSet.save();
    }
    return Promise.resolve();
  }

  isCurrentSetModified() {
    const {
      state: {
        activeFiltersQueryString,
        selectedPerspectiveQueryString,
        selectedPerspectiveId,
      },
    } = activeFilter$;
    if (
      !selectedPerspectiveId ||
      activeFiltersQueryString === selectedPerspectiveQueryString
    ) {
      return false;
    }
    if (
      selectedPerspectiveId &&
      activeFiltersQueryString ===
        perspectiveIdQueryString(selectedPerspectiveId)
    ) {
      const perspective = this.get(selectedPerspectiveId);
      if (!perspective) {
        return false;
      }
      return (
        getModifiersAsQueryString() !==
        getPerspectiveModifiersAsQueryString(perspective)
      );
    }
    return true;
  }

  encodeURI() {
    const currentSet = this.getCurrentSet();
    if (currentSet && !this.isCurrentSetModified()) {
      return perspectiveIdQueryString(currentSet.id);
    }
    return getModifiersAsQueryString();
  }
}

export default new Perspectives();
