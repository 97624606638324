import Backbone from 'backbone';
import UserModel from 'models/user';
import { isPresentationMode } from 'appConfig';
import { trackLoadedApplication } from 'tracking/events/core';
import { initializeUserTracking } from 'tracking/tracking';
import { isEqual } from 'lodash';
import { PromiseQueue, queuePromises } from 'utils/PromiseQueue';
import { logError } from '@ardoq/logging';
import {
  Organization,
  PersonalSetting,
  PresentationReadPermissions,
} from '@ardoq/api-types';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';
import { getApiUrl } from 'backboneExtensions';
import { BasicModel, CurrentUserBackboneModel } from 'aqTypes';
import { userApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import loadedPresentation$ from 'presentation/streams/loadedPresentation$';

type CurrentUserClass = CurrentUserBackboneModel & {
  new (): CurrentUserBackboneModel;
};
const Model: CurrentUserClass = UserModel.extend({
  idAttribute: '_id',
  url: `${getApiUrl(Backbone.Model)}/api/user/current_user`,
  loaded: false,
  EVENT_SETTINGS_CHANGED: 'EVENT_SETTINGS_CHANGED',
  initialize: function (this: CurrentUserBackboneModel) {
    if (isPresentationMode()) {
      // in presentation mode the user should be loaded if the presentation is not public
      const presentationData = loadedPresentation$.state.presentation;
      if (
        !presentationData ||
        presentationData.readAccess === PresentationReadPermissions.ALL
      ) {
        this.set('public', true);
        this.loaded = true;
        this.trigger('sync', this);
        this.trigger('loaded');
        return;
      }
    }
    this.once('sync', () => {
      // Attributes might be updated via the User object - and it does not have organizations
      // list which is only available from current_user services
      // Cache organizations list.
      this.organization = this.attributes.organization;
      this.organizations = this.attributes.organizations;
      initializeUserTracking(this.attributes, this.getOrganization());
      trackLoadedApplication();
    });

    /*
     * Saving a user might happen very frequently.
     * Thus, we must chain user saves to ensure that we don't
     * update the user with an incorrect version
     * (this can happen when a new version is made because of a previous save)
     */
    const saveQueue = new PromiseQueue();
    this.save = queuePromises(this.save.bind(this), saveQueue);
    this._savePersonalSettings = queuePromises(
      this._savePersonalSettings.bind(this),
      saveQueue
    );
  },
  /**
   * Since the url of this model is /current_user
   * we must save it by delegating to UserModel (which puts to the correct endpoint)
   */
  save: function (attributes: Record<string, any>, options = {}) {
    return UserModel.prototype.save
      .call(this, attributes, {
        ...options,
        url: `${getApiUrl(Backbone.Model)}/api/user/${this.id}`,
      })
      .catch((error: unknown) => {
        logError(error as Error, 'Failed to save user');
      });
  },
  isLoaded: function (this: CurrentUserBackboneModel) {
    return this.loaded;
  },
  whenLoaded: function (this: CurrentUserBackboneModel, cb: VoidFunction) {
    if (this.loaded) {
      cb();
    } else {
      this.once('loaded', cb);
    }
  },
  getOrganization: function (this: CurrentUserBackboneModel) {
    return (
      this.organization || {
        id: '',
        name: 'undefined-org',
        label: 'Undefined Organization',
      }
    );
  },
  isTrial: function (this: CurrentUserBackboneModel) {
    return this.organization && this.organization['is-trial'];
  },
  isExpired: function (this: CurrentUserBackboneModel) {
    return this.organization && this.organization.expired;
  },
  getDaysRemainingOfTrial: function (this: CurrentUserBackboneModel) {
    return this.organization && this.organization['days-remaining-trial'];
  },
  getFavoriteTemplates: function (this: CurrentUserBackboneModel) {
    return this.getPersonalSetting(PersonalSetting.FAVORITE_TEMPLATES) || [];
  },
  toggleFavoriteTemplate: function (
    this: CurrentUserBackboneModel,
    id: string
  ) {
    const favorites = this.getFavoriteTemplates();
    const isAtIndex = favorites.indexOf(id);

    if (isAtIndex === -1) {
      favorites.push(id);
    } else {
      favorites.splice(isAtIndex, 1);
    }

    this.setPersonalSetting(
      PersonalSetting.FAVORITE_TEMPLATES,
      favorites.filter(Boolean)
    );
    return this.save();
  },
  getOrganizations: function (this: CurrentUserBackboneModel) {
    const locale = getCurrentLocale();

    return (this.get('organizations') || []).sort(
      (a: Organization, b: Organization) =>
        localeCompare(a.name, b.name, locale)
    );
  },
  isBackofficeUser: function (this: CurrentUserBackboneModel) {
    return Boolean(this.get('backofficeUser'));
  },
  isArdoqEmployee: function (this: CurrentUserBackboneModel) {
    return /.+@ardoq.com$/.test(this.get('email') || '');
  },
  _savePersonalSettings: function (this: CurrentUserBackboneModel) {
    return userApi
      .updateSettings({ userId: this.id, settings: this.get('settings') })
      .then(attributes => {
        if (isArdoqError(attributes)) {
          logError(attributes, 'Failed to save user settings');
        } else {
          // Update the user settings and the with the response from the server
          this.attributes.settings = attributes.settings;
          this.attributes._version = attributes._version;
          this.trigger('sync', this);
        }
      });
  },
  /** Sets a key val on current user and auto saves value. */
  setPersonalSetting: function (
    this: CurrentUserBackboneModel,
    key?: string,
    val?: unknown,
    forceUpdate?: boolean
  ) {
    return this.setPersonalSettings([[key, val]], forceUpdate);
  },
  /**
   * Sets personal settings on the user and auto saves the value.
   * Personal settings are not saved in presentation mode (because currentUser is not loaded there).
   */
  setPersonalSettings: function (
    this: CurrentUserBackboneModel,
    entries: [key: string, value: any][],
    forceUpdate?: boolean
  ) {
    const settings = this.get('settings') || {};
    let hasChanges = forceUpdate;
    entries.forEach(([key, val]) => {
      if (!isEqual(settings[key], val) || forceUpdate) {
        hasChanges = true;
        settings[key] = val;
        this.set('settings', settings);
        this.trigger(this.EVENT_SETTINGS_CHANGED, { key, val });
      }
    });
    return hasChanges && !isPresentationMode()
      ? this._savePersonalSettings()
      : Promise.resolve(this);
  },
  getPersonalSetting: function (this: CurrentUserBackboneModel, key: string) {
    return (this.get('settings') || {})[key];
  },
  /**
   * @param {BasicModel} obj
   * @this {CurrentUserBackboneModel}
   */
  canUnlock: function (this: CurrentUserBackboneModel, obj: BasicModel) {
    return this.id === obj.getLock() || this.isOrgAdmin();
  },
});

export default new Model();
