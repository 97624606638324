import { ExcludeFalsy } from '@ardoq/common-helpers';
import { Column, ExpandableRowData } from '@ardoq/table';
import { SortByFn, SortById } from '@ardoq/pagination';
import {
  APISurveyResponseLog,
  CreatedResponse,
  DeletedResponse,
  SortOrder,
  UpdatedResponse,
  UpdateType,
} from '@ardoq/api-types';
import { AnimatedChevron } from '@ardoq/icons';
import { SmallGhostButton } from '@ardoq/button';
import { formatDateTime } from '@ardoq/date-time';
import ChangeRowTable from './ChangeRowTable';
import { get } from 'lodash';
import styled from 'styled-components';
import { trackEvent } from 'tracking/tracking';
import { TextOverflow } from '@ardoq/popovers';
import { s8 } from '@ardoq/design-tokens';
import { getCurrentLocale } from '@ardoq/locale';

export const isUpdatedResponse = (
  response: APISurveyResponseLog
): response is UpdatedResponse =>
  response && (response as UpdatedResponse).previousVal !== undefined;

export const isCreatedResponse = (
  response: APISurveyResponseLog
): response is CreatedResponse =>
  response && (response as CreatedResponse).created !== undefined;

export const isDeletedResponse = (
  response: APISurveyResponseLog
): response is DeletedResponse =>
  response && (response as DeletedResponse).deleted !== undefined;

const ClickableTextOverflow = styled(TextOverflow)`
  cursor: pointer;
`;

const BASE_STYLE = { padding: `0 ${s8}` };

const getNameKey = (updateType: UpdateType) =>
  updateType === UpdateType.UPDATED ? 'component.name' : 'name';

const getNameWidth = (updateType: UpdateType) =>
  updateType === UpdateType.UPDATED ? 232 : 332;

const getNameColumnConfig = (
  updateType: UpdateType,
  sortByFn: SortByFn,
  sortById: SortById,
  sortOrder: SortOrder
): Column<ExpandableRowData<APISurveyResponseLog>> => {
  const nameKey = getNameKey(updateType);
  return {
    title: 'Name',
    dataIndex: nameKey,
    headerStyle: { ...BASE_STYLE, width: getNameWidth(updateType) },
    getCellStyle: surveyResponse => {
      if (surveyResponse.meta.level === 0) return BASE_STYLE;
      return { padding: 0, margin: 0 };
    },
    getColSpan: (__value, surveyResponse) =>
      surveyResponse.meta.level === 0 ? 0 : 5,
    onHeaderClick: () => sortByFn(nameKey),
    sortOrder: sortById === nameKey && sortOrder,
    valueRender: (_value, surveyResponse) => {
      const { level } = surveyResponse.meta;
      if (level === 1 && isUpdatedResponse(surveyResponse)) {
        return <ChangeRowTable surveyResponse={surveyResponse} />;
      }
      if (isUpdatedResponse(surveyResponse))
        return (
          <ClickableTextOverflow
            onClick={() => window.open(surveyResponse.component.url)}
          >
            {surveyResponse.component.name}
          </ClickableTextOverflow>
        );
      if (isCreatedResponse(surveyResponse))
        return (
          <ClickableTextOverflow
            onClick={() => window.open(surveyResponse.url)}
          >
            {surveyResponse.name}
          </ClickableTextOverflow>
        );
      return <TextOverflow>{surveyResponse.name}</TextOverflow>;
    },
  };
};

const getWhatChangedColumnConfig = (
  updateType: UpdateType,
  sortByFn: SortByFn,
  sortById: SortById,
  sortOrder: SortOrder
): Column<ExpandableRowData<APISurveyResponseLog>> | undefined => {
  if (updateType !== UpdateType.UPDATED) return;
  return {
    title: 'What changed',
    dataIndex: 'name',
    headerStyle: { ...BASE_STYLE, width: 196 },
    onHeaderClick: () => sortByFn('name'),
    sortOrder: sortById === 'name' && sortOrder,
    cellStyle: BASE_STYLE,
    valueRender: (_value, surveyResponse) => {
      if (!isUpdatedResponse(surveyResponse)) return;
      return <TextOverflow>{surveyResponse.name}</TextOverflow>;
    },
  };
};

const getWhoChangedWidth = (updateType: UpdateType) =>
  updateType === UpdateType.UPDATED ? 216 : 260;

const getWhoChangedColumnConfig = (
  updateType: UpdateType,
  sortByFn: SortByFn,
  sortById: SortById,
  sortOrder: SortOrder
): Column<ExpandableRowData<APISurveyResponseLog>> => ({
  title: 'Changed by',
  dataIndex: 'lastModifiedByName',
  headerStyle: { ...BASE_STYLE, width: getWhoChangedWidth(updateType) },
  onHeaderClick: () => sortByFn('lastModifiedByName'),
  sortOrder: sortById === 'lastModifiedByName' && sortOrder,
  cellStyle: BASE_STYLE,
  valueRender: (_value, surveyResponse) => {
    return <TextOverflow>{surveyResponse.lastModifiedByName}</TextOverflow>;
  },
});

const getWhenKey = (updateType: UpdateType) =>
  updateType === UpdateType.UPDATED
    ? 'lastUpdated'
    : updateType === UpdateType.CREATED
      ? 'created'
      : 'deleted';

const getWhenColumnConfig = (
  updateType: UpdateType,
  sortByFn: SortByFn,
  sortById: SortById,
  sortOrder: SortOrder
): Column<ExpandableRowData<APISurveyResponseLog>> => {
  const whenKey = getWhenKey(updateType);
  return {
    title: 'When',
    dataIndex: whenKey,
    headerStyle: { ...BASE_STYLE, width: 160 },
    onHeaderClick: () => sortByFn(whenKey),
    sortOrder: sortById === whenKey && sortOrder,
    cellStyle: BASE_STYLE,
    valueRender: (_value, surveyResponse) =>
      formatDateTime(get(surveyResponse, whenKey) ?? null, getCurrentLocale()),
  };
};

const getExpanderColumnConfig = (
  expandedFoldersIds: string[],
  expandFolder: (path: string) => void
): Column<ExpandableRowData<APISurveyResponseLog>> => ({
  headerStyle: BASE_STYLE,
  cellStyle: BASE_STYLE,
  valueRender: (_value, surveyResponse) => {
    const { path, level } = surveyResponse.meta;
    if (!isUpdatedResponse(surveyResponse) || level > 0) return;
    const isExpanded = expandedFoldersIds.includes(path);
    return (
      <SmallGhostButton
        onClick={() => {
          expandFolder(path);
          trackEvent(
            `Survey response log: ${isExpanded ? 'collapse' : 'expand'} row`
          );
        }}
      >
        <AnimatedChevron $isUp={isExpanded} />
      </SmallGhostButton>
    );
  },
});

export const getColumns = (
  updateType: UpdateType,
  expandedFoldersIds: string[],
  expandFolder: (path: string) => void,
  sortByFn: SortByFn,
  sortById: SortById,
  sortOrder: SortOrder
) => {
  return [
    getNameColumnConfig(updateType, sortByFn, sortById, sortOrder),
    getWhatChangedColumnConfig(updateType, sortByFn, sortById, sortOrder),
    getWhoChangedColumnConfig(updateType, sortByFn, sortById, sortOrder),
    getWhenColumnConfig(updateType, sortByFn, sortById, sortOrder),
    getExpanderColumnConfig(expandedFoldersIds, expandFolder),
  ].filter(ExcludeFalsy);
};
