import { PermissionContext } from '@ardoq/access-control';
import { APIComponentAttributes, ArdoqId } from '@ardoq/api-types';
import { workspaceAccessControlInterface } from './workspace';
import {
  FieldAccess,
  SubdivisionsContext,
  subdivisionsComponentsOperations,
} from '@ardoq/subdivisions';

type ComponentCanDoArgs = {
  subdivisionsContext: SubdivisionsContext;
  permissionContext: PermissionContext;
  component: Pick<
    APIComponentAttributes,
    '_id' | 'rootWorkspace' | 'subdivisionMembership' | 'model' | 'typeId'
  >;
  fieldId: ArdoqId;
};

export type ComponentCanDoInModesArgs = ComponentCanDoArgs & {
  isPresentationMode: boolean;
  isScenarioMode: boolean;
};

/* ---------------------------------------------------------------------------
   ---------------------------------------------------------------------------
   ----------------                                           ----------------
   ----------------             Fields Access Checks          ----------------
   ----------------                                           ----------------
   ---------------------------------------------------------------------------
   ------------------------------------------------------------------------ */

const compileFieldAccess = (args: ComponentCanDoArgs): FieldAccess => {
  return subdivisionsComponentsOperations.getComponentFieldAccess({
    ...args,
    canEditWorkspace: workspaceAccessControlInterface.canEditWorkspace(
      args.permissionContext,
      args.component.rootWorkspace,
      // TODO - RAFAA: For now, we ignore it, so the PR doesn't get too big
      null
    ),
    canReadWorkspace: workspaceAccessControlInterface.canAccessWorkspace(
      args.permissionContext,
      args.component.rootWorkspace
    ),
  });
};
/**
 * Checks if the user can edit a field in a component.
 */
const canEditField = (args: ComponentCanDoArgs): boolean => {
  const access = compileFieldAccess(args);
  return access.hasEditAccess;
};

/**
 * Checks if the user can Read a field in a component. includes checking if the user is an org writer and if the workspace is editable.
 */
const canOnlyReadField = (args: ComponentCanDoArgs): boolean => {
  const access = compileFieldAccess(args);
  return access.hasOnlyReadAccess;
};

/**
 * Checks if the user has no access to a field in a component.
 */
const hasNoAccessToField = (args: ComponentCanDoArgs): boolean => {
  const access = compileFieldAccess(args);
  return !access.hasEditAccess && !access.hasOnlyReadAccess;
};

/**
 * Checks if the user has no access to a field in a component in all kinds of modes.
 */
const hasNoAccessToFieldInAnyModes = (
  args: ComponentCanDoInModesArgs
): boolean => {
  const { isScenarioMode, isPresentationMode } = args;
  if (isScenarioMode || isPresentationMode) {
    return false;
  }

  return hasNoAccessToField(args);
};

/* ---------------------------------------------------------------------------
   ---------------------------------------------------------------------------
   ----------------                                           ----------------
   ----------------           Components Access Checks        ----------------
   ----------------                                           ----------------
   ---------------------------------------------------------------------------
   ------------------------------------------------------------------------ */
/**
 * Checks if the user can access the component details
 */
const canAccessComponent = ({
  component,
  permissionContext,
  subdivisionsContext,
}: Pick<
  ComponentCanDoArgs,
  'component' | 'permissionContext' | 'subdivisionsContext'
>): boolean =>
  subdivisionsComponentsOperations.canAccessComponent({
    component,
    permissionContext,
    subdivisionsContext,
  }) ||
  workspaceAccessControlInterface.canAccessWorkspace(
    permissionContext,
    component.rootWorkspace
  );

/**
 * Checks if the user can edit a field in a component.
 */
const canEditComponent = ({
  component,
  permissionContext,
  subdivisionsContext,
}: Pick<
  ComponentCanDoArgs,
  'component' | 'permissionContext' | 'subdivisionsContext'
>): boolean =>
  workspaceAccessControlInterface.canEditWorkspace(
    permissionContext,
    component.rootWorkspace,
    // TODO - RAFAA: For now, we ignore it, so the PR doesn't get too big
    null
  ) ||
  subdivisionsComponentsOperations.canEditComponent({
    component,
    permissionContext,
    subdivisionsContext,
  });

export const componentAccessControlOperation = {
  /**
   * Checks if the user can read a component. includes checking if the user is an org reader and if the workspace is accessible.
   */
  canAccessComponent,
  /**
   * Checks if the user can edit a component. includes checking if the user is an org writer and if the workspace is editable.
   */
  canEditComponent,

  /**
   * Checks if the user can edit a field in a component. includes checking if the user is an org writer and if the workspace is editable.
   */
  canEditField,

  /**
   * Checks if the user can Read a field in a component. includes checking if the user is an org writer and if the workspace is editable.
   */
  canOnlyReadField,

  /**
   * Checks if the user has no access to a field in a component.
   */
  hasNoAccessToField,

  /**
   * Checks if the user has no access to a field in a component in all kinds of modes.
   */
  hasNoAccessToFieldInAnyModes,
};
