import { actionCreator } from '@ardoq/rxbeach';
import { FavoritePayload } from '@ardoq/api';
import { AppModules } from '../../appContainer/types';
import { WidgetState } from '../../homePage/viewpointsWidget/types';

export const toggleFavorite = actionCreator<FavoritePayload>(
  '[currentUser] TOGGLE_FAVORITE'
);

export const toggleModelPreview = actionCreator(
  '[currentUser] TOGGLE_MODEL_PREVIEW'
);

export const updateCurrentUser = actionCreator(
  '[currentUser] UPDATE_CURRENT_USER'
);

type ChangeAssetsBrowserPerPagePayload = {
  appModule: AppModules;
  perPage: number;
};

export const updateAssetsBrowserPerPageSettings =
  actionCreator<ChangeAssetsBrowserPerPagePayload>(
    '[currentUser] UPDATE_ASSETS_BROWSER_PER_PAGE_SETTINGS'
  );

export const updateHomepageViewpointsState = actionCreator<
  Partial<WidgetState>
>('[currentUser] UPDATE_HOMEPAGE_VIEWPOINTS_STATE');
