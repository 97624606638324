import { reducer } from '@ardoq/rxbeach';
import {
  resetSurveyWorkspaceAccessRequestState,
  setSurveyWorkspaceAccessRequestLoading,
  setSurveyWorkspaceAccessRequestSent,
} from './actions';
import { EntityBrowserAtomsStreamState } from './types';

const handleResetSurveyWorkspaceAccessRequestState =
  (): EntityBrowserAtomsStreamState => ({
    surveyWorkspaceAccessRequestLoading: false,
    surveyWorkspaceAccessRequestSent: false,
  });

const handleSetSurveyWorkspaceAccessRequestLoading = (
  state: EntityBrowserAtomsStreamState,
  loading: boolean
): EntityBrowserAtomsStreamState => ({
  ...state,
  surveyWorkspaceAccessRequestLoading: loading,
});

const handleSetSurveyWorkspaceAccessRequestSent = (
  state: EntityBrowserAtomsStreamState,
  sent: boolean
): EntityBrowserAtomsStreamState => ({
  ...state,
  surveyWorkspaceAccessRequestSent: sent,
});

export const reducers = [
  reducer(
    resetSurveyWorkspaceAccessRequestState,
    handleResetSurveyWorkspaceAccessRequestState
  ),
  reducer(
    setSurveyWorkspaceAccessRequestLoading,
    handleSetSurveyWorkspaceAccessRequestLoading
  ),
  reducer(
    setSurveyWorkspaceAccessRequestSent,
    handleSetSurveyWorkspaceAccessRequestSent
  ),
];
