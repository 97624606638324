import { dispatchAction } from '@ardoq/rxbeach';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
  notifyComponentsSynced,
  notifyComponentsUpdated,
} from './ComponentActions';
import { ComponentBackboneModel } from 'aqTypes';
import { Components } from 'collections/components';
import { resetGraph } from 'modelInterface/graphModelActions';

const subscribeToComponentsReset = (components: Components) =>
  components.on('reset', () => dispatchAction(resetGraph()));

const notifyWhenSynced = (component: ComponentBackboneModel) =>
  component.once('sync', () => {
    dispatchAction(notifyComponentsSynced({ componentIds: [component.id] }));
  });
export default (Components: { collection: Components }) => {
  const components = Components.collection;
  components.on('remove', (component: ComponentBackboneModel) => {
    dispatchAction(notifyComponentsRemoved({ componentIds: [component.id] }));
  });
  components.on(
    'change',
    (
      component: ComponentBackboneModel,
      options: { ignoreInBackboneDispatcher?: boolean } = {}
    ) => {
      if (!options.ignoreInBackboneDispatcher) {
        dispatchAction(
          notifyComponentsUpdated({ componentIds: [component.id] })
        );
        notifyWhenSynced(component);
      }
    }
  );

  components.on('add', (component: ComponentBackboneModel) => {
    dispatchAction(notifyComponentsAdded({ componentIds: [component.id] }));
    notifyWhenSynced(component);
  });

  subscribeToComponentsReset(components);
};
