import { FC, memo } from 'react';
import { TrTag } from './atoms';
import { Row } from 'components/EntityBrowser/types';
import {
  extractAssetsIdsList,
  findIsSelected,
} from 'components/EntityBrowser/utils';
import { Selected } from 'aqTypes';
import { get } from 'lodash';
import { AssetRow } from 'components/AssetsBrowser/types';
import { RowType } from '@ardoq/table';

const findDifferentPropByPaths = (a: any, b: any, pathsToCompare: string[]) =>
  pathsToCompare.find(path => get(a, path) !== get(b, path));

const isAncestorOfId = (row: Row<AssetRow>, parentId?: string) => {
  const path = get(row, 'meta.path');
  if (!parentId) return false;
  if (parentId === path) return false;
  return path?.includes(parentId);
};

export const isChildOfId = (row: Row<AssetRow>, parentId?: string) => {
  const path = get(row, 'meta.path');
  const segments = (path || '').split('.');
  const rowsParentId = segments[segments.length - 2];
  if (!parentId) return false;
  if (parentId === path) return false;
  if (parentId === rowsParentId) return true;
  return false;
};

export const calculateProps = (dataSourceRow: any, dragDropProps: any) => {
  const {
    isDragging,
    selected,
    draggedIds,
    overFolderId,
    dragStartedOnFolderId,
    highlightStatusMap,
    previewId,
  } = dragDropProps;

  const isFolder = dataSourceRow.rowType === RowType.FOLDER;
  const rowFolderId = dataSourceRow.meta.folderId;
  const closestFolderId = isFolder ? dataSourceRow._id : rowFolderId;
  const directlyFolderIsDragged = draggedIds?.includes(dragStartedOnFolderId);
  const isSiblignFolder =
    dragStartedOnFolderId === rowFolderId && !draggedIds?.includes(rowFolderId);

  // pretty  nightmare flag, at lest is well tested
  const isDragged =
    (isDragging &&
      (draggedIds?.includes(dataSourceRow._id) ||
        (isChildOfId(dataSourceRow, dragStartedOnFolderId) &&
          !isSiblignFolder))) ||
    (directlyFolderIsDragged &&
      isAncestorOfId(dataSourceRow, dragStartedOnFolderId));

  const canBeDropped =
    !isDragged &&
    overFolderId === closestFolderId &&
    overFolderId !== dragStartedOnFolderId;

  const isOver =
    isDragging &&
    canBeDropped &&
    closestFolderId !== null &&
    overFolderId === closestFolderId;

  const highlight = highlightStatusMap[dataSourceRow._id];

  const isSelected = findIsSelected(dataSourceRow, selected);

  const isPreview = previewId && previewId === dataSourceRow._id;

  return {
    closestFolderId,
    isDragged,
    isOver,
    highlight,
    isSelected,
    isPreview,
  };
};

const BodyTr: FC<any> = memo(
  ({ children, dataSourceRow, dragDropProps }) => {
    const { permissions = {} } = dataSourceRow.meta || {};
    const isDraggable = permissions.canUpdate;

    const { closestFolderId, isDragged, isOver, isSelected, isPreview } =
      calculateProps(dataSourceRow, dragDropProps);

    return (
      <TrTag
        data-row-id={dataSourceRow._id}
        data-row-type={dataSourceRow.rowType}
        data-closest-folder-id={closestFolderId}
        data-is-dragged={isDragged}
        data-is-draggable={isDraggable}
        $isDisabled={isDragged}
        $isOver={isOver}
        $isSelected={isSelected !== Selected.NOT_SELECTED}
        $isPreview={isPreview}
      >
        {children}
      </TrTag>
    );
  },
  (props, nextProps) => {
    const differentProp = findDifferentPropByPaths(props, nextProps, [
      'dataSourceRow._id',
      'dataSourceRow.name',
      'dataSourceRow.children.length',
      'dataSourceRow.meta.favorite',
      'dataSourceRow.meta.level',
      // Properties used by BroadvastOverview
      'dataSourceRow.status',
      'dataSourceRow.meta.intervalInDays',
      'dataSourceRow.meta.latestSchedule',
      'dataSourceRow.content.contentType',
      'dataSourceRow.previousRuntime',
      'dataSourceRow.lastUpdated',
      // Drag and Drop
      'dragDropProps.isDragging',
      'dragDropProps.expandedFoldersIds.length',
      'dragDropProps.selected.length',
      'dragDropProps.searchPhrase',
      'dragDropProps.renameId',
      'dragDropProps.previewId',
    ]);

    // if there is any prop of difference it should immidiately update
    // no further calculations are needed
    if (differentProp) return false;

    const calculatedProps = calculateProps(
      props.dataSourceRow,
      props.dragDropProps
    );
    const calculatedNextProps = calculateProps(
      nextProps.dataSourceRow,
      nextProps.dragDropProps
    );

    const differentCalculatedProp = findDifferentPropByPaths(
      calculatedProps,
      calculatedNextProps,
      ['closestFolderId', 'isDragged', 'isOver', 'highlight']
    );

    if (differentCalculatedProp) return false;

    if (
      extractAssetsIdsList([props.dataSourceRow]).length !==
      extractAssetsIdsList([nextProps.dataSourceRow]).length
    ) {
      return false;
    }

    const shouldSkipUpdate = !differentProp;
    return shouldSkipUpdate;
  }
);

export default BodyTr;
