import {
  collectRoutines,
  dispatchAction,
  routine,
  ofType,
} from '@ardoq/rxbeach';
import {
  notifyFiltersChanged,
  triggerFiltersChangedEvent,
} from './FilterActions';
import { tap } from 'rxjs';
import Filters from 'collections/filters';
import Perspectives from 'collections/perspectives';

const notifyFiltersChangeOnTrigger = routine(
  ofType(triggerFiltersChangedEvent),
  tap(() =>
    dispatchAction(
      notifyFiltersChanged({
        filters: Filters.getFiltersAsQueryBuilderQueries(),
        queryString: Perspectives.encodeURI(),
      })
    )
  )
);

export const filterRoutines = collectRoutines(notifyFiltersChangeOnTrigger);
