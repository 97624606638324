import { ViewIds } from '@ardoq/api-types';
import { ImageSrc } from '@ardoq/data-model';
import { MaximumPathLengths } from 'aqTypes';

export const MAIN_PANE_LEFT = 'MAIN_PANE_LEFT';
export const MAIN_PANE_RIGHT = 'MAIN_PANE_RIGHT';
export type PaneLocation = typeof MAIN_PANE_LEFT | typeof MAIN_PANE_RIGHT;

export interface ViewInterface {
  id: ViewIds;
  name?: string;
  deselected: () => void;
  remove: () => void;
  localResize?: () => void;
  debouncedRender: () => void;
  isVisible: () => boolean;
  getViewstate: () => any;
  getPathLengths: () => MaximumPathLengths;
  autoZoomToFit?: (args?: { isTriggeredByKeyboard: boolean }) => void;
}

export interface PaneInterface {
  getActiveView(): ViewInterface | null;
  getViewById(viewId: string): ViewInterface | null;
}

export interface ViewMapEntry {
  view: ViewInterface | null;
  reactView: React.ReactNode | null;
}
export enum MetaInfoViewStatus {
  NONE,
  NEW,
  BETA,
  SOON_TO_BE_DISCONTINUED,
  DISCONTINUED,
}
export interface MetaInfo {
  id: ViewIds;
  viewClass?: any;
  ReactComponent?: React.ComponentClass | null;
  name?: string;
  description?: string;
  exampleImage?: ImageSrc;
  loadModule?: () => any;
  features?: {
    hasLegend: boolean;
  };
  isHiddenInViewSelect?: boolean;
  viewStatus?: MetaInfoViewStatus;
  killDate?: Date;
}
