import { pluralize } from '@ardoq/common-helpers';
import SuppressExternallyManagedNotice from 'externallyManaged/SuppressExternallyManagedNotice';

type DialogContentProps = { numOfComponents: number; numOfReferences: number };

const getMessage = ({
  type,
  count,
}: {
  type: 'component' | 'reference';
  count: number;
}) => {
  return (
    <>
      <p>
        {count === 1
          ? `This ${type} is`
          : `These ${pluralize(type, count)} are`}{' '}
        loaded from an external source. Your deletion may be overwritten and the{' '}
        {pluralize(type, count)} recreated on the next run of the load process.
      </p>
      <SuppressExternallyManagedNotice />
    </>
  );
};

const DialogContent = ({
  numOfComponents,
  numOfReferences,
}: DialogContentProps) => {
  if (numOfComponents > 0) {
    return getMessage({ type: 'component', count: numOfComponents });
  }

  if (numOfReferences > 0) {
    return getMessage({ type: 'reference', count: numOfReferences });
  }

  return null;
};

export default DialogContent;
