import {
  APIReferenceAttributes,
  APIWorkspaceAttributes,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

/** Triggered when presentation is clearing workspaces with references */
export const clearSimpleGraphModel = actionCreator(
  '[models] CLEAR_SIMPLE_GRAPH_MODEL'
);

export type UpdateLinkedWorkspacesPayload = {
  references: APIReferenceAttributes[];
  workspaces?: APIWorkspaceAttributes[];
};
export const updateLinkedWorkspaces =
  actionCreator<UpdateLinkedWorkspacesPayload>(
    '[models] UPDATE_LINKED_WORKSPACES'
  );
