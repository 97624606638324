import Models from 'collections/models';

import {
  APIComponentType,
  APIModelAttributes,
  ArdoqId,
  ComponentTypeHierarchy,
  APIReferenceType,
} from '@ardoq/api-types';
import { AjaxErrorParams, ModelSaveOptions } from 'backbone';
import workspaces from 'collections/workspaces';
import { toArdoqError } from '@ardoq/common-helpers';
import { Model } from 'aqTypes';
import { toApiResponse } from '@ardoq/api';

const HAS_NO_USAGE_VALUE = 0;

const get = (id: ArdoqId): APIModelAttributes | null => {
  const model = Models.collection.get(id);

  return model ? structuredClone(model.attributes) : null;
};

const removeReferenceType = (modelId: ArdoqId, referenceTypeId: number) => {
  return Models.collection.get(modelId)?.removeReferenceType(referenceTypeId);
};

const getReferenceType = (modelId: ArdoqId, referenceTypeId: number) => {
  return Models.collection.get(modelId)?.getReferenceTypeById(referenceTypeId);
};

const saveModelData = async (
  workspaceId: ArdoqId,
  attributes: Partial<APIModelAttributes>,
  options?: ModelSaveOptions
) => {
  const workspace = workspaces.collection.get(workspaceId);
  const model = workspace?.getModel();
  if (!model) {
    return toArdoqError({
      error: new Error(`cannot find model for workspace: ${workspaceId}`),
    });
  }
  try {
    // Need to be spread because backbone mutates the object instead of copying it
    return { ...(await model.save(attributes, options)).attributes };
  } catch (error: unknown) {
    const ajaxError = error as AjaxErrorParams;
    return toApiResponse(
      new Response(ajaxError.responseText, {
        status: ajaxError.status,
        statusText: ajaxError.statusText,
      })
    );
  }
};

const updateModel = ({ _id, ...attributes }: APIModelAttributes) => {
  const model = Models.collection.get(_id);

  if (!model) {
    return;
  }

  model.set(attributes);
};

/**
 * Checks if the target node is used or has a sub node that is used
 * @param tree Root of the Tree
 * @param usageDict List of Nodes in the Tree and how many times they were used.
 * @param typeId the target node
 * @returns boolean
 */
const isModelTypeUsed = (
  typeId: string,
  tree: ComponentTypeHierarchy,
  usageDict: Record<string, number>
): boolean => {
  const isUsed = usageDict[typeId] !== HAS_NO_USAGE_VALUE;
  if (isUsed) {
    return true;
  }
  return [...getModelTypeDescendantTypeIds(typeId, tree)].some(
    childTypeId => usageDict[childTypeId] !== 0
  );
};

const flatModelTypeHierarchy = (
  tree: ComponentTypeHierarchy
): APIComponentType[] =>
  Object.values(tree).flatMap(modelType => [
    { ...modelType },
    ...flatModelTypeHierarchy(modelType.children),
  ]);

const getModelTypeDescendantTypeIds = (
  typeId: ArdoqId,
  tree: ComponentTypeHierarchy
) => {
  return getModelTypeDescendantTypes(typeId, tree).map(types => types.id);
};

const findModelTypeById = (
  tree: ComponentTypeHierarchy,
  typeId: string
): APIComponentType | undefined => {
  if (tree[typeId]) {
    return tree[typeId];
  }
  for (const child of Object.values(tree)) {
    const result = findModelTypeById(child.children, typeId);
    if (result) {
      return result;
    }
  }
  return undefined;
};

const getModelTypeDescendantTypes = (
  typeId: ArdoqId,
  tree: ComponentTypeHierarchy
): APIComponentType[] => {
  const modelType = findModelTypeById(tree, typeId);
  if (!modelType) {
    return [];
  }
  return flatModelTypeHierarchy(modelType.children);
};

const getModelCount = () => {
  return Models.collection.length;
};

type GetComponentAndReferenceTypeIds = (modelId: string) => {
  componentTypeIds: string[];
  referenceTypeIds: string[];
};

const getComponentAndReferenceTypeIds: GetComponentAndReferenceTypeIds =
  modelId => {
    const model = Models.collection.get(modelId);
    if (!model) {
      return {
        componentTypeIds: [],
        referenceTypeIds: [],
      };
    }

    const componentTypeIds = Object.keys(model.getAllTypes());

    // referenceTypeIds are sometimes number and sometimes string
    // this function normalizes them into strings
    const referenceTypeIds = Object.values(model.getReferenceTypes()).map(
      ({ id }) => id.toString()
    );

    return { componentTypeIds, referenceTypeIds };
  };

const getComponentTypes = (id: ArdoqId): APIComponentType[] => {
  const model = Models.collection.get(id);

  return model ? Object.values(model.getAllTypes()) : [];
};

const getReferenceTypes = (id: ArdoqId): APIReferenceType[] => {
  const model = Models.collection.get(id);
  return model ? Object.values(model.getReferenceTypes()) : [];
};

const getTypeHierarchy = (model: Model): ComponentTypeHierarchy =>
  model.get('root');

export const modelInterface = {
  get,
  HAS_NO_USAGE_VALUE,
  removeReferenceType,
  getReferenceType,
  getComponentTypes,
  getReferenceTypes,
  saveModelData,
  updateModel,
  isModelTypeUsed,
  flatModelTypeHierarchy,
  getModelTypeDescendantTypeIds,
  findModelTypeById,
  getModelTypeDescendantTypes,
  getModelCount,
  getComponentAndReferenceTypeIds,
  getTypeHierarchy,
};
