import { createElement } from 'react';
import DialogContent from './DialogContent';
import { getTitle } from 'components/Dialogs/confirmDeletion/helpers';

export const getManagedDialogConfig = ({
  numOfComponents,
  numOfReferences,
}: {
  numOfComponents: number;
  numOfReferences: number;
}) => {
  const title = `${getTitle({
    numOfComponents,
    numOfReferences,
  })} from external sources`;
  const message = createElement(DialogContent, {
    numOfComponents,
    numOfReferences,
  });
  return {
    title,
    text: message,
  };
};
