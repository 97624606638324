import { dispatchAction } from '@ardoq/rxbeach';
import Filters from 'collections/filters';
import GroupByCollection from 'collections/groupByCollection';
import { replaceAllDynamicFilters } from 'filters/dynamicFilters';
import { notifyPerspectiveCleared } from 'perspective/actions';
import {
  setActivePerspective,
  triggerFiltersChangedEvent,
} from 'streams/filters/FilterActions';

type ClearPerspectivesArgs = {
  shouldTriggerChangeEvent: boolean;
};
const clearPerspectives = ({
  shouldTriggerChangeEvent,
}: ClearPerspectivesArgs) => {
  replaceAllDynamicFilters([]);
  Filters.removeAllFilters({ shouldTriggerChangeEvent: false });
  GroupByCollection.removeAll();
  dispatchAction(notifyPerspectiveCleared());
  dispatchAction(setActivePerspective({ id: null, queryString: '' }));
  if (shouldTriggerChangeEvent) {
    dispatchAction(triggerFiltersChangedEvent());
  }
};

export default clearPerspectives;
