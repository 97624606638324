import {
  PermissionContext,
  canDo,
  permissionsOperations,
} from '@ardoq/access-control';
import { ArdoqId } from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';
import { ScenarioModeState } from 'scope/types';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';
import { scenarioAccessControlInterface } from './scenario';

/**
 * Check if the current user has the correct access to edit a workspace.
 *
 * The decision tree looks like this:
 *  - If we are in scenario mode, we should use the scenario's permissions logic
 *     - If we are in scenario diff mode, we should not be able to edit the workspace
 *     - Otherwise, we should check if the user has the correct permissions to edit the scenario.
 * - Otherwise, we should check if the user has the correct permissions to edit the workspace.
 * @returns {boolean}
 */
const canEditWorkspace = (
  permissionContext: PermissionContext,
  workspaceId: ArdoqId,
  activeScenarioState: ScenarioModeState | null
): boolean => {
  if (
    activeScenarioState &&
    activeScenarioOperations.isInScenarioMode(activeScenarioState)
  ) {
    return scenarioAccessControlInterface.canEditActiveScenario(
      permissionContext,
      activeScenarioState
    );
  }
  return (
    !!workspaceId &&
    canDo({
      permissionContext,
      resourceId: workspaceId,
      action: 'workspace/edit',
    })
  );
};

/**
 * Check if the current user has the correct access to
 * access a workspace (checks for permissions).
 * @returns {boolean}
 */
const canAccessWorkspace = (
  permissionContext: PermissionContext,
  workspaceId: ArdoqId
): boolean =>
  canDo({
    permissionContext,
    resourceId: workspaceId,
    action: 'workspace/read',
  });

/**
 * Check if the current user has the correct access to admin a workspace.
 *
 * The decision tree looks like this:
 * - If we are in scenario mode, we should not be able to admin the workspace
 * - Otherwise, we should check if the user has the correct permissions to admin the workspace.
 * @returns {boolean}
 */
const canAdminWorkspace = (
  permissionContext: PermissionContext,
  workspaceId: ArdoqId,
  activeScenarioState: ScenarioModeState | null
): boolean => {
  // If we are in scenario mode, we should not be able to admin the workspace
  if (
    activeScenarioState &&
    activeScenarioOperations.isInScenarioMode(activeScenarioState)
  ) {
    return false;
  }

  return canDo({
    permissionContext,
    resourceId: workspaceId,
    action: 'workspace/admin',
  });
};

const canCreateWorkspace = (permissionContext: PermissionContext): boolean => {
  if (!permissionsOperations.isLoadedPermissionContext(permissionContext)) {
    return false;
  }
  if (hasFeature(Features.QUICK_START)) {
    return false;
  }
  return canDo({
    action: 'organization/create_workspace',
    permissionContext,
    resourceId: permissionsOperations.getOrgIdFromContext(permissionContext),
  });
};

const canCopyWorkspace = (
  permissionContext: PermissionContext,
  workspaceId: ArdoqId
): boolean =>
  canAccessWorkspace(permissionContext, workspaceId) &&
  canCreateWorkspace(permissionContext);

const canAddFieldsToWorkspace = (
  permissionContext: PermissionContext,
  workspaceId: ArdoqId,
  activeScenarioState: ScenarioModeState | null
): boolean => {
  if (
    activeScenarioState &&
    activeScenarioOperations.isInScenarioMode(activeScenarioState)
  ) {
    // We should not be able to add fields to a workspace in scenario mode
    return false;
  }

  return canDo({
    action: 'workspace/add_field',
    permissionContext,
    resourceId: workspaceId,
  });
};

const canEditWorkspaceFields = (
  permissionContext: PermissionContext,
  workspaceId: ArdoqId,
  activeScenarioState: ScenarioModeState | null
): boolean => {
  if (
    activeScenarioState &&
    activeScenarioOperations.isInScenarioMode(activeScenarioState)
  ) {
    // We should not be able to edit fields in a workspace in scenario mode
    return false;
  }

  return canDo({
    action: 'workspace/edit_fields',
    permissionContext,
    resourceId: workspaceId,
  });
};

const canEditWorkspaceReferenceTypes = (
  permissionContext: PermissionContext,
  workspaceId: ArdoqId,
  activeScenarioState: ScenarioModeState | null
): boolean => {
  if (
    activeScenarioState &&
    activeScenarioOperations.isInScenarioMode(activeScenarioState)
  ) {
    // We should not be able to edit reference types in a workspace in scenario mode
    return false;
  }

  return canDo({
    action: 'workspace/edit_reference_types',
    permissionContext,
    resourceId: workspaceId,
  });
};

/**
 * Not used.
 */
const canEditWorkspaceAttachments = (
  permissionContext: PermissionContext,
  workspaceId: ArdoqId,
  activeScenarioState: ScenarioModeState | null
): boolean => {
  if (
    activeScenarioState &&
    activeScenarioOperations.isInScenarioMode(activeScenarioState)
  ) {
    // We should not be able to edit attachments in a workspace in scenario mode
    return false;
  }

  if (!permissionsOperations.isLoadedPermissionContext(permissionContext)) {
    return false;
  }

  return canDo({
    action: 'workspace/edit_attachments',
    permissionContext,
    resourceId: workspaceId,
  });
};

export const workspaceAccessControlInterface = {
  /**
   * Check if the current user has the correct access to edit a workspace.
   *
   * The decision tree looks like this:
   *  - If we are in scenario mode, we should use the scenario's permissions logic
   *     - If we are in scenario diff mode, we should not be able to edit the workspace
   *     - Otherwise, we should check if the user has the correct permissions to edit the scenario.
   * - Otherwise, we should check if the user has the correct permissions to edit the workspace.
   * @returns {boolean}
   */
  canEditWorkspace,
  /**
   * Check if the current user has the correct access to
   * access a workspace (checks for permissions).
   * @returns {boolean}
   */
  canAccessWorkspace,
  /**
   * Check if the current user has the correct access to admin a workspace.
   *
   * The decision tree looks like this:
   * - If we are in scenario mode, we should not be able to admin the workspace
   * - Otherwise, we should check if the user has the correct permissions to admin the workspace.
   * @returns {boolean}
   */
  canAdminWorkspace,
  canCreateWorkspace,
  canCopyWorkspace,
  canAddFieldsToWorkspace,
  canEditWorkspaceFields,
  canEditWorkspaceReferenceTypes,
  canEditWorkspaceAttachments,
};
