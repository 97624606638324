import { isEqual, omit } from 'lodash';
import { RegisteredReducer, reducer } from '@ardoq/rxbeach';
import { BridgeClosedPayload, bridgeClosed } from '@ardoq/post-message-bridge';
import {
  TogglePresentationEditorPayload,
  requestShowAppModule,
  showRightPane,
  togglePresentationEditor,
} from 'appContainer/actions';
import { AppModules, PayloadShowAppModule } from 'appContainer/types';
import * as gridEditorActions from './actions';
import {
  DockedGridEditorFocus,
  DockedGridEditorState,
  DockedGridEditorVisibility,
  DropdownOpenerSource,
  GridEditorFrame,
  GridEditorSharedState,
  GridEditorState,
  PopoutGridEditorState,
} from './types';
import { gridEditorStateOperations } from './gridEditorStateOperations';
import { ArdoqId } from '@ardoq/api-types';
import { isDockedStateInit, isPopoutStateInit } from './reducerHelpers';
import { closeGridEditorFieldWorkflow } from '../appModelStateEdit/propertiesEditor/addField/gridEditor/actions';
import { beginGridEditorAddFieldWorkflow } from '../appModelStateEdit/actions';
import {
  InitGridEditorPayload,
  PayloadDropdownOpenerSource,
  PayloadSetDisabledWorkspaces,
  PayloadWorkspaceId,
} from './actions';
import { GRID_EDITOR_POST_MESSAGE_BRIDGE_ID } from './consts';

const {
  activateDropdownState,
  deactivateDropdownState,
  gridEditorTableLoaded,
  gridSetDisabledWorkspaces,
  gridTargetWorkspaceDisable,
  gridTargetWorkspaceEnable,
  hideGridEditor,
  initGridEditorState,
  notifyPopoutGridEditorOpened,
  setDockedGridEditorFocus,
  setDockedGridEditorStyle,
  showGridEditor,
  toggleFullscreen,
  requestPopoutGridEditor,
  notifyOpenPopoutGridEditorFailed,
  requestClosePopoutGridEditor,
} = gridEditorActions;

/**
 * Update state showing initial load has completed.
 * Idempotent because it once caused an infinite loop due to changing the object
 * reference of the state.
 */

const handleGridEditorTableLoaded = (
  state: GridEditorState
): GridEditorState => {
  if (state.isGridEditorTableLoaded) return state;
  return {
    ...state,
    isGridEditorTableLoaded: true,
  };
};

const openDockedGridEditor = (state: GridEditorState): GridEditorState => {
  // Opening the popout editor is handled in a routine
  if (
    state.frameId === GridEditorFrame.POPOUT ||
    state.activeFrameId === GridEditorFrame.POPOUT
  ) {
    return state;
  }

  return {
    ...state,
    dockedGridEditorVisibility: DockedGridEditorVisibility.EXPANDED,
  };
};

const closeDockedGridEditor = (state: GridEditorState): GridEditorState => {
  if (state.activeFrameId === GridEditorFrame.POPOUT) return state;

  return {
    ...state,
    dockedGridEditorVisibility: DockedGridEditorVisibility.CLOSED,
    dockedGridEditorFocus: DockedGridEditorFocus.BACKGROUND,
  };
};

const closeDockedGridEditorIfPresentationEditorOpens = (
  state: GridEditorState,
  { shouldShow }: TogglePresentationEditorPayload
) => {
  if (!shouldShow) return state;
  return closeDockedGridEditor(state);
};

const closeDockedGridEditorIfNavigatingAwayFromWorkspaces = (
  state: GridEditorState,
  payload: PayloadShowAppModule
) => {
  // Popout GridEditor remains open
  if (gridEditorStateOperations.isPopoutGridEditorOpen(state)) {
    return state;
  }
  if (payload.selectedModule === AppModules.WORKSPACES) {
    return state;
  }
  return closeDockedGridEditor(state);
};

const handleSetDockedGridEditorFocus = (
  state: GridEditorState,
  focusPayload: DockedGridEditorState['dockedGridEditorFocus']
): GridEditorState => {
  if (state.activeFrameId === GridEditorFrame.POPOUT) return state;

  // Focus state only makes sense when the Grid Editor is in EXPANDED state.
  // FULLSCREEN implies foreground, and CLOSED implies background
  if (
    state.dockedGridEditorVisibility !== DockedGridEditorVisibility.EXPANDED
  ) {
    return state;
  }

  return {
    ...state,
    dockedGridEditorFocus: focusPayload,
  };
};

const setPopoutAsActiveFrame = (state: GridEditorState): GridEditorState => {
  if (state.frameId === GridEditorFrame.DOCKED)
    return closeDockedGridEditor(state);

  // Only ardoq-front manages visibility of popout Grid Editor
  if (state.frameId !== GridEditorFrame.ARDOQ_FRONT_BRIDGE) return state;

  if (gridEditorStateOperations.isDockedGridEditorState(state)) {
    const newState: GridEditorSharedState = omit(state, [
      'dockedGridEditorVisibility',
      'dockedGridEditorFocus',
      'dockedEditorPaneStyle',
    ]);

    return {
      ...newState,
      frameId: state.frameId, // TS gets confused without this
      activeFrameId: GridEditorFrame.POPOUT,
    };
  }

  return {
    ...state,
    activeFrameId: GridEditorFrame.POPOUT,
  };
};

const closePopoutEditor = (state: GridEditorState): GridEditorState => {
  // Only ardoq-front manages visibility of popout Grid Editor
  if (state.frameId !== GridEditorFrame.ARDOQ_FRONT_BRIDGE) return state;
  if (state.activeFrameId !== GridEditorFrame.POPOUT) return state;

  return {
    ...state,
    frameId: state.frameId, // TS gets confused without this
    activeFrameId: GridEditorFrame.DOCKED,
    dockedGridEditorVisibility: DockedGridEditorVisibility.CLOSED,
    dockedGridEditorFocus: DockedGridEditorFocus.BACKGROUND,
    dockedEditorPaneStyle: gridEditorStateOperations.getFullscreenEditorStyle(),
  };
};

const closePopoutEditorOnPopoutBridgeClosed = (
  state: GridEditorState,
  { id, documentType }: BridgeClosedPayload
): GridEditorState => {
  if (!gridEditorStateOperations.isArdoqFrontRuntime(state)) return state;
  if (!gridEditorStateOperations.isPopoutGridEditorOpen(state)) return state;

  // ensure that it was the Popout GridEditor that was closed
  if (id === GRID_EDITOR_POST_MESSAGE_BRIDGE_ID && documentType === 'popout') {
    return closePopoutEditor(state);
  }

  return state;
};

/**
 * @deprecated Prefer individual action/reducer pairs to limit spread of "internal logic"
 */

const handleActivateDropdown = (
  state: GridEditorState,
  { src }: PayloadDropdownOpenerSource
): GridEditorState => {
  return {
    ...state,
    currentDropdownOpenerSource: src,
  };
};

const handleActivateSelectFieldDropdown = (
  state: GridEditorState
): GridEditorState => {
  return {
    ...state,
    currentDropdownOpenerSource:
      DropdownOpenerSource.SRC_EDITOR_TOOLBAR_SELECT_FIELD,
  };
};

const handleDeactivateDropdown = (state: GridEditorState): GridEditorState => {
  return {
    ...state,
    currentDropdownOpenerSource: null,
  };
};

const handleEditorPaneStyles = (
  state: GridEditorState,
  payload: DockedGridEditorState['dockedEditorPaneStyle']
): GridEditorState => {
  if (gridEditorStateOperations.isPopoutGridEditorState(state)) return state;

  if (isEqual(state.dockedEditorPaneStyle, payload)) {
    return state;
  }

  return {
    ...state,
    dockedEditorPaneStyle: payload,
  };
};

const handleToggleFullscreen = (state: GridEditorState): GridEditorState => {
  if (gridEditorStateOperations.isPopoutGridEditorState(state)) {
    return state;
  }

  const isFullscreen =
    state.dockedGridEditorVisibility === DockedGridEditorVisibility.FULLSCREEN;

  if (isFullscreen) {
    const expandedState = {
      ...state,
      dockedGridEditorFocus: DockedGridEditorFocus.BACKGROUND,
      dockedGridEditorVisibility: DockedGridEditorVisibility.EXPANDED,
    };
    return handleActivateDropdown(expandedState, {
      src: DropdownOpenerSource.SRC_TOGGLE_FULLSCREEN,
    });
  }

  const fullscreenState = {
    ...state,
    dockedGridEditorFocus: DockedGridEditorFocus.FOREGROUND,
    dockedGridEditorVisibility: DockedGridEditorVisibility.FULLSCREEN,
  };

  return handleDeactivateDropdown(fullscreenState);
};

const handleSetIsShowAllComponents = (
  state: GridEditorState
): GridEditorState => ({
  ...state,
  isShowAllComponents: !state.isShowAllComponents,
});

const handleInitGridEditorState = (
  _state: GridEditorState,
  _payload: InitGridEditorPayload
): GridEditorState => {
  const combinedForTs = { state: _state, payload: _payload };
  if (isDockedStateInit(combinedForTs)) {
    const { state, payload } = combinedForTs;

    const dockedState: DockedGridEditorState = {
      ...omit(state, ['activeFrameId']),
      activeFrameId: payload.activeFrameId,
      activeViewType: payload.activeViewType,
      dockedGridEditorVisibility: payload.dockedGridEditorVisibility,
      dockedEditorPaneStyle: payload.dockedEditorPaneStyle,
      dockedGridEditorFocus: DockedGridEditorFocus.BACKGROUND,
    };

    return dockedState;
  }

  if (isPopoutStateInit(combinedForTs)) {
    const { state, payload } = combinedForTs;
    if (state.activeFrameId === GridEditorFrame.DOCKED) {
      const popoutState: PopoutGridEditorState = {
        ...omit(state, [
          'dockedEditorPaneStyle',
          'dockedGridEditorFocus',
          'dockedGridEditorVisibility',
        ]),
        activeFrameId: payload.activeFrameId,
      };
      return popoutState;
    }
  }

  return _state;
};

const handleSetDisabledWorkspaces = (
  state: GridEditorState,
  { disabledWorkspaces }: PayloadSetDisabledWorkspaces
): GridEditorState => ({
  ...state,
  disabledWorkspaces,
});

const handleEnableTargetWorkspace = (
  state: GridEditorState,
  { workspaceId }: PayloadWorkspaceId
): GridEditorState => {
  const disabledWorkspaces = new Set<ArdoqId>(state.disabledWorkspaces);
  if (disabledWorkspaces.has(workspaceId)) {
    disabledWorkspaces.delete(workspaceId);
  }
  return {
    ...state,
    disabledWorkspaces,
  };
};

const handleDisableTargetWorkspace = (
  state: GridEditorState,
  { workspaceId }: PayloadWorkspaceId
): GridEditorState => {
  const disabledWorkspaces = new Set<ArdoqId>(state.disabledWorkspaces);
  disabledWorkspaces.add(workspaceId);
  return {
    ...state,
    disabledWorkspaces,
  };
};

const resetToDefaultStateOfCurrentFrameId = (state: GridEditorState) => {
  return gridEditorStateOperations.getDefaultGridEditorState(state.frameId);
};

const reducers: RegisteredReducer<GridEditorState, any>[] = [
  /**
   * Overwrite whole state, mainly used for unit tests.
   */
  reducer(
    gridEditorActions.gridEditorResetState,
    resetToDefaultStateOfCurrentFrameId
  ),

  /**
   * Opening/closing
   */
  reducer(showGridEditor, openDockedGridEditor),
  reducer(notifyPopoutGridEditorOpened, setPopoutAsActiveFrame),
  reducer(notifyOpenPopoutGridEditorFailed, closePopoutEditor),
  reducer(bridgeClosed, closePopoutEditorOnPopoutBridgeClosed),
  reducer(requestClosePopoutGridEditor, closePopoutEditor),
  reducer(hideGridEditor, closeDockedGridEditor),
  reducer(showRightPane, closeDockedGridEditor),
  reducer(requestPopoutGridEditor, closeDockedGridEditor),
  reducer(
    togglePresentationEditor,
    closeDockedGridEditorIfPresentationEditorOpens
  ),
  reducer(
    requestShowAppModule,
    closeDockedGridEditorIfNavigatingAwayFromWorkspaces
  ),

  reducer(gridEditorTableLoaded, handleGridEditorTableLoaded),
  reducer(setDockedGridEditorFocus, handleSetDockedGridEditorFocus),
  reducer(activateDropdownState, handleActivateDropdown),
  reducer(
    gridEditorActions.activateSelectFieldDropdown,
    handleActivateSelectFieldDropdown
  ),
  reducer(deactivateDropdownState, handleDeactivateDropdown),
  reducer(setDockedGridEditorStyle, handleEditorPaneStyles),
  reducer(toggleFullscreen, handleToggleFullscreen),
  reducer(
    gridEditorActions.setActiveViewTypeToComponent,
    gridEditorStateOperations.setActiveViewTypeToComponent
  ),
  reducer(
    gridEditorActions.setActiveViewTypeToReference,
    gridEditorStateOperations.setActiveViewTypeToReference
  ),
  reducer(initGridEditorState, handleInitGridEditorState),
  reducer(
    gridEditorActions.toggleShowAllComponents,
    handleSetIsShowAllComponents
  ),
  reducer(gridSetDisabledWorkspaces, handleSetDisabledWorkspaces),
  reducer(gridTargetWorkspaceEnable, handleEnableTargetWorkspace),
  reducer(gridTargetWorkspaceDisable, handleDisableTargetWorkspace),

  // disabledFields reducers
  reducer(
    gridEditorActions.gridEditorInitDisabledFields,
    gridEditorStateOperations.overwriteDisabledFields
  ),
  reducer(
    gridEditorActions.gridEnableAllFields,
    gridEditorStateOperations.enableAllFields
  ),
  reducer(
    gridEditorActions.gridFieldBatchUpdate,
    gridEditorStateOperations.batchUpdateFields
  ),
  reducer(
    gridEditorActions.requestColumnSort,
    gridEditorStateOperations.setColumnSort
  ),

  // fieldEditor reducers
  reducer(
    beginGridEditorAddFieldWorkflow,
    gridEditorStateOperations.fieldEditorOpen
  ),
  reducer(
    closeGridEditorFieldWorkflow,
    gridEditorStateOperations.fieldEditorClose
  ),
];

export default reducers;
