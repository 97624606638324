import { dispatchAction } from '@ardoq/rxbeach';
import {
  notifyWorkspaceModelChanged,
  notifyWorkspaceNameChanged,
} from './actions';
import { Workspace } from 'aqTypes';
import Backbone from 'backbone';

interface Args {
  collection: Backbone.Collection<Workspace>;
}
export default ({ collection: workspaces }: Args) => {
  workspaces.on('change', (workspace: Workspace) => {
    if (workspace.hasChanged('name')) {
      dispatchAction(notifyWorkspaceNameChanged());
    }
    dispatchAction(notifyWorkspaceModelChanged());
  });
};
