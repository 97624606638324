import type { PerspectiveBackboneModel } from 'aqTypes';
import preventConcurrency from 'utils/preventConcurrency';
import clearPerspectives from './clearPerspectives';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  setActivePerspective,
  triggerFiltersChangedEvent,
} from 'streams/filters/FilterActions';
import { replaceAllDynamicFilters } from 'filters/dynamicFilters';
import Filters from 'collections/filters';
import GroupByCollection from 'collections/groupByCollection';
import { GroupByAttributes } from 'models/types';
import { getModifiersAsQueryString } from 'perspectives/util';

/** not safe for concurrent async calls. */
const loadPerspectiveUnsafe = async (
  perspective?: PerspectiveBackboneModel
): Promise<void> => {
  clearPerspectives({ shouldTriggerChangeEvent: false });
  if (!perspective) {
    dispatchAction(triggerFiltersChangedEvent());
    return;
  }

  await replaceAllDynamicFilters(perspective.get('dynamicFilters') || []);
  Filters.loadFilters({
    ...perspective.get('filters'),
    shouldTriggerChangeEvent: false,
  });

  const groupBys: GroupByAttributes[] | null = perspective.get('groupBys');
  if (groupBys) {
    GroupByCollection.add(groupBys);
  }

  dispatchAction(
    setActivePerspective({
      id: perspective.id,
      queryString: getModifiersAsQueryString(),
    })
  );

  dispatchAction(triggerFiltersChangedEvent());
};
const loadPerspective = preventConcurrency<void, typeof loadPerspectiveUnsafe>(
  loadPerspectiveUnsafe
);
export default loadPerspective;
