import { Selected } from 'aqTypes';
import { Checkbox } from './atoms';
import { without } from 'lodash';

export const includesAny = (sourceSet: any[], subSet: any[]): boolean => {
  const rest = without(sourceSet, ...subSet);
  return rest.length < sourceSet.length;
};

export const includesAll = (sourceSet: any[], subSet: any[]): boolean => {
  if (subSet.length === 0) return false;
  const rest = without(subSet, ...sourceSet);
  return rest.length === 0;
};

const getSelectedState = (
  allIds: string[],
  selectedIds: string[]
): Selected => {
  if (includesAll(selectedIds, allIds)) return Selected.SELECTED;
  if (includesAny(selectedIds, allIds)) return Selected.MIXED;
  return Selected.NOT_SELECTED;
};

type SelectAllCheckboxProps = {
  selectableIds: string[];
  selected: string[];
  setSelected: (ids: string[]) => void;
};

const SelectAllCheckbox = ({
  selectableIds,
  selected,
  setSelected,
}: SelectAllCheckboxProps) => {
  const isSelected = getSelectedState(selectableIds, selected);

  return (
    <Checkbox
      isSelected={isSelected}
      onClick={() => {
        if (isSelected === Selected.NOT_SELECTED) {
          setSelected(selectableIds);
        } else {
          setSelected([]);
        }
      }}
    />
  );
};

export default SelectAllCheckbox;
