import { dispatchAction } from '@ardoq/rxbeach';
import {
  notifyFieldAdded,
  notifyFieldRemoved,
  notifyFieldUpdated,
} from './FieldActions';
import { FieldBackboneModel } from 'aqTypes';
import { Fields } from 'collections/fields';

export default (Fields: { collection: Fields }) => {
  Fields.collection.on('remove', (field: FieldBackboneModel) =>
    dispatchAction(notifyFieldRemoved({ field }))
  );

  Fields.collection.on('change', (field: FieldBackboneModel) => {
    dispatchAction(notifyFieldUpdated({ field }));
  });

  Fields.collection.on('add', (field: FieldBackboneModel) =>
    dispatchAction(notifyFieldAdded({ field }))
  );
};
