import { dispatchAction } from '@ardoq/rxbeach';
import {
  notifyReferencesAdded,
  notifyReferencesRemoved,
  notifyReferencesReset,
  notifyReferencesUpdated,
  notifyReferenceSourceOrTargetUpdated,
} from './ReferenceActions';

export default References => {
  References.collection.on('remove', reference => {
    dispatchAction(
      notifyReferencesRemoved({
        referenceIds: [reference.id],
      })
    );
  });

  References.collection.on('change', reference => {
    dispatchAction(notifyReferencesUpdated({ referenceIds: [reference.id] }));
  });

  References.collection.on('change:source change:target', reference => {
    dispatchAction(
      notifyReferenceSourceOrTargetUpdated({ referenceIds: [reference.id] })
    );
  });

  References.collection.on('merged', reference => {
    // Needed by the entityMerge functionality so that graphModel$ emits before modelUpdateNotification$
    dispatchAction(notifyReferencesUpdated({ referenceIds: [reference.id] }));
  });
  References.collection.on('add', reference => {
    dispatchAction(notifyReferencesAdded({ referenceIds: [reference.id] }));
  });

  References.collection.on('reset', dispatchAction(notifyReferencesReset()));
};
