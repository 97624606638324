import { actionCreator } from '@ardoq/rxbeach';
import { SurveyWorkspaceAccessRequestData } from '../types';

export const issueSurveyWorkspaceAccessRequest =
  actionCreator<SurveyWorkspaceAccessRequestData>(
    '[entityBrowserAtoms] ISSUE_SURVEY_WORKSPACE_ACCESS_REQUEST'
  );

export const resetSurveyWorkspaceAccessRequestState = actionCreator(
  '[entityBrowserAtoms] RESET_SURVEY_WORKSPACE_ACCESS_REQUEST_STATE'
);

export const setSurveyWorkspaceAccessRequestLoading = actionCreator<boolean>(
  '[entityBrowserAtoms] SET_SURVEY_WORKSPACE_ACCESS_REQUEST_LOADING'
);

export const setSurveyWorkspaceAccessRequestSent = actionCreator<boolean>(
  '[entityBrowserAtoms] SET_SURVEY_WORKSPACE_ACCESS_REQUEST_SENT'
);
