import {
  DragTableHeaderWrapper,
  TableTag,
} from 'appContainer/DashboardContainer/atoms';
import Ghost from 'appContainer/DashboardContainer/Ghost';
import BodyTr from 'appContainer/DashboardContainer/BodyTr';
import { ComponentType } from 'react';
import withDndRows from 'appContainer/DashboardContainer/withDndRows';
import styled from 'styled-components';

const DragTableHeader = styled(DragTableHeaderWrapper).attrs({
  'data-root-level': true,
  children: 'Drag to the top',
})`
  height: 110px;
`;

type DndProps = any;

export const browserWithDndRows = <P extends Record<string, unknown>>(
  DecoratedComponent: ComponentType<P>
) => {
  const DecoratedComponentWithDndRows = ({
    isDragging,
    isUpdating,
    draggedIds,
    currentMousePosition,
    hoverId,
    overFolderId,
    dragStartedOnFolderId,
    draggedRowType,
    highlightStatusMap,
    previewId,
    selected,
    isDndEnabled = true,
    ...props
  }: P & DndProps) => {
    if (!isDndEnabled) {
      return (
        <DecoratedComponent
          skipExpandOnSearch
          loading={isUpdating}
          selected={selected}
          {...props}
        />
      );
    }

    const dragDropProps = {
      isUpdating,
      isDragging,
      hoverId,
      overFolderId,
      draggedIds,
      dragStartedOnFolderId,
      selected,
      renameId: props.renameId,
      highlightStatusMap,
      previewId,
    };

    return (
      <>
        {isDragging && (
          <>
            <DragTableHeader />
            <Ghost
              position={currentMousePosition}
              draggedIds={draggedIds}
              draggedRowType={draggedRowType}
            />
          </>
        )}
        <DecoratedComponent
          skipExpandOnSearch
          loading={isUpdating}
          selected={selected}
          components={{ BodyTr, Table: TableTag }}
          dragDropProps={dragDropProps}
          {...props}
        />
      </>
    );
  };

  return withDndRows(DecoratedComponentWithDndRows);
};
