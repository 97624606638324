import {
  APIFieldAttributes,
  APIReferenceType,
  ArdoqId,
  ViewIds,
} from '@ardoq/api-types';
import { NewEntityAttributes } from 'scopeData/scopeEditUtils/types';

interface WorkspaceEditorState {
  name: string;
  startView: ViewIds | null;
  defaultPerspective: string | null;
  defaultSort: string | null;
  validationErrors: Record<string, any>;
}

export enum GetContentOptionsType {
  MENU = 'menu',
  WORKSPACE_PROPERTIES = 'workspace-properties',
  CREATE_COMPONENT = 'create-component',
  COMPONENT_PROPERTIES = 'component-properties',
  COMPONENT_STYLE = 'component-style',
  CREATE_REFERENCE = 'create-reference',
  REFERENCE_PROPERTIES = 'reference-properties',
  CREATE_FIELD = 'create-field',
  FIELD_EDITOR = 'field-editor',
  MANAGE_FIELDS = 'manage-fields',
  ADD_FIELD_TO_WORKSPACE = 'add-field-to-ws',
  MANAGE_REFERENCE_TYPES = 'manage-reference-types',
  CREATE_REFERENCE_TYPE = 'create-reference-type',
  REFERENCE_TYPE_EDITOR = 'reference-type-editor',
}

type AddFieldToWorkspaceGetContentOptions = {
  type: GetContentOptionsType.ADD_FIELD_TO_WORKSPACE;
};
type ManageFieldsGetContentOptions = {
  type: GetContentOptionsType.MANAGE_FIELDS;
};
export type FieldEditorGetContentOptions = {
  type: GetContentOptionsType.FIELD_EDITOR;
  field?: { id: ArdoqId };
  label?: string;
  presetTypes?: { isComponent: boolean; ids: string[] };
};
type ManageReferenceTypesGetContentOptions = {
  type: GetContentOptionsType.MANAGE_REFERENCE_TYPES;
};
type MenuGetContentOptions = {
  type: GetContentOptionsType.MENU;
  tabId?: string;
};
type WorkspacePropertiesGetContentOptions = {
  type: GetContentOptionsType.WORKSPACE_PROPERTIES;
  workspaceId?: string;
  state?: WorkspaceEditorState;
};
type CreateReferenceTypeGetContentOptions = {
  type: GetContentOptionsType.CREATE_REFERENCE_TYPE;
};
type ReferenceTypeEditorGetContentOptions = {
  type: GetContentOptionsType.REFERENCE_TYPE_EDITOR;
  referenceType: Pick<APIReferenceType, 'id'>;
  /** @deprecated argument is only dispatched from deprecated module */
  isNew?: boolean;
};
export type CreateFieldGetContentOptions = {
  type: GetContentOptionsType.CREATE_FIELD;
  label: string;
  attributes?: Partial<APIFieldAttributes>;
};

export type ComponentPropertiesGetContentOptions = {
  type: GetContentOptionsType.COMPONENT_PROPERTIES;
  attributes?: Record<string, any>;
  removableFields?: string[];
  componentIds?: ArdoqId[];
};
export type ReferencePropertiesGetContentOptions = {
  type: GetContentOptionsType.REFERENCE_PROPERTIES;
  attributes?: Record<string, any>;
  removableFields?: string[];
  referenceIds?: ArdoqId[];
};
type ComponentStyleGetContentOptions = {
  type: GetContentOptionsType.COMPONENT_STYLE;
  componentIds?: ArdoqId[];
};
type CreateComponentGetContentOptions = {
  type: GetContentOptionsType.CREATE_COMPONENT;
  parentId?: ArdoqId | null;
  workspaceId?: ArdoqId | null;
  flashMessage?: string;
  newOnSave?: boolean;
};
type CreateReferenceGetContentOptions = {
  type: GetContentOptionsType.CREATE_REFERENCE;
  referenceIds?: ArdoqId[];
  newReferenceAttributes?: NewEntityAttributes[];
};
export type GetContentOptions =
  | AddFieldToWorkspaceGetContentOptions
  | ManageFieldsGetContentOptions
  | FieldEditorGetContentOptions
  | ManageReferenceTypesGetContentOptions
  | MenuGetContentOptions
  | WorkspacePropertiesGetContentOptions
  | CreateReferenceTypeGetContentOptions
  | ReferenceTypeEditorGetContentOptions
  | CreateFieldGetContentOptions
  | ComponentPropertiesGetContentOptions
  | ReferencePropertiesGetContentOptions
  | ComponentStyleGetContentOptions
  | CreateComponentGetContentOptions
  | CreateReferenceGetContentOptions;
