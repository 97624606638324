import { ParameterOptions } from './types';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { updateParameterOptions } from './actions';

type ParameterOptionsState = ParameterOptions[];
const defaultState = [] as ParameterOptionsState;

const handleUpdateParameterOptions = (
  state: ParameterOptionsState,
  { parameterQueryId, parameterOptions }: Readonly<ParameterOptions>
) => [
  { parameterQueryId, parameterOptions },
  ...state.filter(existing => existing.parameterQueryId !== parameterQueryId),
];

const reducers = [
  reducer(updateParameterOptions, handleUpdateParameterOptions),
];

const parameterOptions$ = persistentReducedStream(
  'parameterOptions$',
  defaultState,
  reducers
);

export default parameterOptions$;
