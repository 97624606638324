import { APIOrganizationUser } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

export const fetchOrgUsers = actionCreator('[OrgUsers] FETCH_ORG_USERS');

export const fetchOrgUsersError = actionCreator<unknown>(
  '[OrgUsers] FETCH_ORG_USERS_ERROR'
);

export const fetchedOrgUsersSuccess = actionCreator<APIOrganizationUser[]>(
  '[orgUsers] FETCHED_ORG_USERS_SUCCESS'
);

export const resetOrgUsers = actionCreator('[orgUsers] RESET_ORG_USERS');
