import { actionCreator } from '@ardoq/rxbeach';

interface FieldPayload {
  readonly field: Backbone.Model;
}

export const notifyFieldRemoved = actionCreator<FieldPayload>(
  '[FieldActions] NOTIFY_FIELD_REMOVED'
);

export const notifyFieldAdded = actionCreator<FieldPayload>(
  '[FieldActions] NOTIFY_FIELD_ADDED'
);

export const notifyFieldUpdated = actionCreator<FieldPayload>(
  '[FieldActions] NOTIFY_FIELD_UPDATED'
);
