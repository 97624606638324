import {
  AugmentedModel,
  getAttributeOfModel,
  getAttributesOfModel,
  StringKeys,
} from 'modelInterface/genericInterfaces';
import {
  APIEntityType,
  APIReferenceType,
  APIWorkspaceAttributes,
  ArdoqId,
} from '@ardoq/api-types';
import { Model } from 'aqTypes';
import Workspaces, { modelIdToWorkspaceId } from 'collections/workspaces';
import Context from 'context';
import {
  GetAttribute,
  GetAttributes,
  GetComponentTypeHierarchy,
  GetComponentTypes,
  GetCssClassNames,
  GetCurrentWsId,
  GetCurrentWsName,
  GetDecomposedComponentTypes,
  GetDescription,
  GetLoadedWorkspaceIds,
  GetLoadedWorkspaceOptions,
  GetManagedComponentFields,
  GetManagedReferenceFields,
  GetModelData,
  GetNameToComponentTypeId,
  GetNameToReferenceTypeId,
  GetNameToVirtualReferenceTypeId,
  GetReferenceTypeById,
  GetReferenceTypes,
  GetStartView,
  GetStartViewOptions,
  GetStubWorkspaces,
  GetTypeById,
  GetWorkspace,
  GetWorkspaceCount,
  GetWorkspaceData,
  GetWorkspaceIdByModelId,
  GetWorkspaceIntegrations,
  GetWorkspaceModelId,
  GetWorkspaceName,
  HasWriteAccess,
  IsExternallyManaged,
  IsFlexible,
  IsWorkspace,
  SetCurrentWorkspace,
  WorkspaceInterfaceImplementation,
} from '@ardoq/workspace-interface';
import type {
  ComponentTypeWithoutChildren,
  StubWorkspace,
} from '@ardoq/data-model';
import Models from 'collections/models';
import { ModelType } from 'models/ModelType';
import { ModelSaveOptions } from 'backbone';
import Workspace from 'models/workspace';
import { TrackingLocation } from 'tracking/types';
import { get as getGeneric } from 'collectionInterface/genericInterfaces';
import { getCollectionForEntityType } from 'collectionInterface/utils';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { modelInterface } from 'modelInterface/models/modelInterface';
import { omit } from 'lodash';
import { virtualModel } from 'models/virtualReference';

// NOTE:
// The purpose of BB interfaces is to avoid exposing the BB model. In practice
// this means you should __never__ return a BB model from these functions.

const getAttributesConfig: [
  Backbone.Collection<AugmentedModel<APIWorkspaceAttributes>>,
  Map<StringKeys<APIWorkspaceAttributes>, StringKeys<APIWorkspaceAttributes>>,
] = [
  Workspaces.collection,
  // @ts-expect-error the id, _id situation is for compatibility with the legacy Backbone structure
  new Map([['id', '_id']]),
];

const getAttributes: GetAttributes =
  getAttributesOfModel<APIWorkspaceAttributes>(...getAttributesConfig);

const getAttribute: GetAttribute = getAttributeOfModel<APIWorkspaceAttributes>(
  ...getAttributesConfig
);

const getWorkspaceById = (workspaceId: ArdoqId) =>
  Workspaces.collection.get(workspaceId);

type GetModelByWsId = (workspaceId: ArdoqId) => Model | null;
const getModelByWsId: GetModelByWsId = workspaceId => {
  const workspace = getWorkspaceById(workspaceId);
  if (!workspace) return null;
  return workspace.getModel() ?? null;
};

const getComponentTypes: GetComponentTypes = workspaceId => {
  const model = getModelByWsId(workspaceId);
  if (!model) return [];
  return Object.values(model.getAllTypes());
};

/**
 * Decomposes component type hierarchy of a workspace by separating children
 * from component types using indexes. This allows for quick lookup of both
 * component types and their children.
 *
 * @example
 * {
 *   childrenIndex: {
 *     root: [...topLevelChildrenTypeIds]
 *     [componentTypeId]: [...childrenTypeIds]
 *   },
 *   componentTypes: {
 *     [componentTypeId]: ComponentTypeWithoutChildren
 *   }
 * }
 *
 */
const getDecomposedComponentTypes: GetDecomposedComponentTypes =
  workspaceId => {
    const model = getModelByWsId(workspaceId);
    if (!model) {
      throw new Error(
        'No workspace model available, cannot get component types'
      );
    }

    const root = modelInterface.getTypeHierarchy(model);

    const typesWithoutChildrenByTypeId: Record<
      string,
      ComponentTypeWithoutChildren
    > = {};
    const childrenIndex: Record<string, string[]> = {};

    const allTypesDict = model.getAllTypes();
    for (const compType of Object.values(allTypesDict)) {
      const childrenIds = Object.keys(compType.children);
      const childrenSet = childrenIndex[compType.id] ?? [];
      childrenIndex[compType.id] = Array.from(
        new Set([...childrenSet, ...childrenIds])
      );

      typesWithoutChildrenByTypeId[compType.id] = omit(compType, ['children']);
    }

    return {
      componentTypes: typesWithoutChildrenByTypeId,
      childrenIndex: {
        ...childrenIndex,
        root: Object.keys(root),
      },
    };
  };

const getComponentTypeHierarchy: GetComponentTypeHierarchy = workspaceId =>
  getModelByWsId(workspaceId)?.get('root') || {};

const getModelData: GetModelData = workspaceId => {
  const workspace = getWorkspaceById(workspaceId);
  return workspace ? workspace.getModel()?.toJSON() : null;
};

const getTypeById: GetTypeById = (workspaceId, typeId) => {
  const workspace = getWorkspaceById(workspaceId);
  const model = workspace && workspace.getModel();
  if (!model) {
    return null;
  }
  return model.getTypeById(typeId);
};

const getCssClassNames: GetCssClassNames = workspaceId => {
  const workspace = getWorkspaceById(workspaceId);
  return workspace ? workspace.getCSS() : null;
};

const getWorkspaceName: GetWorkspaceName = workspaceId => {
  const workspace = getWorkspaceById(workspaceId);
  return workspace ? workspace.get('name') : null;
};

const getDescription: GetDescription = workspaceId => {
  const workspace = getWorkspaceById(workspaceId);
  return workspace ? workspace.getDescription() : null;
};

const getWorkspaceIntegrations: GetWorkspaceIntegrations = workspaceId => {
  return getWorkspaceById(workspaceId)?.getIntegrations() ?? [];
};

const hasWriteAccess: HasWriteAccess = workspaceId => {
  const workspace = getWorkspaceById(workspaceId);
  return Boolean(workspace && workspace.hasWriteAccess());
};

const getWorkspaceIdByModelId: GetWorkspaceIdByModelId = modelId =>
  modelIdToWorkspaceId.get(modelId) ?? null;

const isExternallyManaged: IsExternallyManaged = workspaceId => {
  const wSIntegrations = getWorkspaceIntegrations(workspaceId);
  return Array.isArray(wSIntegrations) && wSIntegrations.length > 0;
};

const isFlexibleWorkspace: IsFlexible = (workspaceId: string) => {
  const model = getModelByWsId(workspaceId);
  return model?.isFlexible() ?? false;
};

const getManagedComponentFields: GetManagedComponentFields = workspaceId => {
  return getWorkspaceIntegrations(workspaceId).flatMap(
    integration => integration.componentFields
  );
};

const getManagedReferenceFields: GetManagedReferenceFields = workspaceId => {
  return getWorkspaceIntegrations(workspaceId).flatMap(
    integration => integration.referenceFields
  );
};

const getLoadedWorkspaceOptions: GetLoadedWorkspaceOptions = () =>
  Workspaces.collection
    .toArray()
    .map(workspace => ({ label: workspace.get('name'), value: workspace.id }));

const getReferenceTypeById: GetReferenceTypeById = (
  workspaceId,
  referenceTypeId
) => {
  const refType =
    getModelByWsId(workspaceId)?.getReferenceTypeById(referenceTypeId);
  return refType ?? null;
};

const getLoadedWorkspaceIds: GetLoadedWorkspaceIds = () =>
  Workspaces.collection.map(ws => ws.getId());

const getWorkspaceModelId: GetWorkspaceModelId = workspaceId =>
  getWorkspaceById(workspaceId)?.getModel()?.id ?? null;

const getCurrentWsId: GetCurrentWsId = () => Context.activeWorkspaceId();

const getCurrentWsName: GetCurrentWsName = () => {
  const workspaceId = Context.activeWorkspaceId() || '';
  return getWorkspaceById(workspaceId)?.get('name') ?? null;
};

const setCurrentWorkspace: SetCurrentWorkspace = (id, trackingLocation) => {
  const workspace = getWorkspaceById(id);
  if (workspace) {
    Context.loadWorkspaces({
      contextWorkspace: workspace,
      trackingLocation: trackingLocation as TrackingLocation,
    });
  }
};

const getWorkspaceData: GetWorkspaceData = workspaceId =>
  getWorkspaceById(workspaceId)?.toJSON() ?? null;

const getReferenceTypes: GetReferenceTypes = workspaceId => {
  const workspaceModelId = getWorkspaceModelId(workspaceId);
  if (!workspaceModelId) {
    return null;
  }
  return Models.collection.get(workspaceModelId)?.getReferenceTypes() ?? null;
};

const getStartViewOptions: GetStartViewOptions = workspaceId =>
  Workspaces.collection
    .get(workspaceId)
    ?.getStartViewOptions()
    .map(option => ({
      label: option.label,
      value: option.val,
    }));

const save = (
  id: ArdoqId,
  attributes?: Partial<APIWorkspaceAttributes>,
  options?: ModelSaveOptions
) => getWorkspaceById(id)?.save(attributes, options);

const getStartView: GetStartView = id =>
  getWorkspaceById(id)?.get('startView') ?? null;

const isWorkspace: IsWorkspace = workspaceId =>
  getWorkspaceById(workspaceId) instanceof Workspace.model;

const getNameToComponentTypeId: GetNameToComponentTypeId = (
  workspaceIds,
  componentTypeName
) =>
  getNameToTypeId(
    workspaceIds,
    (model?: Model) => model?.getAllTypes() ?? {},
    componentTypeName
  );

const getNameToReferenceTypeId: GetNameToReferenceTypeId = (
  workspaceIds,
  referenceTypeName
) =>
  getNameToTypeId(
    workspaceIds,
    (model?: Model) => model?.getReferenceTypes() ?? {},
    referenceTypeName
  );

const getNameToVirtualReferenceTypeId: GetNameToVirtualReferenceTypeId =
  referenceTypeName =>
    getTypeIdWithName(virtualModel.getReferenceTypes(), referenceTypeName);

const getNameToTypeId = (
  workspaceIds: ArdoqId[],
  getTypeDict: (
    model?: Model
  ) => Record<string | number, APIReferenceType | ModelType>,
  typeName: string
): Record<string, string | number | undefined> => {
  const workspaceIdTypeIdTuples = workspaceIds.map(id => {
    const typeDict = getTypeDict(getWorkspaceById(id)?.getModel());
    const typeId = getTypeIdWithName(typeDict, typeName);
    return [id, typeId];
  });
  return Object.fromEntries(workspaceIdTypeIdTuples);
};

const getTypeIdWithName = (
  typeDict: Record<string | number, APIReferenceType | ModelType>,
  typeName: string
) => Object.values(typeDict).find(({ name }) => name === typeName)?.id;

const workspaceExists = (
  workspaceId?: ArdoqId | null
): workspaceId is ArdoqId =>
  Boolean(workspaceId && getWorkspaceData(workspaceId));

const getWorkspaceCount: GetWorkspaceCount = () => {
  return Workspaces.collection.length;
};

const get: GetWorkspace = id => getGeneric(APIEntityType.WORKSPACE, id);

const findByModel: GetWorkspace = modelId =>
  getCollectionForEntityType(APIEntityType.WORKSPACE)
    .findWhere({ componentModel: modelId })
    ?.toJSON() ?? null;

const getStubWorkspaces: GetStubWorkspaces = (
  ids: ArdoqId[]
): StubWorkspace[] => {
  return ids
    .map(
      id =>
        getAttributes(id, ['_id', 'name', 'componentModel']) as {
          _id: ArdoqId;
          name: string;
          componentModel: ArdoqId;
        }
    )
    .filter(ExcludeFalsy);
};

const getVirtualReferenceTypes = (workspaceId: string) =>
  virtualModel.getReferenceTypesForWorkspace(workspaceId);

export const workspaceInterface: WorkspaceInterfaceImplementation = {
  get,
  findByModel,
  getAttributes,
  getAttribute,
  getComponentTypes,
  getDecomposedComponentTypes,
  getComponentTypeHierarchy,
  getModelData,
  getTypeById,
  getCssClassNames,
  getWorkspaceName,
  getDescription,
  getWorkspaceIntegrations,
  hasWriteAccess,
  getWorkspaceIdByModelId,
  isExternallyManaged,
  isFlexibleWorkspace,
  getManagedComponentFields,
  getManagedReferenceFields,
  getLoadedWorkspaceOptions,
  getReferenceTypeById,
  getLoadedWorkspaceIds,
  getWorkspaceModelId,
  getCurrentWsId,
  getCurrentWsName,
  setCurrentWorkspace,
  getWorkspaceData,
  getReferenceTypes,
  getStartViewOptions,
  save,
  getStartView,
  isWorkspace,
  getNameToComponentTypeId,
  getNameToReferenceTypeId,
  getNameToVirtualReferenceTypeId,
  workspaceExists,
  getWorkspaceCount,
  getStubWorkspaces,
  getVirtualReferenceTypes,
};
