import { PersistedMetamodel } from '@ardoq/api-types';
import {
  addMetamodel,
  removeMetamodel,
  setMetamodels,
  updateMetamodel,
} from './metamodelActions';
import { reducer } from '@ardoq/rxbeach';

const order = (a: PersistedMetamodel, b: PersistedMetamodel) => {
  if (a.wholeOrg) return -1;
  if (b.wholeOrg) return 1;
  if (a._id === b._id) return 0;
  if (a._id < b._id) return -1;
  return 1;
};

const handleSetMetamodels = (
  currentState: PersistedMetamodel[],
  metamodels: PersistedMetamodel[]
) => [...metamodels].sort(order);

const handleAddMetamodel = (
  currentState: PersistedMetamodel[],
  metamodel: PersistedMetamodel
) => [...currentState, metamodel].sort(order);

const handleRemoveMetamodel = (
  currentState: PersistedMetamodel[],
  metamodelId: string
) => currentState.filter(({ _id }) => _id !== metamodelId);

const handleUpdateMetamodel = (
  currentState: PersistedMetamodel[],
  metamodel: PersistedMetamodel
) =>
  [...currentState.filter(({ _id }) => _id !== metamodel._id), metamodel].sort(
    order
  );

const reducers = [
  reducer(setMetamodels, handleSetMetamodels),
  reducer(addMetamodel, handleAddMetamodel),
  reducer(updateMetamodel, handleUpdateMetamodel),
  reducer(removeMetamodel, handleRemoveMetamodel),
];

export default reducers;
