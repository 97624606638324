import { ExtractPayload } from '@ardoq/rxbeach';
import { InitGridEditorPayload, initGridEditorState } from './actions';
import { GridEditorFrame, GridEditorState } from './types';

export const isDockedStateInit = (things: {
  state: GridEditorState;
  payload: ExtractPayload<typeof initGridEditorState>;
}): things is {
  state: Extract<
    GridEditorState,
    { frameId: GridEditorFrame.ARDOQ_FRONT_BRIDGE | GridEditorFrame.DOCKED }
  >;
  payload: Extract<
    InitGridEditorPayload,
    { activeFrameId: GridEditorFrame.DOCKED }
  >;
} => {
  const { state, payload } = things;
  return (
    payload.activeFrameId === GridEditorFrame.DOCKED &&
    [GridEditorFrame.ARDOQ_FRONT_BRIDGE, GridEditorFrame.DOCKED].includes(
      state.frameId
    )
  );
};

export const isPopoutStateInit = (things: {
  state: GridEditorState;
  payload: ExtractPayload<typeof initGridEditorState>;
}): things is {
  state: Extract<
    GridEditorState,
    { frameId: GridEditorFrame.ARDOQ_FRONT_BRIDGE | GridEditorFrame.POPOUT }
  >;
  payload: Extract<
    InitGridEditorPayload,
    { activeFrameId: GridEditorFrame.POPOUT }
  >;
} => {
  const { state, payload } = things;
  return (
    payload.activeFrameId === GridEditorFrame.POPOUT &&
    [GridEditorFrame.ARDOQ_FRONT_BRIDGE, GridEditorFrame.POPOUT].includes(
      state.frameId
    )
  );
};
