import { map } from 'rxjs/operators';
import storedQueries$ from 'streams/queries/storedQueries$';
import { SearchType } from '@ardoq/api-types';

const dynamicFilters$ = storedQueries$.pipe(
  map(state =>
    state.storedQueries.filter(
      query => query.type === SearchType.DYNAMIC_FILTER_QUERY
    )
  )
);

export default dynamicFilters$;
