import { FC } from 'react';
import externallyManagedNotice$ from 'externallyManaged/externallyManagedNotice$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { setExternallyManagedNoticeSuppressed } from 'externallyManaged/actions';
import { PayloadIsSuppressed } from 'externallyManaged/types';
import { Checkbox } from '@ardoq/forms';

const SuppressExternallyManagedNotice: FC<PayloadIsSuppressed> = ({
  isSuppressed,
}) => {
  return (
    <Checkbox
      isChecked={Boolean(isSuppressed)}
      onChange={() =>
        dispatchAction(
          setExternallyManagedNoticeSuppressed({
            isSuppressed: !isSuppressed,
          })
        )
      }
    >
      Don’t show me this message again
    </Checkbox>
  );
};
export default connect(
  SuppressExternallyManagedNotice,
  externallyManagedNotice$
);
