import { APITagAttributes } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

export const fetchTags = actionCreator('[tags] FETCH_TAGS');

export const notifyFetchingTagsSucceeded = actionCreator<APITagAttributes[]>(
  '[tags] NOTIFY_FETCHING_TAGS_SUCCEEDED'
);

export const notifyFetchingTagsFailed = actionCreator<{ error: string }>(
  '[tags] NOTIFY_FETCHING_TAGS_FAILED'
);
