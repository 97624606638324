import { PersonalSetting } from '@ardoq/api-types';
import {
  getDefaultLocale,
  getLocale,
  Locale,
  registerImplementation,
} from '@ardoq/locale';
import { logError } from '@ardoq/logging';
import CurrentUser from '../models/currentUser';

let currentLocale: Locale;

// --------------------------------------------------------------------------------------------------------------------
// Prefarebly this approach but since we don't use streams to provide this value (we use the get method),
// It will cause UX bug in ASSET_MANAGER.
//
// export const setLocaleFromUserSettings = (
//   currentUser: CurrentUserBackboneModel
// ) => {
//   const usersLocale = currentUser?.getPersonalSetting(PersonalSetting.LOCALE);
//   currentLocale = getLocaleCode(usersLocale) || getDefaultLocale();
// };
//
// export const initializeLocale = () =>
//   registerImplementation({
//     getCurrentLocale: () => {
//       if (currentLocale) return currentLocale;
//       currentLocale = getDefaultLocale();
//       return currentLocale;
//     },
//   });
// --------------------------------------------------------------------------------------------------------------------

export const setLocaleFromUserSettings = () => {
  const usersLocale = CurrentUser.getPersonalSetting(PersonalSetting.LOCALE);

  currentLocale = getLocale(usersLocale) ?? getDefaultLocale();
};

export const initializeLocale = () =>
  registerImplementation({
    getCurrentLocale: () => {
      if (currentLocale) return currentLocale;

      try {
        setLocaleFromUserSettings();
      } catch (e) {
        logError(e as Error, 'Could not initialize locale from CurrentUser');
        currentLocale = getDefaultLocale();
      }

      return currentLocale;
    },
  });
