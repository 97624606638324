import { APIFieldType, UpdatedResponse } from '@ardoq/api-types';
import {
  BooleanDecorator,
  DateDecorator,
  DecoratorWrapper,
} from '@ardoq/decorators';
import { colors, s16, s8 } from '@ardoq/design-tokens';
import { Icon, IconName } from '@ardoq/icons';
import { TextOverflow } from '@ardoq/popovers';
import {
  UNSET_VALUE_LABEL,
  NoValue,
  MarkdownInPopover,
  RenderOptions,
} from '@ardoq/renderers';
import { Tag, StatusType } from '@ardoq/status-ui';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import styled from 'styled-components';

const Container = styled.div`
  background: ${colors.white};
  border-top: 1px solid ${colors.grey80};
  border-bottom: 1px solid ${colors.grey80};
  margin: ${s16} 0;
`;

export const Row = styled.tr`
  background: ${colors.white};
  &::after {
    border: none;
  }
`;

// passing in a non styled thead here to avoid having a border bottom on the header
const Thead = styled.thead``;

const StyledTag = styled(Tag)`
  display: block;
  max-width: fit-content;
`;

const getFieldRenderer = (name: string, type: APIFieldType, value: any) => {
  if (name === 'description' || type === APIFieldType.TEXT_AREA) {
    return !value ? (
      <NoValue />
    ) : (
      <MarkdownInPopover value={value} options={{} as RenderOptions} />
    );
  }
  switch (type) {
    case APIFieldType.SELECT_MULTIPLE_LIST:
      return value?.length ? (
        <TextOverflow>{value.join(', ')}</TextOverflow>
      ) : (
        <NoValue />
      );
    case APIFieldType.CHECKBOX:
      return (
        <BooleanDecorator unsetValueLabel={UNSET_VALUE_LABEL} checked={value} />
      );
    // TODO ZhenyaUsenko: double check date ranges as DateDecorator has dateFrom/dateUntil props
    case APIFieldType.DATE_ONLY:
    case APIFieldType.DATE_TIME:
    case APIFieldType.DATE_ONLY_RANGE:
    case APIFieldType.DATE_TIME_RANGE:
      return value ? <DateDecorator date={value} /> : <NoValue />;
    default: {
      if (value === undefined || value === null || typeof value === 'object') {
        return <NoValue />;
      }
      if (typeof value === 'string') {
        return value.length > 0 ? (
          <TextOverflow>{value}</TextOverflow>
        ) : (
          <NoValue />
        );
      }
      return <DecoratorWrapper>{value}</DecoratorWrapper>;
    }
  }
};

const ChangeRowTable = ({
  surveyResponse,
}: {
  surveyResponse: UpdatedResponse;
}) => {
  return (
    <Container>
      <DatasourceTable
        isFixedLayout
        dataSource={[surveyResponse]}
        columns={[
          {
            title: 'Before',
            headerStyle: { padding: `0 ${s8}`, width: '48%' },
            valueRender: (_value: string, surveyResponse: UpdatedResponse) => (
              <StyledTag statusType={StatusType.ERROR}>
                {getFieldRenderer(
                  surveyResponse.name,
                  surveyResponse.type,
                  surveyResponse.previousVal
                )}
              </StyledTag>
            ),
          },
          {
            headerStyle: { padding: `0 ${s8}`, width: '4%' },
            valueRender: () => <Icon iconName={IconName.ARROW_FORWARD} />,
          },
          {
            title: 'Now',
            headerStyle: { padding: `0 ${s8}`, width: '48%' },
            valueRender: (_value: string, surveyResponse: UpdatedResponse) => (
              <StyledTag statusType={StatusType.SUCCESS}>
                {getFieldRenderer(
                  surveyResponse.name,
                  surveyResponse.type,
                  surveyResponse.currentVal
                )}
              </StyledTag>
            ),
          },
        ]}
        components={{ ...newTableTheme, HeadTr: Row, Thead }}
        getRowComponent={() => Row}
      />
    </Container>
  );
};

export default ChangeRowTable;
