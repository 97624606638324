import { some } from 'lodash';
import {
  CSSAbsolutePositionProps,
  DockedGridEditorFocus,
  DockedGridEditorState,
  DockedGridEditorVisibility,
  DropdownOpenerSource,
  GridEditorFrame,
  GridEditorSharedState,
  GridEditorState,
  PopoutGridEditorState,
  VIEW_TYPES,
} from './types';
import {
  BatchUpdateFieldsPayload,
  DisabledFieldsPayload,
  SetColumnSortPayload,
} from './actions';
import { ContextShape, TAGS_FIELD_NAME } from '@ardoq/data-model';
import { ArdoqId } from '@ardoq/api-types';
import { ContextSort, NumericSortOrder } from '@ardoq/common-helpers';

/**
 * Common state between docked and popout grid editors
 */
const getGridEditorSharedState = (): GridEditorSharedState => {
  return {
    activeViewType: VIEW_TYPES.COMPONENT,
    fieldEditor: fieldEditorDefaultState(),
    trackingClickNamespace: 'grid-editor-components-tab',
    isGridEditorTableLoaded: false,
    isShowAllComponents: false,
    disabledWorkspaces: new Set<ArdoqId>(),
    currentDropdownOpenerSource: null,
    disabledFields: new Set<string>(
      gridEditorStateOperations.getDefaultDisabledFieldNames()
    ),
  };
};

const isArdoqFrontRuntime = (state: GridEditorState) => {
  return state.frameId === GridEditorFrame.ARDOQ_FRONT_BRIDGE;
};

const isPopoutGridEditorRuntime = (state: GridEditorState) => {
  return state.frameId === GridEditorFrame.POPOUT;
};

const isDockedGridEditorState = (
  state: GridEditorState
): state is DockedGridEditorState => {
  return state.activeFrameId === GridEditorFrame.DOCKED;
};

const isAnyGridEditorOpen = (state: GridEditorState) => {
  return isDockedGridEditorOpen(state) || isPopoutGridEditorOpen(state);
};

const isPopoutGridEditorState = (
  state: GridEditorState
): state is PopoutGridEditorState => {
  return some([
    state.frameId === GridEditorFrame.POPOUT,
    state.activeFrameId === GridEditorFrame.POPOUT,
  ]);
};

const isDockedGridEditorOpen = (
  state: GridEditorState
): state is DockedGridEditorState => {
  if (!isDockedGridEditorState(state)) return false;
  return state.dockedGridEditorVisibility !== DockedGridEditorVisibility.CLOSED;
};

const isPopoutGridEditorOpen = (state: GridEditorState) => {
  if (isDockedGridEditorState(state)) {
    return false;
  }
  return state.activeFrameId === GridEditorFrame.POPOUT;
};

const isGridEditorVisible = (state: GridEditorState) => {
  if (isDockedGridEditorState(state)) {
    return isDockedGridEditorOpen(state);
  }
  if (isPopoutGridEditorState(state)) {
    return isPopoutGridEditorOpen(state);
  }
  return false;
};

const isEditorFullscreen = (state: GridEditorState) => {
  if (isPopoutGridEditorState(state)) {
    return true;
  }
  return isDockedEditorFullscreen(state);
};

const isDockedEditorFullscreen = (state: GridEditorState) => {
  if (!isDockedGridEditorState(state)) {
    return false;
  }
  return (
    state.dockedGridEditorVisibility === DockedGridEditorVisibility.FULLSCREEN
  );
};

const isDockedEditorExpanded = (
  state: GridEditorState
): state is DockedGridEditorState => {
  if (!isDockedGridEditorState(state)) {
    return false;
  }
  return (
    state.dockedGridEditorVisibility === DockedGridEditorVisibility.EXPANDED
  );
};

const isDockedEditorInForeground = (
  state: GridEditorState
): state is DockedGridEditorState => {
  if (!isDockedGridEditorState(state)) {
    return false;
  }
  return state.dockedGridEditorFocus === DockedGridEditorFocus.FOREGROUND;
};

const isDockedEditorInBackground = (
  state: GridEditorState
): state is DockedGridEditorState => {
  if (!isDockedGridEditorState(state)) {
    return false;
  }
  return state.dockedGridEditorFocus === DockedGridEditorFocus.BACKGROUND;
};

const setActiveViewTypeToComponent = (
  state: GridEditorState
): GridEditorState => ({
  ...state,
  activeViewType: VIEW_TYPES.COMPONENT,
  trackingClickNamespace: 'grid-editor-components-tab',
});

const setActiveViewTypeToReference = (
  state: GridEditorState
): GridEditorState => ({
  ...state,
  activeViewType: VIEW_TYPES.REFERENCE,
  trackingClickNamespace: 'grid-editor-references-tab',
});

const isComponentTabVisible = (state: GridEditorState) => {
  return state.activeViewType === VIEW_TYPES.COMPONENT;
};

const isReferenceTabVisible = (state: GridEditorState) => {
  return state.activeViewType === VIEW_TYPES.REFERENCE;
};

const getDisabledFieldNames = (state: GridEditorState) => {
  return state.disabledFields;
};

// disabled fields
const getDefaultDisabledFieldNames = () => {
  return [
    '_id',
    'description',
    'parent',
    TAGS_FIELD_NAME,
    'type',
    'locked',
    'created',
    'last-updated',
  ];
};

const enableAllFields = (state: GridEditorState): GridEditorState => {
  return {
    ...state,
    disabledFields: new Set<string>(),
  };
};

const overwriteDisabledFields = (
  state: GridEditorState,
  { disabledFields }: DisabledFieldsPayload
): GridEditorState => {
  return {
    ...state,
    disabledFields: new Set(disabledFields),
  };
};

const batchUpdateFields = (
  state: GridEditorState,
  { enable, disable }: BatchUpdateFieldsPayload
): GridEditorState => {
  // Copy to avoid mutating the state
  const disabledFields = new Set(state.disabledFields);

  // Enable fields
  for (const fieldName of enable ?? []) {
    disabledFields.delete(fieldName);
  }

  // Disable fields
  for (const fieldName of disable ?? []) {
    disabledFields.add(fieldName);
  }

  return {
    ...state,
    disabledFields,
  };
};

const getAscFieldSort = (fieldName: string): ContextSort => ({
  attr: fieldName,
  order: NumericSortOrder.ASC,
  orderUnsetLast: true,
});
const getDescFieldSort = (fieldName: string): ContextSort => ({
  attr: fieldName,
  order: NumericSortOrder.DESC,
  orderUnsetLast: true,
});

const setColumnSort = (
  state: GridEditorState,
  { fieldName }: SetColumnSortPayload
): GridEditorState => {
  if (state.columnSort?.attr !== fieldName) {
    return { ...state, columnSort: getAscFieldSort(fieldName) };
  }

  if (state.columnSort.order === NumericSortOrder.ASC) {
    return { ...state, columnSort: getDescFieldSort(fieldName) };
  }

  return { ...state, columnSort: undefined };
};

const isDisabledWorkspace = (
  state: Pick<GridEditorState, 'disabledWorkspaces'>,
  workspaceId: string
) => {
  return state.disabledWorkspaces.has(workspaceId);
};

/**
 * Control buttons are the buttons on the right hand side of the black bar
 * above the Grid Editor allow the user to open/close, fullscreen, and pop out
 * the editor.
 */
const hideControlButtons = (state: GridEditorState) => {
  return isPopoutGridEditorOpen(state);
};

const isFieldEditorOpen = (state: GridEditorState) => {
  return (
    state.currentDropdownOpenerSource ===
    DropdownOpenerSource.SRC_EDITOR_TOOLBAR_ADD_FIELD
  );
};

const isDropdownSelectFieldOpen = (state: GridEditorState) => {
  return (
    state.currentDropdownOpenerSource ===
    DropdownOpenerSource.SRC_EDITOR_TOOLBAR_SELECT_FIELD
  );
};

const isWorkspaceSelectorOpen = (state: GridEditorState) => {
  return (
    state.currentDropdownOpenerSource ===
    DropdownOpenerSource.SRC_WORKSPACE_SELECTOR
  );
};

const fieldEditorDefaultState = (): GridEditorState['fieldEditor'] => ({
  editor: 'add-existing-field',
});

const fieldEditorOpen = (state: GridEditorState): GridEditorState => {
  return {
    ...state,
    fieldEditor: fieldEditorDefaultState(),
    currentDropdownOpenerSource:
      DropdownOpenerSource.SRC_EDITOR_TOOLBAR_ADD_FIELD,
  };
};

const fieldEditorClose = (state: GridEditorState): GridEditorState => {
  return {
    ...state,
    fieldEditor: fieldEditorDefaultState(),
    currentDropdownOpenerSource: null,
  };
};

/**
 * Loaded workspaces that have not been disabled by the user in the editor
 */
const getActiveConnectedWorkspaceIds = (args: {
  context: Pick<ContextShape, 'connectedWorkspaceIds'>;
  gridEditorModel: Pick<GridEditorState, 'disabledWorkspaces'>;
}) => {
  const { context, gridEditorModel } = args;
  return context.connectedWorkspaceIds.filter(id => {
    return !isDisabledWorkspace(gridEditorModel, id);
  });
};

/**
 * Typically the target workspace when creating a reference
 * This logic is weird but matches the behavior of production, essentially:
 * - If there is only one connected workspace, use that
 * - Otherwise, use the current workspace
 * So the only way to create a reference in another workspace is to only have
 * that workspace enabled.
 */
const getTargetWorkspaceId = (args: {
  context: ContextShape;
  gridEditorModel: GridEditorState;
}) => {
  const { context } = args;
  const activeWorkspaceIds = getActiveConnectedWorkspaceIds(args);

  if (activeWorkspaceIds.length === 1) {
    return activeWorkspaceIds[0];
  }
  return context.workspaceId;
};

/**
 * CSS style to take up the full screen
 */
const getFullscreenEditorStyle = (): CSSAbsolutePositionProps => {
  return {
    position: 'absolute',
    left: '0px',
    top: '0px',
    width: '100%',
    height: '100%',
  };
};

/**
 * Initialize the state depending on which frameId (runtime) it is running in:
 * ardoq-front, docked, and popout.
 */
const getDefaultGridEditorState = (
  frameId: GridEditorFrame
): GridEditorState => {
  const sharedDefaultState = getGridEditorSharedState();

  // Ardoq-front will never start with a popout editor open, so if mode is
  // popout we know that the frameId is popout too.
  if (frameId === GridEditorFrame.POPOUT) {
    return {
      frameId,
      activeFrameId: GridEditorFrame.POPOUT,
      ...sharedDefaultState,
    };
  }

  return {
    frameId,
    activeFrameId: GridEditorFrame.DOCKED,
    dockedGridEditorVisibility: DockedGridEditorVisibility.CLOSED,
    dockedGridEditorFocus: DockedGridEditorFocus.BACKGROUND,
    dockedEditorPaneStyle: getFullscreenEditorStyle(),
    ...sharedDefaultState,
  };
};

/**
 * Get the viewType of the open tab, null if the Grid Editor is not open
 */
const getOpenTabName = (gridEditorModel: GridEditorState) => {
  const isVisible =
    gridEditorStateOperations.isGridEditorVisible(gridEditorModel);
  if (!isVisible) {
    return null;
  }
  return gridEditorModel.activeViewType;
};

export const gridEditorStateOperations = {
  batchUpdateFields,
  enableAllFields,
  setColumnSort,
  fieldEditorOpen,
  fieldEditorClose,
  fieldEditorDefaultState,
  isArdoqFrontRuntime,
  isPopoutGridEditorRuntime,
  isDockedGridEditorState,
  isDockedEditorExpanded,
  isDockedEditorInBackground,
  isDockedEditorInForeground,
  isFieldEditorOpen,
  isDropdownSelectFieldOpen,
  isWorkspaceSelectorOpen,
  isPopoutGridEditorState,
  isAnyGridEditorOpen,
  isDockedGridEditorOpen,
  isPopoutGridEditorOpen,
  isGridEditorVisible,
  isEditorFullscreen,
  setActiveViewTypeToComponent,
  setActiveViewTypeToReference,
  isComponentTabVisible,
  isReferenceTabVisible,
  isDisabledWorkspace,
  getTargetWorkspaceId,
  getActiveConnectedWorkspaceIds,
  getDefaultDisabledFieldNames,
  getDisabledFieldNames,
  getFullscreenEditorStyle,
  getDefaultGridEditorState,
  getOpenTabName,
  overwriteDisabledFields,
  hideControlButtons,
};
