import { GhostWrapper } from './atoms';
import { RowType } from '@ardoq/table';
import type { Point } from '@ardoq/math';

type GhostProps = {
  position: Point;
  draggedIds: string[];
  draggedRowType?: RowType;
};

const getGhostMessage = (draggedIds: string[], draggedRowType?: RowType) => {
  if (draggedRowType === RowType.FOLDER) {
    return '1 folder';
  }
  return `${draggedIds.length} asset${draggedIds.length > 1 ? 's' : ''}`;
};

const Ghost = ({
  position: [positionX, positionY],
  draggedIds,
  draggedRowType,
}: GhostProps) => (
  <GhostWrapper
    style={{ transform: `translate(${positionX}px, ${positionY}px)` }}
  >
    {getGhostMessage(draggedIds, draggedRowType)}
  </GhostWrapper>
);

export default Ghost;
