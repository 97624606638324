import { FormSize, FormWrapper, TextArea, TextInput } from '@ardoq/forms';
import { ModalLayout, ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import { TAG_NAME_VALIDATION_MESSAGE, TAG_REGEX } from 'models/consts';
import { useState } from 'react';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  AddTagPayload,
  EditTagPayload,
  createTag,
  updateTag,
} from 'streams/tags/TagActions';

interface EditTagProps {
  tag?: EditTagPayload;
  headerText: string;
  onClose: VoidFunction;
  onSubmit: (tag: AddTagPayload) => void;
}

const TagEditor = ({ tag, headerText, onClose, onSubmit }: EditTagProps) => {
  const [name, setName] = useState<string>(tag?.name || '');
  const [description, setDescription] = useState<string>(
    tag?.description || ''
  );

  const isNameValid = TAG_REGEX.test(name);

  return (
    <ModalTemplate
      modalSize={ModalSize.S}
      headerText={headerText}
      primaryButtonText="Save"
      primaryButtonClickId="confirm-save"
      isPrimaryButtonDisabled={!isNameValid}
      onPrimaryButtonClick={() => {
        onSubmit({ name, description });
        onClose();
      }}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={() => onClose()}
    >
      <ModalLayout>
        <FormWrapper $formSize={FormSize.AUTO}>
          <TextInput
            label="Name"
            data-click-id="enter-tag-name"
            value={name}
            onValueChange={setName}
            helperText={TAG_NAME_VALIDATION_MESSAGE}
            errorMessage={
              name && !isNameValid ? TAG_NAME_VALIDATION_MESSAGE : undefined
            }
          />
          <TextArea
            label="Description"
            value={description}
            onValueChange={setDescription}
          />
        </FormWrapper>
      </ModalLayout>
    </ModalTemplate>
  );
};

export const editTagModal = ({ tag }: { tag: EditTagPayload }) => {
  modal<false>(resolve => (
    <TagEditor
      onSubmit={tagPlayoad => {
        dispatchAction(
          updateTag({
            ...tagPlayoad,
            _id: tag._id,
          })
        );
      }}
      headerText={`Edit ${tag.name}`}
      tag={tag}
      onClose={() => resolve(false)}
    />
  ));
};
export const createTagModal = () => {
  modal<false>(resolve => (
    <TagEditor
      headerText="Create tag"
      onSubmit={tagPlayoad => {
        dispatchAction(createTag(tagPlayoad));
      }}
      onClose={() => resolve(false)}
    />
  ));
};
