import { isArrayLike } from 'lodash';
import { pluralize } from '@ardoq/common-helpers';
import {
  ComponentMessage,
  ReferenceMessage,
} from 'components/Dialogs/confirmDeletion/ContentMessage';
import { bulkDeleteComponents } from 'services/modelService';
import { ArdoqId } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { batchApi, componentApi, scenarioApi } from '@ardoq/api';
import { resetGraph } from 'modelInterface/graphModelActions';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import { componentBulkDeleteCleanup } from 'sync/eventHandlers';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import { isArdoqError } from '@ardoq/common-helpers';

const { getShortName, removeFromCollection, destroyReference } =
  referenceInterface;
const { getDisplayName } = componentInterface;

export const deleteComponentsByIds = async (componentIds: ArdoqId[]) => {
  const data = await bulkDeleteComponents(componentIds);
  if (isArdoqError(data)) {
    return data;
  }

  if (data) {
    componentBulkDeleteCleanup(data);
  }
  return data;
};

export const deleteReferencesByIds = async (referenceIds: ArdoqId[]) => {
  if (referenceIds.length === 0) {
    return;
  }

  if (isScenarioMode()) {
    referenceIds.map(destroyReference);
  } else {
    const result = await batchApi.execute({
      references: { delete: referenceIds },
    });
    if (isArdoqError(result)) {
      return result;
    }
    if (referenceIds.some(referenceInterface.isReference)) {
      // clear the graphModel$ stream (this is sync)
      // this is called here and in modelDeleted() in sync/eventHandlers.ts
      // because we don't know what finishes first
      dispatchAction(resetGraph());
    }
    referenceIds.forEach(removeFromCollection);
  }
};

const isIterable = (obj: unknown): obj is Iterable<unknown> =>
  // @ts-expect-error whatever, TypeScript. this is just how it's done.
  Boolean(obj?.[Symbol.iterator]);

export const wrapInList = <T,>(items: T[] | T | undefined): T[] => {
  if (items instanceof Array) {
    return items;
  }
  if (isIterable(items) || isArrayLike(items)) {
    return Array.from(items) as T[];
  }
  if (typeof items !== 'undefined') {
    return [items as T] as T[];
  }
  return [];
};

export const getTitle = ({
  numOfComponents,
  numOfReferences,
}: {
  numOfComponents: number;
  numOfReferences: number;
}): string => {
  let title = 'Delete ';
  if (numOfComponents) {
    title += `${pluralize('component', numOfComponents)}`;
  }
  if (numOfComponents && numOfReferences) {
    title += ' and ';
  }
  if (numOfReferences) {
    title += `${pluralize('reference', numOfReferences)}`;
  }
  return title;
};

const getContent = ({
  componentIds,
  referenceIds,
  numOfReferences,
  numOfComponents,
  hasSubtreeInfo,
  componentNames,
}: {
  componentIds: ArdoqId[];
  referenceIds: ArdoqId[];
  numOfComponents: number;
  numOfReferences: number;
  hasSubtreeInfo: boolean;
  componentNames: string[] | undefined;
}) =>
  numOfComponents ? (
    <ComponentMessage
      componentNames={
        componentNames ?? componentIds.map(id => getDisplayName(id) || '')
      }
      numOfReferences={numOfReferences}
      numOfComponents={numOfComponents}
      hasSubtreeInfo={hasSubtreeInfo}
    />
  ) : (
    <ReferenceMessage
      referenceNames={referenceIds.map(id => getShortName(id) || '')}
    />
  );

export const getDialogConfigByIds = async (
  componentIds: ArdoqId[],
  referenceIds: ArdoqId[],
  componentNames?: string[]
) => {
  const {
    numComponents: numOfComponents,
    numIncidentReferences: numOfIncidentReferences,
    hasSubtreeInfo,
  } = componentIds.length > 0
    ? await getTotalCountsOfCompsAndRefs(componentIds)
    : {
        numComponents: 0,
        numIncidentReferences: 0,
        hasSubtreeInfo: false,
      };

  const numOfReferences = numOfIncidentReferences + referenceIds.length;

  return {
    title: getTitle({
      numOfComponents,
      numOfReferences,
    }),
    text: getContent({
      componentIds,
      referenceIds,
      numOfReferences,
      numOfComponents,
      hasSubtreeInfo,
      componentNames,
    }),
    confirmButtonClickId: 'confirm-delete-components-references',
  };
};

const getTotalCountsOfCompsAndRefs = async (componentIds: ArdoqId[]) => {
  const result = await getSubtreeInfoOfComponents(componentIds);
  if (isArdoqError(result)) {
    return {
      numComponents: componentIds.length,
      numIncidentReferences: 0,
      hasSubtreeInfo: false,
    };
  }
  return {
    ...result,
    hasSubtreeInfo: true,
  };
};

const getSubtreeInfoOfComponents = async (componentIds: ArdoqId[]) => {
  const scenarioId = getActiveScenarioId();
  if (!scenarioId) {
    return await componentApi.subtreeInfo(componentIds);
  }
  return await scenarioApi.subtreeInfo(scenarioId, componentIds);
};
