import { debounce } from 'lodash';
import { ArdoqId } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  notifyReferencesRemoved,
  notifyReferenceSourceOrTargetUpdated,
} from 'streams/references/ReferenceActions';
import { subscribeToAction } from 'streams/utils/streamUtils';
import { notifyWorkspaceAggregatedLoaded } from 'streams/workspaces/actions';
import { triggerFiltersChangedEvent } from 'streams/filters/FilterActions';
import { clearSimpleGraphModel } from './actions';

interface Reference extends Backbone.Model {
  id: ArdoqId;
  attributes: {
    source: ArdoqId;
    target: ArdoqId;
  };
}
const getReferenceAttributes = (reference: Reference) => ({
  sourceId: reference.get('source'),
  targetId: reference.get('target'),
});

/**
 * SimpleGraphModel is a graph model representation that keeps
 * mappings of references from component to components. The graph model is used
 * by filters.
 * @see `filter.js`
 * @member `incoming` Mapping of incoming references
 * @member `outgoing` Mapping of outgoing references
 */
let incoming: Map<ArdoqId, Set<ArdoqId>>;
let outgoing: Map<ArdoqId, Set<ArdoqId>>;

interface SimpleGraphModelDependencies {
  References: Backbone.Collection<Backbone.Model>;
}
/**
 * Initializes the SimpleGraphModel by registering event listeners that add and
 * remove entries from the model appropriately. The function adds listeners on
 * workspace loading, workspace closing and single reference creation and removal.
 * @param References Backbone references collection
 */
export const initializeModel = ({
  References,
}: SimpleGraphModelDependencies) => {
  incoming = new Map();
  outgoing = new Map();

  subscribeToAction(notifyWorkspaceAggregatedLoaded, () =>
    handleWorkspaceLoaded(References.toArray())
  );

  subscribeToAction(notifyReferencesRemoved, () =>
    rebuildGraphModel(References.toArray())
  );

  subscribeToAction(notifyReferenceSourceOrTargetUpdated, () =>
    handleReferenceSourceOrTargetUpdate(References.toArray())
  );

  References.on('reset', handledReferenceCollectionReset);
  References.on('add', handleAddedReference);

  /* Specific handling for presentation because it stores references on workspaces */
  subscribeToAction(clearSimpleGraphModel, clearGraphModel);
};

const handleWorkspaceLoaded = (referencesToAdd: Reference[]) =>
  referencesToAdd.forEach(reference => handleAddedReference(reference));

const handledReferenceCollectionReset = () => clearGraphModel();

const clearGraphModel = () => {
  incoming.clear();
  outgoing.clear();
};

const rebuildGraphModel = (referencesToAdd: Reference[]) => {
  clearGraphModel();
  referencesToAdd.forEach(reference => handleAddedReference(reference));
};

const handleReferenceSourceOrTargetUpdate = debounce(
  (referencesToAdd: Reference[]) => {
    rebuildGraphModel(referencesToAdd);
    dispatchAction(triggerFiltersChangedEvent());
  },
  100
);

const handleAddedReference = (reference: Reference) => {
  const { sourceId, targetId } = getReferenceAttributes(reference);

  let sources = incoming.get(targetId);
  if (!sources) {
    sources = new Set();
    incoming.set(targetId, sources);
  }
  sources.add(sourceId);

  let targets = outgoing.get(sourceId);
  if (!targets) {
    targets = new Set();
    outgoing.set(sourceId, targets);
  }
  targets.add(targetId);
};

export const sourceHasTarget = (sourceId: ArdoqId, targetId: ArdoqId) => {
  const targets = outgoing.get(sourceId);
  return targets ? targets.has(targetId) : false;
};

export const targetHasSource = (targetId: ArdoqId, sourceId: ArdoqId) => {
  const sources = incoming.get(targetId);
  return sources ? sources.has(sourceId) : false;
};
