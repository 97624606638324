import reducers from './reducers';
import { getFrameIdFromWindowContext } from './gridEditorUtils';
import { persistentReducedStream } from '@ardoq/rxbeach';
import { GridEditorState } from './types';
import { gridEditorStateOperations } from './gridEditorStateOperations';

const gridEditor$ = persistentReducedStream<GridEditorState>(
  'gridEditor2023$',
  gridEditorStateOperations.getDefaultGridEditorState(
    getFrameIdFromWindowContext(window)
  ),
  reducers
);

export default gridEditor$;
