import {
  createTag,
  deleteTag,
  deleteTags,
  editTag,
  filterTag,
  showAddTagDialog,
  tagComponents,
  untagComponent,
  untagReference,
  updateTag,
} from './TagActions';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap, switchMap } from 'rxjs/operators';
import Tags from 'collections/tags';
import Components from 'collections/components';
import References from 'collections/references';
import { ExcludeFalsy, getArdoqErrorMessage } from '@ardoq/common-helpers';
import { tagInterface } from 'modelInterface/tags/tagInterface';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import { confirmDeleteTag } from 'components/Dialogs/confirmDeletion/confirmDeletion';
import Filters from 'collections/filters';
import { createTagModal, editTagModal } from 'tabview/tagscape/tagEditor';
import { alert } from '@ardoq/modal';
import { logWarn } from '@ardoq/logging';
import { notifyWorkspacesDeleted } from '../workspaces/actions';
import { tagApi, handleError, api } from '@ardoq/api';
import {
  fetchTags,
  notifyFetchingTagsSucceeded,
  notifyFetchingTagsFailed,
} from './actions';
import { FilterTypes } from '@ardoq/api-types';

type TagCRUD = 'deleted' | 'created' | 'edited';
function localErrorHandler(
  operation: TagCRUD,
  name?: string,
  errorMsg?: string | undefined
): void {
  alert({
    title: 'Error',
    subtitle: `Tag${
      name ? ` "${name}"` : ''
    } was not successfully ${operation}.`,
    errorMessage: errorMsg || '',
  });
}

const handleDeleteTag = routine(
  ofType(deleteTag),
  extractPayload(),
  tap(async id => {
    const confirmed = await confirmDeleteTag({
      tagName: tagInterface.getName(id) ?? '',
      componentNames: tagInterface
        .getIdsOfComponentsWithTag(id)
        .map(componentInterface.getDisplayName)
        .filter(ExcludeFalsy),
      referenceNames: tagInterface
        .getIdsOfReferencesWithTag(id)
        .map(referenceInterface.getName)
        .filter(ExcludeFalsy),
    });
    if (confirmed) {
      tagInterface.deleteTag(id);
    }
  })
);

const handleEditTag = routine(
  ofType(editTag),
  extractPayload(),
  tap(id => {
    const tag = tagInterface.getTagData(id);
    if (tag) {
      editTagModal({ tag });
    }
  })
);

const handleFilterTag = routine(
  ofType(filterTag),
  extractPayload(),
  tap(({ id, exclude }) => {
    const tag = Tags.collection.get(id);
    if (!tag) {
      return null;
    }
    const tagName = tag.name();
    return Filters.createFilter(
      {
        isNegative: exclude,
        name: tagName,
        affectReference: true,
        affectComponent: true,
        type: FilterTypes.TAG,
        value: tagName,
      },
      { shouldTriggerChangeEvent: true }
    );
  })
);

const handleShowAddTagDialog = routine(
  ofType(showAddTagDialog),
  tap(() => createTagModal())
);

const handleDeleteTags = routine(
  ofType(deleteTags),
  extractPayload(),
  tap(({ cids }) => {
    cids.forEach(c => Tags.collection.get(c)!.destroy());
  })
);

const handleUntagComponent = routine(
  ofType(untagComponent),
  extractPayload(),
  tap(({ tagCid, componentCid }) => {
    const tag = Tags.collection.get(tagCid)!;
    const component = Components.collection.get(componentCid);
    if (!component) {
      logWarn(Error('attempted to untag a nonexistent component'), null, {
        componentCid,
      });
      return;
    }
    tag.remove(component);
    tag.save();
  })
);

const handleUntagReference = routine(
  ofType(untagReference),
  extractPayload(),
  tap(({ tagCid, referenceCid }) => {
    const tag = Tags.collection.get(tagCid)!;
    const reference = References.collection.get(referenceCid);
    if (!reference) {
      logWarn(Error('attempted to untag a nonexistent reference'), null, {
        referenceCid,
      });
      return;
    }
    tag.remove(reference);
    tag.save();
  })
);

const handleCreateTag = routine(
  ofType(createTag),
  extractPayload(),
  tap(({ name, description }) => {
    const newTag = Tags.collection.create(
      { name, description },
      { wait: true } // Since create is async hence, it is used here to avoid displaying wrong tag in the UI
    );
    // Handle the error using flag
    if (newTag && newTag.validationError) {
      localErrorHandler('created', name, newTag.validationError.name);
    }
  })
);

const handleUpdateTag = routine(
  ofType(updateTag),
  extractPayload(),
  tap(({ _id, name, description }) => {
    const tag = Tags.collection.get(_id)!;
    tag.set({ name, description });
    tag.save(undefined, {
      error: () => localErrorHandler('edited', tag.get('name')),
    });
  })
);

const handleTagComponents = routine(
  ofType(tagComponents),
  extractPayload(),
  tap(({ tagId, componentIds }) => {
    const tag = Tags.collection.get(tagId!)!;
    componentIds.forEach(id => {
      const component = Components.collection.get(id)!;
      tag.add(component);
    });
    tag.save();
  })
);

const handleWorkspacesDeleted = routine(
  ofType(notifyWorkspacesDeleted),
  extractPayload(),
  tap(({ workspaceIds }) => {
    workspaceIds.forEach(workspaceId => {
      tagInterface.removeWorkspaceTags(workspaceId);
    });
  })
);

const handleFetchTags = routine(
  ofType(fetchTags),
  switchMap(tagApi.fetchAll),
  handleError(error => {
    dispatchAction(
      notifyFetchingTagsFailed({
        error: getArdoqErrorMessage(error),
      })
    );
    api.logErrorIfNeeded(error);
  }),
  tap(response => {
    dispatchAction(notifyFetchingTagsSucceeded(response));
  })
);

export default collectRoutines(
  handleShowAddTagDialog,
  handleDeleteTags,
  handleUntagComponent,
  handleUntagReference,
  handleTagComponents,
  handleDeleteTag,
  handleEditTag,
  handleFilterTag,
  handleUpdateTag,
  handleCreateTag,
  handleWorkspacesDeleted,
  handleFetchTags
);
