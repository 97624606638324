import {
  APIAttachment,
  APIDocumentArchiveFolder,
  ArdoqId,
  DocumentArchiveRenamePayload,
  TransformToGlobalPayload,
  TransformToGlobalResponse,
  UploadFilesPayload,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import {
  DocumentArchiveContext,
  DocumentBrowserUIState,
  DocumentFolder,
  FilterByType,
} from './types';

export const createFolder = actionCreator<{
  name: string;
  type: 'org' | 'workspace';
  typeId: ArdoqId;
}>('[DocumentBrowser] CREATE_FOLDER');

export const uploadFiles = actionCreator<UploadFilesPayload>(
  '[DocumentBrowser] UPLOAD_FILES'
);

export const moveAttachmentsToFolder = actionCreator<{
  type: 'org' | 'workspace';
  typeId: ArdoqId;
  attachmentsWithNewFolder: APIAttachment[];
}>('[DocumentBrowser] MOVE_ATTACHMENTS_TO_FOLDER');

export const moveFoldersToFolder = actionCreator<{
  type: 'org' | 'workspace';
  typeId: ArdoqId;
  foldersWithNewParent: APIDocumentArchiveFolder[];
}>('[DocumentBrowser] MOVE_FOLDERS_TO_FOLDER');

export const setRenameFocus = actionCreator<ArdoqId | null>(
  '[DocumentBrowser] SET_DOCUMENT_RENAME_ID'
);

export const setPreviewId = actionCreator<ArdoqId | null>(
  '[DocumentBrowser] SET_PREVIEW_ID'
);

export const renameFolder = actionCreator<DocumentArchiveRenamePayload>(
  '[DocumentBrowser] RENAME_FOLDER'
);

export const renameAttachment = actionCreator<DocumentArchiveRenamePayload>(
  '[DocumentBrowser] RENAME_ATTACHMENT'
);

export const renameSuccess = actionCreator<ArdoqId>(
  '[DocumentBrowser] RENAME_SUCCESS'
);

export const deleteAttachments = actionCreator<ArdoqId[]>(
  '[DocumentBrowser] DELETE_ATTACHMENTS'
);

export const deleteFolders = actionCreator<DocumentFolder[]>(
  '[DocumentBrowser] DELETE_FOLDERS'
);

export const deleteEmptyFolder = actionCreator<ArdoqId>(
  '[DocumentBrowser] DELETE_EMPTY_FOLDERS'
);

export const deleteSuccess = actionCreator<ArdoqId[]>(
  '[DocumentBrowser] DELETE_SUCCESS'
);

export const setExpandedFoldersIds = actionCreator<ArdoqId[]>(
  '[DocumentBrowser] SET_EXPANDED_FOLDER_IDS'
);

export const setSelected = actionCreator<ArdoqId[]>(
  '[DocumentBrowser] SET_SELECTED'
);

export const setDocumentArchiveContext = actionCreator<DocumentArchiveContext>(
  '[DocumentBrowser] SET_DOCUMENT_ARCHIVE_CONTEXT'
);

export const setSearchPhrase = actionCreator<string>(
  '[DocumentBrowser] SET_SEARCH_PHRASE'
);

export const setFilterByType = actionCreator<FilterByType | null>(
  '[DocumentBrowser] SET_FILTER_BY_TYPE'
);

export const transferToGlobal = actionCreator<TransformToGlobalPayload>(
  '[DocumentBrowser] TRANSFER_TO_GLOBAL'
);

export const transferToGlobalSuccess = actionCreator<TransformToGlobalResponse>(
  '[DocumentBrowser] TRANSFER_TO_GLOBAL_SUCCESS'
);

export const setDocumentArchiveUIDefaultState = actionCreator<
  Partial<DocumentBrowserUIState> | undefined
>('[DocumentBrowser] SET_DOCUMENT_ARCHIVE_UI_DEFAULT_STATE');
