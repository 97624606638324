import { actionCreator } from '@ardoq/rxbeach';
import {
  DynamicFilterDefinition,
  DynamicFilterId,
  DynamicFilterIncludes,
  DynamicFilterState,
} from '@ardoq/data-model';

type DynamicFilterIdPayload = Readonly<DynamicFilterId>;
export type DynamicFilterIncludesPayload = Readonly<DynamicFilterIncludes>;
export type DynamicFilterParametersPayload = Readonly<DynamicFilterDefinition>;

export interface DynamicFilterErrorPayload extends Readonly<DynamicFilterId> {
  readonly error: string;
}

export const setDynamicFilterActive = actionCreator<DynamicFilterIdPayload>(
  '[dynamicFilters] SET_DYNAMIC_FILTER_ACTIVE'
);

export const setDynamicFilterInactive = actionCreator<DynamicFilterIdPayload>(
  '[dynamicFilters] SET_DYNAMIC_FILTER_INACTIVE'
);
export const setAllDynamicFiltersInactive = actionCreator(
  '[dynamicFilters] SET_ALL_FILTERS_INACTIVE'
);

export const setDynamicFilterLoading = actionCreator<DynamicFilterIdPayload>(
  '[dynamicFilters] SET_DYNAMIC_FILTER_LOADING'
);

export const requestActivateDynamicFilter =
  actionCreator<DynamicFilterIdPayload>(
    '[dynamicFilters] REQUEST_ACTIVATE_DYNAMIC_FILTER'
  );

export const setDynamicFilterParameters =
  actionCreator<DynamicFilterParametersPayload>(
    '[dynamicFilters] SET_DYNAMIC_FILTER_PARAMETERS'
  );

export const setDynamicFilterIncludes =
  actionCreator<DynamicFilterIncludesPayload>(
    '[dynamicFilters] SET_DYNAMIC_FILTER_INCLUDES'
  );

export const dynamicFilterError = actionCreator<DynamicFilterErrorPayload>(
  '[dynamicFilters] DYNAMIC_FILTER_ERROR'
);

export type ReplaceDynamicFiltersPayload = DynamicFilterState[];
export const replaceDynamicFilters =
  actionCreator<ReplaceDynamicFiltersPayload>(
    '[dynamicFilters] REPLACE_DYNAMIC_FILTERS'
  );
