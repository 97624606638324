import { persistentReducedStream } from '@ardoq/rxbeach';
import { EntityBrowserAtomsStreamState } from './types';
import { reducers } from './reducers';

const defaultState: EntityBrowserAtomsStreamState = {
  surveyWorkspaceAccessRequestLoading: false,
  surveyWorkspaceAccessRequestSent: false,
};

const entityBrowserAtoms$ = persistentReducedStream(
  'entityBrowserAtom$',
  defaultState,
  reducers
);

export default entityBrowserAtoms$;
