import { useState } from 'react';
import { Left, Right } from './atoms';
import { GroupEntryRow, SingleEntryRow } from './EntryRow';
import {
  getEntryValues,
  getIntersectionOfReferenceOptions,
  isGroupEntryProps,
} from './helpers';
import { ConvertAllSelectionRow } from './ConvertAllSelectionRow';
import { Entry } from './types';
import { ButtonGroup, PrimaryButton, SecondaryButton } from '@ardoq/button';
import { Paragraph } from '@ardoq/typography';
import { Select } from '@ardoq/select';
import { ModalSize, ModalTemplate, ModalText } from '@ardoq/modal';
import { FlexBox, Stack } from '@ardoq/layout';

type ReverseReferenceDialogContentProps = {
  resolve: (resultValue: any | false) => void;
  entries: Entry[];
};

const ReverseReferenceDialogContent = ({
  resolve,
  entries,
}: ReverseReferenceDialogContentProps) => {
  const initialArray = getEntryValues(entries);
  const intersectReferenceOptions = getIntersectionOfReferenceOptions(entries);
  const [entryValues, setEntryValue] = useState({ ...initialArray });

  const setEntryRowValue = (key: number, newTypeValue: number) => {
    setEntryValue({
      ...entryValues,
      [key]: { ...entryValues[key], typeValue: newTypeValue },
    });
  };

  const setAllEntryRows = (label: string) => {
    const updatedEntryValues = getEntryValues(entries, label);
    setEntryValue({ ...updatedEntryValues });
  };

  const handleCancel = () => resolve(false);
  const handleConfirm = () => resolve(entryValues);

  return (
    <ModalTemplate
      headerText="Change reference type"
      footerContent={
        <ButtonGroup>
          <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
          <PrimaryButton onClick={handleConfirm}>Confirm</PrimaryButton>
        </ButtonGroup>
      }
      modalSize={ModalSize.S}
      onCloseButtonClick={handleCancel}
    >
      <ModalText>
        <Stack gap="medium">
          <Paragraph>
            You are reversing reference(s) between two workspaces that do not
            have the same reference types. Please select a new reference type
            for the grouped references between the two workspaces, or keep it
            unselected to keep as its original value:
          </Paragraph>

          {entries.length > 2 && (
            <ConvertAllSelectionRow
              intersectReferenceOptions={intersectReferenceOptions}
              setAllEntryRows={setAllEntryRows}
            />
          )}

          {entries.map((entry: Entry, index: number) => (
            <FlexBox key={index}>
              <Left>
                {isGroupEntryProps(entry.props) ? (
                  <GroupEntryRow {...entry.props} />
                ) : (
                  <SingleEntryRow {...entry.props} />
                )}
              </Left>
              <Right>
                <Select
                  options={entry.validOptions}
                  backspaceRemovesValue={false}
                  isClearable={false}
                  value={entry.validOptions.find(
                    ({ value }) => value === entryValues[index].typeValue
                  )}
                  onChange={({ value }: any) => setEntryRowValue(index, value)}
                />
              </Right>
            </FlexBox>
          ))}
        </Stack>
      </ModalText>
    </ModalTemplate>
  );
};

export default ReverseReferenceDialogContent;
