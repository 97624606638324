import Backbone from 'backbone';

type BackboneCollectionType<TModel extends Backbone.Model> =
  Backbone.Collection<TModel> & {
    new (
      models?: TModel[] | Record<string, any>[],
      options?: any
    ): BackboneCollectionType<TModel>;
  };
const BaseBackboneCollection = <TModel extends Backbone.Model = Backbone.Model>(
  properties: any,
  classProperties?: any
): BackboneCollectionType<TModel> =>
  Backbone.Collection.extend(properties, classProperties);
export default BaseBackboneCollection;
