import { ButtonType } from '@ardoq/button';
import styled from 'styled-components';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { ButtonWithDropdown, DropdownOption } from '@ardoq/dropdown-menu';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';

// CUSTOM HACK
// It's mimicking the clickable icon
// that was used on asset manager
// before the dropdown menu replacement
const MenuWrapper = styled.div`
  && {
    b {
      font-size: 18px !important;
      line-height: 24px;
    }
  }
`;

type EntityBrowserActionsMenuProps = {
  menuOptions: DropdownOption[];
  onTrackClick?: VoidFunction;
};

const EntityBrowserActionsMenu = ({
  menuOptions,
  onTrackClick,
}: EntityBrowserActionsMenuProps) =>
  menuOptions.length ? (
    <MenuWrapper>
      <ButtonWithDropdown
        dropdownSize={DropdownSize.S}
        alignMenuTo="right"
        options={menuOptions}
        hideChevron
        onClick={onTrackClick}
        buttonType={ButtonType.GHOST}
      >
        <Icon
          iconName={IconName.MORE_VERT}
          iconSize={IconSize.SMALL}
          aria-controls="asset-menu"
        />
      </ButtonWithDropdown>
    </MenuWrapper>
  ) : null;

export default EntityBrowserActionsMenu;
