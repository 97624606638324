import Backbone from 'backbone';
import { getIn } from 'utils/collectionUtil';
import type { PerspectiveBackboneModel } from 'aqTypes';

type PerspectiveClass = PerspectiveBackboneModel & {
  new (
    attributes: Record<string, any>,
    options: Record<string, any>
  ): PerspectiveBackboneModel;
};
const Perspective: PerspectiveClass = Backbone.Model.extend({
  idAttribute: '_id',
  defaults: {
    name: undefined,
    description: undefined,
    workspace: undefined,
  },
  hasContent: function (this: Backbone.Model): boolean {
    const advancedFilters = getIn(this.attributes, [
      'filters',
      'advancedFilters',
      'length',
    ]);
    const workspaceFilter = getIn(this.attributes, [
      'filters',
      'workspaceFilter',
    ]);
    const hasSomeFilter = Boolean(advancedFilters || workspaceFilter);
    const hasSomeGroupBy = getIn(this.attributes, ['groupBys', 'length']);

    return hasSomeFilter || hasSomeGroupBy;
  },
});

export default Perspective;
