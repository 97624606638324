import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { pluralize } from '@ardoq/common-helpers';
import { WarningNotification } from '@ardoq/status-ui';

const DivWithMargin = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ComponentMessage: FunctionComponent<{
  componentNames: string[];
  numOfComponents: number;
  numOfReferences: number;
  hasSubtreeInfo: boolean;
}> = ({ componentNames, numOfComponents, numOfReferences, hasSubtreeInfo }) => {
  const compNoun = pluralize('component', numOfComponents);
  const refNoun = pluralize('reference', numOfReferences);
  return (
    <>
      <WarningNotification style={{ marginBottom: 8 }}>
        {hasSubtreeInfo
          ? `In total this will delete ${numOfComponents} ${compNoun} and ${numOfReferences} ${refNoun}.`
          : 'The total of deleted components and references is unknown'}
      </WarningNotification>

      <div>
        The following {compNoun}, the children and references <br /> to and from
        {numOfComponents > 1 ? ' them ' : ' it '}
        will be deleted:
        <DivWithMargin>
          <ul>
            {componentNames.map((name, index) => (
              <li key={name + index}>{name}</li>
            ))}
          </ul>
        </DivWithMargin>
      </div>
    </>
  );
};

export const ReferenceMessage: FunctionComponent<{
  referenceNames: string[];
  additionally?: boolean;
}> = ({ referenceNames, additionally = false }) => (
  <div>
    <span>
      {`${additionally ? 'Additionally, the' : 'The'}
      following ${pluralize(
        'reference',
        referenceNames.length
      )} will be deleted:`}
    </span>
    <ul>
      {referenceNames.map((name, index) => (
        <li key={name + index}>{name}</li>
      ))}
    </ul>
  </div>
);
