import { dispatchAction } from '@ardoq/rxbeach';
import {
  notifyGroupingAdded,
  notifyGroupingRemoved,
  notifyGroupingSynced,
  notifyGroupingUpdated,
} from './GroupingActions';
import { GroupByBackboneModel, GroupByBackboneModelCollection } from 'aqTypes';

export default (Groupings: GroupByBackboneModelCollection) => {
  Groupings.on('remove', (grouping: GroupByBackboneModel) => {
    dispatchAction(notifyGroupingRemoved({ grouping }));
  });
  Groupings.on('change', (grouping: GroupByBackboneModel) => {
    dispatchAction(notifyGroupingUpdated({ grouping }));
  });
  Groupings.on('add', (grouping: GroupByBackboneModel) => {
    dispatchAction(notifyGroupingAdded({ grouping }));
  });
  Groupings.on('sync', (grouping: GroupByBackboneModel) => {
    dispatchAction(notifyGroupingSynced({ grouping }));
  });
};
