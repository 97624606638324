import * as React from 'react';
import { get, throttle } from 'lodash';
import { clearSelection } from '@ardoq/common-helpers';
import { Row, RowItem } from 'components/EntityBrowser/types';
import { type Point, geometry } from '@ardoq/math';
import { Highlight } from 'utils/withHighlightStatusMap';
import { logError } from '@ardoq/logging';
import { ArdoqId } from '@ardoq/api-types';
import { generateExpandFolder, RowType } from '@ardoq/table';
import type { AssetsBrowserProps } from '../../components/AssetsBrowser/AssetsBrowser';

const MOUSE_MOVE_EVENT_THROTTLE = 5;

type DndDecoratorProps<T extends RowItem> = Pick<
  AssetsBrowserProps,
  | 'folderIds'
  | 'renameCancel'
  | 'selected'
  | 'setSelected'
  | 'tableHeight'
  | 'getCustomColumns'
  | 'onAssetClick'
  | 'onAssetPreviewClick'
  | 'onAssetOpenInViewpointModeClick'
  | 'onSortParamsChange'
  | 'initialSortParams'
  | 'expandedFoldersIds'
  | 'setExpandedFoldersIds'
  | 'searchPhrase'
  | 'dataSource'
  | 'scrollableSectionHeight'
  | 'skipDefaultSortOrder'
  | 'style'
> & {
  dndDisabled?: boolean;
  previewId?: ArdoqId | null;
  onAssetsMove: (workspacesIds: ArdoqId[], folderId: ArdoqId | null) => void;
  setHighlights: (ids: ArdoqId[]) => void;
  highlightStatusMap: { [id: string]: Highlight };
  renameConfirm: (newName: string, row: Row<T>) => void;
  renameId: string | null;
  onTableClick?: (
    e: React.MouseEvent<HTMLTableElement, MouseEvent>,
    trElement: Row<T> | null
  ) => void;
};
export type DndDecoratorState = {
  isDragging: boolean;
  isMouseDown: boolean;
  isUpdating: boolean;
  draggedRowType: RowType | null;
  dragStartedOnId: null | string;
  dragStartedOnFolderId: null | string;
  hoverId: null | string;
  overFolderId: null | string;
  draggedIds: string[];
  initalMousePosition: Point | null;
  currentMousePosition: Point | null;
};
type TrDataSetProps = {
  rowId: null | string;
  rowType: null | RowType;
  closestFolderId: null | string;
  isDragged: null | string;
  isDraggable: null | string;
};
type EventData = {
  rowId: null | string;
  rowType: null | RowType;
  closestFolderId: null | string;
  isDragged: boolean;
  isDraggable: boolean;
  rootLevelDrop: boolean;
};

const getPositionPointFromEvent = (ev: MouseEvent): Point => [
  ev.clientX,
  ev.clientY,
];

const getDataFromEvent = (ev: MouseEvent): EventData => {
  // FF BUGFIX:
  // Mozilla returns wrong event.target onMouseMove if mouse button
  // is aready down, that's why I am picking taget based on mouse x,y
  const ffEventTarget = navigator.userAgent.includes('Firefox')
    ? document.elementFromPoint(ev.clientX, ev.clientY)
    : null;
  const eventTarget = ffEventTarget || ev.target;
  const trEl =
    eventTarget instanceof Element ? eventTarget.closest('tr') : null;

  const isRootLevelDrop = Boolean(
    eventTarget && get(eventTarget, 'dataset.rootLevel', false)
  );

  if (isRootLevelDrop) {
    return {
      rowId: null,
      rowType: null,
      closestFolderId: null,
      rootLevelDrop: true,
      isDragged: false,
      isDraggable: false,
    };
  }

  const dataset = trEl ? (trEl.dataset as TrDataSetProps) : null;

  if (!dataset)
    return {
      rowId: null,
      rowType: null,
      closestFolderId: null,
      rootLevelDrop: false,
      isDragged: false,
      isDraggable: false,
    };

  return {
    ...dataset,
    // boolean values over data attributes becomes strings,
    // so this is simple type cast back to boolean
    isDragged: dataset.isDragged === 'true',
    isDraggable: dataset.isDraggable === 'true',
    rootLevelDrop: false,
  };
};

const isDraggableRowType = (rowType: RowType | null): boolean =>
  rowType === RowType.FOLDER ||
  rowType === RowType.TRAVERSAL ||
  rowType === RowType.BOOKMARK ||
  rowType === RowType.WORKSPACE ||
  rowType === RowType.MANAGED_WORKSPACE ||
  rowType === RowType.SURVEY ||
  rowType === RowType.SCENARIO ||
  rowType === RowType.METAMODEL ||
  rowType === RowType.PRESENTATION ||
  rowType === RowType.REPORT ||
  rowType === RowType.DASHBOARD ||
  rowType === RowType.DOCUMENT ||
  rowType === RowType.IMAGE ||
  rowType === RowType.UNKOWN_FILE ||
  rowType === RowType.VIEWPOINT ||
  rowType === RowType.BROADCAST;

// @ts-expect-error leaving WrappedComponent typed as any, to avoid issues elsewhere
const withDndRows = WrappedComponent => {
  const defaultState: DndDecoratorState = {
    isUpdating: false,
    isDragging: false,
    isMouseDown: false,
    draggedRowType: null,
    dragStartedOnId: null,
    dragStartedOnFolderId: null,
    hoverId: null,
    overFolderId: null,
    draggedIds: [],
    initalMousePosition: null,
    currentMousePosition: null,
  };

  return class DndDecorator<T extends RowItem> extends React.Component<
    DndDecoratorProps<T>,
    DndDecoratorState
  > {
    state: DndDecoratorState = defaultState;
    expandFolderTimer: number | null = null;

    _mouseMoveListener = throttle(
      ev => {
        const { initalMousePosition, isDragging, hoverId } = this.state;
        if (initalMousePosition) {
          const currentMousePosition = getPositionPointFromEvent(ev);
          const { rowId, closestFolderId } = getDataFromEvent(ev);

          const isOverFolder = closestFolderId && rowId === closestFolderId;
          const isHoverIdChanged = rowId !== hoverId;

          if (isHoverIdChanged) this.clearExpandFolderTimer();
          if (isHoverIdChanged && isOverFolder && closestFolderId) {
            this.setExpandFolderTimer(closestFolderId);
          }

          this.setState({
            hoverId: rowId,
            overFolderId: closestFolderId,
            currentMousePosition,
          });
          if (
            !isDragging &&
            !this.props.renameId &&
            geometry.distanceTwoArgs(
              currentMousePosition,
              initalMousePosition
            ) > 5
          ) {
            this._onDragStart();
          }
        }
      },
      MOUSE_MOVE_EVENT_THROTTLE,
      { leading: false }
    );

    expandFolder = (folderId: string, shouldExpand = true) => {
      const { expandedFoldersIds = [], setExpandedFoldersIds = () => {} } =
        this.props;
      // simple check for perfomance purposes
      if (shouldExpand && expandedFoldersIds.includes(folderId)) return;
      if (!shouldExpand && !expandedFoldersIds.includes(folderId)) return;
      generateExpandFolder(expandedFoldersIds, setExpandedFoldersIds)(
        folderId,
        shouldExpand
      );
    };

    clearExpandFolderTimer = () => {
      if (this.expandFolderTimer) {
        clearTimeout(this.expandFolderTimer);
        this.expandFolderTimer = null;
      }
    };

    setExpandFolderTimer = (folderId: string) => {
      this.expandFolderTimer = window.setTimeout(
        () => this.expandFolder(folderId),
        500
      );
    };

    _escPress = (keyPressEvent: KeyboardEvent) => {
      if (keyPressEvent.key !== 'Escape') return;
      this.setState({ ...defaultState });
      clearSelection();
      document.removeEventListener('keydown', this._escPress);
      document.removeEventListener('mousemove', this._mouseMoveListener);
      document.removeEventListener('mouseup', this._mouseUpListener);
    };

    _onMouseDown = (ev: any) => {
      if (this.props.dndDisabled) return;
      const { rowType, rowId, closestFolderId, isDraggable } =
        getDataFromEvent(ev);
      if (!isDraggable) return;
      if (!isDraggableRowType(rowType)) return;

      this.setState({
        isMouseDown: true,
        draggedRowType: rowType,
        dragStartedOnId: rowId,
        dragStartedOnFolderId: closestFolderId,
        initalMousePosition: getPositionPointFromEvent(ev),
      });
      document.addEventListener('mousemove', this._mouseMoveListener);
      document.addEventListener('mouseup', this._mouseUpListener);
    };

    _mouseUpListener = async (ev: any) => {
      ev.preventDefault();
      this.clearExpandFolderTimer();

      const { rootLevelDrop, isDragged } = getDataFromEvent(ev);
      const { overFolderId, draggedIds, isDragging } = this.state;
      if (isDragging) {
        clearSelection();
        document.removeEventListener('keydown', this._escPress);
      }

      if (isDragged) {
        this.setState(defaultState);
        return;
      }

      try {
        if ((overFolderId || rootLevelDrop) && draggedIds.length > 0) {
          this.setState({ ...defaultState, isUpdating: true });
          await this.props.onAssetsMove(draggedIds, overFolderId);
          this.props.setSelected([]);
          this.props.setHighlights(draggedIds);
          if (overFolderId) {
            this.expandFolder(overFolderId, true);
            this.props.setHighlights([overFolderId]);
          }
        }
      } catch (error) {
        logError(error as Error, 'Moving items to folder failed');
      }
      document.removeEventListener('mousemove', this._mouseMoveListener);
      document.removeEventListener('mouseup', this._mouseUpListener);
      this.setState({ ...defaultState });
    };

    _onDragStart = () => {
      const dragStartedOnId = this.state.dragStartedOnId as string;

      const draggedIds = this.props.selected.includes(dragStartedOnId)
        ? this.props.selected
        : [dragStartedOnId];

      this.setState({
        isDragging: true,
        draggedIds,
      });
      document.addEventListener('keydown', this._escPress);
    };

    componentDidMount() {
      document.addEventListener('mousedown', this._onMouseDown);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this._onMouseDown);
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          isUpdating={this.state.isUpdating}
        />
      );
    }
  };
};

export default withDndRows;
