import { APISurveyResponseLog, ArdoqId, UpdateType } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

export const fetchResponseLogData = actionCreator<{
  surveyId: ArdoqId;
  updateType: UpdateType;
}>('[surveys] GET_RESPONSE_LOG_DATA');

export const notifyFetchedResponseLogData = actionCreator<{
  responseLog: APISurveyResponseLog[];
  surveyId: ArdoqId;
}>('[surveys] SET_RESPONSE_LOG_DATA');

export const notifyFailedToFetchResponseLogData = actionCreator<{
  message: string;
}>('[surveys] SET_RESPONSE_LOG_DATA_FAILURE');
