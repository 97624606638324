import {
  APIAttachment,
  APIDocumentArchiveFolder,
  ArdoqId,
} from '@ardoq/api-types';
import Backbone from 'backbone';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { contextInterface } from 'modelInterface/contextInterface';
import { attachmentApi, folderApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { DocumentArchiveContext } from 'components/DocumentBrowser/types';

const DocumentArchiveModel = Backbone.Model.extend({
  idAttribute: '_id',
});

const DocumentArchiveAttachmentModel = DocumentArchiveModel.extend({
  url: function () {
    switch (this.get('context')) {
      case DocumentArchiveContext.ORG:
        return this.getOrgUrl();
      case DocumentArchiveContext.WS:
        return this.getWsUrl();
      default:
        throw new Error('Unknown DocumentArchiveContext');
    }
  },
  getOrgUrl: function () {
    return attachmentApi.getItemFromOrgUrl(
      currentUserInterface.getCurrentOrgId(),
      this.id || ''
    );
  },
  getWsUrl: function () {
    return attachmentApi.getItemFromWorkspaceUrl(
      contextInterface.getCurrentWsId()!,
      this.id || ''
    );
  },
});

export const DocumentArchiveFolderModel = DocumentArchiveModel.extend({
  defaults: {
    name: '',
    description: '',
    parent: null,
  },
  url: function () {
    switch (this.get('context')) {
      case DocumentArchiveContext.ORG:
        return this.getOrgUrl();
      case DocumentArchiveContext.WS:
        return this.getWsUrl();
      default:
        throw new Error('Unknown DocumentArchiveContext');
    }
  },
  getOrgUrl: function () {
    return folderApi.getItemFromOrgUrl(
      currentUserInterface.getCurrentOrgId(),
      this.id || ''
    );
  },
  getWsUrl: function () {
    return folderApi.getItemFromWorkspaceUrl(
      contextInterface.getCurrentWsId()!,
      this.id || ''
    );
  },
});

const DocumentArchiveCollection = Backbone.Collection.extend({
  initialize: function () {
    this.on('add', this.modelAdded);
  },

  getContext: function (model: Backbone.Model) {
    const context = model.get('context');

    if (context) {
      return context;
    }
    // If the function gets triggered as a result of a websocket even, the model
    // does not have a context, and we have to check if it has a rootWorkspace to find the correct context

    const rootWorkspace = model.get('rootWorkspace');

    if (!rootWorkspace) {
      return DocumentArchiveContext.ORG;
    }

    if (rootWorkspace === contextInterface.getCurrentWsId()) {
      return DocumentArchiveContext.WS;
    }
  },

  // We need to set context manually as it's purely frontend field
  modelAdded: function (model: Backbone.Model) {
    const context = this.getContext(model);
    model.set('context', context);
  },

  // Overriding fetch
  fetch: function () {
    throw new Error(
      "Don't use fetch() method. Use fetchOrg() and fetchWs(wsId) methods instead"
    );
  },
  url: function () {
    throw new Error('I should never be here');
  },

  _addContext: function (
    data: APIDocumentArchiveFolder[] | APIAttachment[],
    context: DocumentArchiveContext
  ) {
    this.add(
      data.map(itemData => ({
        ...itemData,
        context,
      })),
      { silent: true }
    );
    this.trigger('sync');
  },

  _removeWsModels: function () {
    this.remove(
      this.filter(
        (model: Backbone.Model) =>
          model.get('context') === DocumentArchiveContext.WS
      ),
      { silent: true }
    );
  },

  _postFetch: function (
    id: ArdoqId,
    data: APIDocumentArchiveFolder[] | APIAttachment[]
  ) {
    // Why this check here?
    // Because during the Ajax request user could select another WS,
    // so reulsts form the previous request should be not applied
    if (this.wsId !== id) return;
    this._removeWsModels();
    this._addContext(data, DocumentArchiveContext.WS);
  },
});

const DocumentArchiveFolders = DocumentArchiveCollection.extend({
  model: DocumentArchiveFolderModel,
  fetchOrg: async function () {
    if (this.orgDataRequested) return;
    this.orgDataRequested = true;
    const folders = await folderApi.fetchOrgFolders(
      currentUserInterface.getCurrentOrgId()
    );
    if (isArdoqError(folders)) {
      return folders;
    }
    this._addContext(folders, DocumentArchiveContext.ORG);
  },
  fetchWs: async function (wsId: ArdoqId) {
    this.wsId = wsId;
    const folders = await folderApi.fetchWorkspaceFolders(
      contextInterface.getCurrentWsId()!
    );
    if (isArdoqError(folders)) {
      return folders;
    }
    return this._postFetch(wsId, folders);
  },
  getOrgUrl: function () {
    return folderApi.getOrgUrl(currentUserInterface.getCurrentOrgId());
  },
  getWsUrl: function () {
    return folderApi.getWorkspaceUrl(this.wsId);
  },
});

const DocumentArchiveAttachments = DocumentArchiveCollection.extend({
  model: DocumentArchiveAttachmentModel,
  fetchOrg: async function () {
    if (this.orgDataRequested) return;
    this.orgDataRequested = true;
    const attachments = await attachmentApi.getOrgAttachments(
      currentUserInterface.getCurrentOrgId()
    );
    if (isArdoqError(attachments)) {
      return attachments;
    }

    return this._addContext(attachments, DocumentArchiveContext.ORG);
  },
  fetchWs: async function (wsId: ArdoqId) {
    this.wsId = wsId;
    const attachments = await attachmentApi.getWorkspaceAttachments(wsId);
    if (isArdoqError(attachments)) {
      return attachments;
    }
    return this._postFetch(wsId, attachments);
  },
  getWsUrl: function (workspaceId: ArdoqId | null) {
    return attachmentApi.getWorkspaceUrl(workspaceId || this.wsId);
  },
  getOrgUrl: function () {
    return attachmentApi.getOrgUrl(currentUserInterface.getCurrentOrgId());
  },
  getWsId: function () {
    return this.wsId;
  },
});

export const documentArchiveFolders = new DocumentArchiveFolders();

export const documentArchiveAttachments = new DocumentArchiveAttachments();
