import { dispatchAction } from '@ardoq/rxbeach';
import { closeScenario, loadDiff, openScenario } from './actions';
import { ArdoqId } from '@ardoq/api-types';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import {
  getLoadedScenarioData,
  getScenarioRelatedGraph,
} from 'loadedScenarioData';
import Components from 'collections/components';
import References from 'collections/references';
import { DIFF_TYPE } from '@ardoq/global-consts';
import { showScenarioRelatedComponents } from 'scenarioRelated/actions';
import { CollectionView } from 'collections/consts';

// Only temporary to test the api

const testScenario = {
  openScenario: (scenarioId: ArdoqId) =>
    dispatchAction(openScenario({ scenarioId, trackingClickSource: 'test' })),

  closeScenario: () => dispatchAction(closeScenario()),

  getActiveScenarioId,
  getLoadedScenarioData,
  getScenarioRelatedGraph,

  showScenarioRelated: (componentIds: ArdoqId[]) =>
    dispatchAction(showScenarioRelatedComponents({ componentIds })),

  getScopeDiff: () =>
    dispatchAction(
      loadDiff({
        scenarioId: getActiveScenarioId(),
      })
    ),
  clearAllTransparentFlags: () => {
    [
      ...Components.collection.views.get(CollectionView.BASE_VIEW)!.toArray(),
      ...References.collection.views.get(CollectionView.BASE_VIEW)!.toArray(),
    ].forEach(model => model.set({ [DIFF_TYPE]: null }));
  },
};

export default testScenario;
