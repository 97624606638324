import styled from 'styled-components';

export const Left = styled.div``;

export const Right = styled.div`
  width: 150px;
  margin: auto 0 auto auto;
`;

export const Text = styled.div`
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Label = styled.div`
  font-weight: bold;
  width: 150px;
  float: left;
`;
