import { returnPayload } from '@ardoq/common-helpers';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { setExternallyManagedNoticeSuppressed } from 'externallyManaged/actions';
import { PayloadIsSuppressed } from 'externallyManaged/types';

const handleSetSuppressed = reducer(
  setExternallyManagedNoticeSuppressed,
  returnPayload<PayloadIsSuppressed>
);

const defaultState: PayloadIsSuppressed = { isSuppressed: false };
const reducers = [handleSetSuppressed];

const externallyManagedNotice$ = persistentReducedStream<PayloadIsSuppressed>(
  'externallyManagedNotice$',
  defaultState,
  reducers
);

export default externallyManagedNotice$;
