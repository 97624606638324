import { PerspectiveBackboneModel, PerspectiveFilters } from 'aqTypes';
import Filters from 'collections/filters';
import GroupByCollection from 'collections/groupByCollection';
import DynamicFilters from 'filters/dynamicFilters';
import Filter from 'models/filter';
import GroupBy from 'models/groupBy';
import { GroupByAttributes } from 'models/types';
import { FilterTypes } from '@ardoq/api-types';

export const getModifiersAsQueryString = (
  attributeFilters = Filters.getAttributeFilters(),
  tagFilters = Filters.getTagFilters(),
  dateRangeFilters = Filters.getDateRangeFilters(),
  groupBys = GroupByCollection.getAll(),
  compLabelFilter = Filters.getCompLabelFilter(),
  refLabelFilter = Filters.getRefLabelFilter(),
  compLabelMultiSelectFilter = Filters.getCompMultiLabelFilter(),
  refLabelMultiSelectFilter = Filters.getRefMultiLabelFilter(),
  dynamicFilters = DynamicFilters.getDynamicFiltersAsEncodables()
) =>
  [
    ...attributeFilters,
    ...tagFilters,
    ...dateRangeFilters,
    ...groupBys,
    ...compLabelFilter,
    ...refLabelFilter,
    ...compLabelMultiSelectFilter,
    ...refLabelMultiSelectFilter,
    ...dynamicFilters,
  ]
    .map(encodable => encodable.encodeURI())
    .join('');

export const getPerspectiveModifiersAsQueryString = (
  perspective: PerspectiveBackboneModel
) => {
  const filters: PerspectiveFilters = perspective.get('filters');
  const [
    attributeFilters,
    tagFilters,
    dateRangeFilters,
    componentLabelFilter,
    referenceLabelFilter,
  ] = [
    FilterTypes.ATTRIBUTE,
    FilterTypes.TAG,
    FilterTypes.DATE_RANGE,
    FilterTypes.COMPONENT_LABEL,
    FilterTypes.REFERENCE_LABEL,
  ].map(filterType =>
    filters.advancedFilters
      .filter(({ type }) => type === filterType)
      .map(filterAttributes => new Filter(filterAttributes))
  );

  const groupBys: GroupByAttributes[] = perspective.get('groupBys');
  const groupByModels = groupBys.map(
    groupByAttributes => new GroupBy.model(groupByAttributes)
  );
  return getModifiersAsQueryString(
    attributeFilters,
    tagFilters,
    dateRangeFilters,
    groupByModels,
    componentLabelFilter,
    referenceLabelFilter,
    []
  );
};
