import { documentArchiveFolders } from 'collections/documentArchive';
import { DocumentArchiveContext } from 'components/DocumentBrowser/types';
import { APIFolderAttributes } from '@ardoq/api-types';
import { createStreamFromBackboneCollection } from 'streams/utils/streamUtils';
import { map } from 'rxjs';
import { mapValues } from 'lodash';

const documentArchiveFolders$ = createStreamFromBackboneCollection<
  APIFolderAttributes & { context: DocumentArchiveContext }
>(documentArchiveFolders, [
  'description',
  'lastUpdated',
  '_id',
  'parent',
  'lastModifiedByName',
  'name',
  'createdByEmail',
  'created',
  'createdBy',
  'lastModifiedBy',
  'resource',
  'lastModifiedByEmail',
  '_version',
  'ardoq',
  'createdByName',
  'context',
]).pipe(
  map(state => ({
    ...state,
    //
    // The map below is a temporal fix for document archive.
    // Under certain condition it is possible that
    // folder._id is equal to folder.parent
    // which technically means folder is located in itself
    // Obviously this doesn't make sense.
    //
    // The change blow fix that problem if API deliverers
    // corrupted data. Following task is to ensure
    // API won't be able to create such case anymore.
    //
    // https://ardoqcom.atlassian.net/browse/ARD-14194
    //
    byId: mapValues(state.byId, folderData => {
      if (folderData._id === folderData.parent) {
        return { ...folderData, parent: null };
      }
      return folderData;
    }),
  }))
);

export default documentArchiveFolders$;
