import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  combineLatestWith,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isEmpty, isEqual } from 'lodash';
import parameterOptions$ from 'streams/queries/parameterOptions/parameterOptions$';
import { fetchParameterOptions } from 'streams/queries/parameterOptions/actions';
import {
  excludeNullAndUndefined,
  tapEach,
} from 'streams/utils/streamOperators';
import {
  dynamicFilterError,
  requestActivateDynamicFilter,
  setAllDynamicFiltersInactive,
  setDynamicFilterActive,
  setDynamicFilterInactive,
  setDynamicFilterIncludes,
  setDynamicFilterLoading,
  setDynamicFilterParameters,
} from './actions';
import dynamicFilterStates$ from './dynamicFilterStates$';
import dynamicFilters$ from './dynamicFilters$';
import { merge } from 'rxjs';
import { DynamicFilterSelectState } from '@ardoq/data-model';
import {
  notifyComponentsAdded,
  notifyComponentsUpdated,
} from '../components/ComponentActions';
import {
  notifyReferencesAdded,
  notifyReferencesUpdated,
} from '../references/ReferenceActions';
import { trackDynamicFilterReFetch } from './tracking';
import { Features, hasFeature } from '@ardoq/features';
import { alert } from '@ardoq/modal';
import { logError } from '@ardoq/logging';
import { api, dynamicFilterApi } from '@ardoq/api';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';

const fetchIncludedIds = async (
  id: string,
  params: { [param: string]: string }
) => {
  const result = await dynamicFilterApi.runDynamicFilter(id, params);
  if (isArdoqError(result)) {
    return result;
  }
  return {
    id,
    params,
    components: new Set(result.result.componentIds),
    references: new Set(result.result.referenceIds),
  };
};

const activeDynamicFilter = routine(
  ofType(requestActivateDynamicFilter, setDynamicFilterParameters),
  extractPayload(),
  withLatestFrom(dynamicFilterStates$, dynamicFilters$),
  map(([{ id }, states, filters]) => {
    const params = states.filter(f => f.id === id).map(f => f.params)[0] || {};
    const filter = filters.find(f => f._id === id);
    if (
      filter &&
      filter.parameterQueryId &&
      Object.entries(params).length === 0
    ) {
      const error = 'Dynamic filter parameter missing.';
      dispatchAction(dynamicFilterError({ id, error }));
      return null;
    }
    return { id, params };
  }),
  excludeNullAndUndefined(),
  mergeMap(async ({ id, params }) => {
    dispatchAction(setDynamicFilterLoading({ id }));
    const result = await fetchIncludedIds(id, params);
    if (isArdoqError(result)) {
      const errorMessage = getArdoqErrorMessage(result);

      // catchError terminates the stream because of one defect filter, but there could be multiple
      // filters awaiting activation in loading state. Deactivate them all.
      dispatchAction(setAllDynamicFiltersInactive());
      dispatchAction(dynamicFilterError({ id, error: errorMessage }));

      const title = 'Error while loading dynamic filter';
      const commonText =
        'The affected perspective/slide would display incorrect results, as all dynamic filters are deactivated in case of a loading error.';

      alert({
        title,
        subtitle: 'Please recreate the current perspective/slide anew.',
        text: api.isNotFound(result)
          ? `The dynamic filter could have been deleted by someone in your organization. ${commonText}`
          : commonText,
        errorMessage,
      });
      logError(new Error(errorMessage), title);
      throw errorMessage;
    }
    return result;
  }),
  tap(activeFilter => {
    dispatchAction(setDynamicFilterIncludes(activeFilter));
  })
);

const handleSetAllDynamicFiltersInactive = routine(
  ofType(setAllDynamicFiltersInactive),
  withLatestFrom(dynamicFilterStates$),
  tap(([, dynmicFilterStates]) => {
    dynmicFilterStates.forEach(({ id }) => {
      dispatchAction(setDynamicFilterInactive({ id }));
    });
  })
);

// This routine re-fetches applied dynamic filters on components or references change
const refetchAppliedDynamicFilters = routine(
  ofType(
    notifyComponentsUpdated,
    notifyComponentsAdded,
    notifyReferencesAdded,
    notifyReferencesUpdated
  ),
  withLatestFrom(dynamicFilterStates$),
  debounceTime(2000),
  filter(() => hasFeature(Features.DYNAMIC_FILTERS_AUTO_REFETCH)),
  map(([, filters]) =>
    filters.filter(({ state }) => state === DynamicFilterSelectState.ACTIVE)
  ),
  mergeMap(filters =>
    merge(
      ...filters.map(async ({ id, params }) => {
        trackDynamicFilterReFetch({ filterId: id });
        const result = await fetchIncludedIds(id, params);
        if (isArdoqError(result)) {
          dispatchAction(setDynamicFilterInactive({ id }));
          dispatchAction(
            dynamicFilterError({ id, error: getArdoqErrorMessage(result) })
          );
          return result;
        }
        return result;
      })
    )
  ),
  tap(activeFilter => {
    if (isArdoqError(activeFilter)) {
      return;
    }
    dispatchAction(setDynamicFilterIncludes(activeFilter));
  })
);

const handleSetDynamicFilterIncludes = routine(
  ofType(setDynamicFilterIncludes),
  extractPayload(),
  tap(({ id }) => {
    dispatchAction(setDynamicFilterActive({ id }));
  })
);

const initializeParametersForFilters = routine(
  combineLatestWith(dynamicFilterStates$, dynamicFilters$, parameterOptions$),
  // Find queries where we have not yet fetched the parameter options
  map(([, states, filters, options]) => {
    const queryIds = new Set<string>();

    for (const state of states) {
      const filter = filters.find(f => f._id === state.id);
      if (!filter || isEmpty(state.params)) continue;

      const parameterQueryId = filter.parameterQueryId;
      if (!parameterQueryId) continue;

      const hasFetchedOptions = options.some(
        opts => opts.parameterQueryId === parameterQueryId
      );
      if (hasFetchedOptions) continue;

      queryIds.add(parameterQueryId);
    }

    return [...queryIds];
  }),
  distinctUntilChanged(isEqual as (a: string[], b: string[]) => boolean),
  tapEach(parameterQueryId => {
    dispatchAction(fetchParameterOptions({ parameterQueryId }));
  })
);

export default collectRoutines(
  handleSetAllDynamicFiltersInactive,
  activeDynamicFilter,
  handleSetDynamicFilterIncludes,
  initializeParametersForFilters,
  refetchAppliedDynamicFilters
);
