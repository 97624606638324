import { MouseEvent } from 'react';
import * as React from 'react';
import { Checkbox, Indentation } from './atoms';
import { clearSelection } from '@ardoq/common-helpers';
import { Selected } from 'aqTypes';
import { ArdoqId } from '@ardoq/api-types';

type SelectCellProps = {
  onCheckboxClick: (e: MouseEvent) => void;
  indentationLevel: number;
  isSelected: Selected;
  disabled?: boolean;
  rowId: ArdoqId;
};

const SelectCell = (props: SelectCellProps) => {
  return (
    <>
      <Indentation level={props.indentationLevel} />
      <Checkbox
        disabled={props.disabled}
        isSelected={props.isSelected}
        onClick={(e: React.MouseEvent<any>) => {
          clearSelection();
          props.onCheckboxClick(e);
        }}
      />
    </>
  );
};

export default SelectCell;
