import {
  collectRoutines,
  dispatchAction,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  fetchedOrgUsersSuccess,
  fetchOrgUsers,
  fetchOrgUsersError,
} from './actions';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { handleError, membershipApi } from '@ardoq/api';
import currentUser$ from 'streams/currentUser/currentUser$';

const fetchOrgUsersRoutine = routine(
  ofType(fetchOrgUsers),
  withLatestFrom(currentUser$),
  map(([, currentUser]) => currentUser.organization._id),
  switchMap(membershipApi.fetchAllUsers),
  handleError(error => {
    dispatchAction(fetchOrgUsersError(error));
  }),
  tap(users => {
    dispatchAction(fetchedOrgUsersSuccess(users));
  })
);

export const usersRoutines = collectRoutines(fetchOrgUsersRoutine);
