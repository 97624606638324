import { dispatchAction } from '@ardoq/rxbeach';
import { notifyPerspectivesDestroyed } from './FilterActions';
import { PerspectiveBackboneModel } from 'aqTypes';
import type PerspectiveBackboneModelCollection from 'collections/perspectives';

export default (Perspectives: typeof PerspectiveBackboneModelCollection) =>
  Perspectives.on('destroy', (perspective: PerspectiveBackboneModel) => {
    dispatchAction(
      notifyPerspectivesDestroyed({
        perspectiveIds: [perspective.id],
      })
    );
  });
