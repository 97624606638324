import {
  PermissionContext,
  permissionsOperations,
} from '@ardoq/access-control';
import { APICurrentUser, ArdoqId, Subdivision } from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';
import {
  SubdivisionsContext,
  subdivisionsOperations,
  subdivisionsZonesOperations,
} from '@ardoq/subdivisions';

/**
 * Check if the current user has the correct access to
 * access a zone (checks for permissions).
 * without checking if the subdivision is active.
 */
const canAccessZone = (
  permissionContext: PermissionContext,
  zoneId: ArdoqId
): boolean =>
  hasFeature(Features.PERMISSION_ZONES) &&
  subdivisionsZonesOperations.canAccessZone(permissionContext, zoneId);

/**
 * Check if the current user has the correct access to
 * edit a zone (checks for permissions).
 * without checking if the subdivision is active.
 */
const canEditZone = (
  permissionContext: PermissionContext,
  zoneId: ArdoqId
): boolean =>
  hasFeature(Features.PERMISSION_ZONES) &&
  subdivisionsZonesOperations.canEditZone(permissionContext, zoneId);

/**
 * Check if the current user has the correct access to
 * admin a zone (checks for permissions).
 * without checking if the subdivision is active.
 */
const canAdminZone = (
  permissionContext: PermissionContext,
  zoneId: ArdoqId
): boolean =>
  hasFeature(Features.PERMISSION_ZONES) &&
  subdivisionsZonesOperations.canAdminZone(permissionContext, zoneId);

/**
 * Check if the current user has the correct access to create a zone.
 * without checking if the subdivision is active.
 */
const canCreateZone = (permissionContext: PermissionContext): boolean =>
  hasFeature(Features.PERMISSION_ZONES) &&
  subdivisionsZonesOperations.canCreateZone(permissionContext);

/**
 * Check if the current user has the correct access to edit a zone or any of the provided zones.
 */
const hasEditableZone = (
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext
): boolean =>
  subdivisionsZonesOperations
    .getSortedZonesIds(subdivisionsContext)
    .some(zoneId => canEditZone(permissionContext, zoneId));

/**
 * Check if the current user has access to edit a at least one zone in the subdivision.
 * without checking if the subdivision is active.
 */
const canEditSubdivision = (
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext,
  subdivisionId: ArdoqId
): boolean => {
  const zoneIds = subdivisionsOperations.getZonesIdsInSubdivision(
    subdivisionsContext,
    subdivisionId
  );

  return zoneIds.some(zoneId => canEditZone(permissionContext, zoneId));
};

/**
 * @deprecated A hacky, temporary solution to show some items in the UI, only for admins (since they are not ready to be shown for writers).
 */
const shouldShowAdminItems = (
  currentUser: APICurrentUser | null,
  hasPermissionsZoneInternalFeatureFlag: boolean = hasFeature(
    Features.PERMISSION_ZONES_INTERNAL
  )
): boolean =>
  hasPermissionsZoneInternalFeatureFlag ||
  (!!currentUser && permissionsOperations.isOrgAdmin(currentUser));

const getEditableSubdivisionsList = (
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext
): Subdivision[] => {
  const subdivisions =
    subdivisionsOperations.getSubdivisionsList(subdivisionsContext);

  return subdivisions.filter(subdivision =>
    subdivisionAccessControlInterface.canEditSubdivision(
      permissionContext,
      subdivisionsContext,
      subdivision._id
    )
  );
};

export const subdivisionAccessControlInterface = {
  /**
   * Check if the current user has the correct access to
   * access a zone (checks for permissions).
   * without checking if the subdivision is active.
   */
  canAccessZone,

  /**
   * Check if the current user has the correct access to
   * edit a zone (checks for permissions).
   * without checking if the subdivision is active.
   */
  canEditZone,

  /**
   * Check if the current user has the correct access to
   * admin a zone (checks for permissions).
   * without checking if the subdivision is active.
   */
  canAdminZone,

  /**
   * Check if the current user has the correct access to create a zone.
   * without checking if the subdivision is active.
   */
  canCreateZone,

  /**
   * Check if the current user has the correct access to edit a zone or any of the provided zones.
   */
  hasEditableZone,

  /**
   * Check if the current user has access to edit a at least one zone in the subdivision.
   * without checking if the subdivision is active.
   */
  canEditSubdivision,
  /**
   * @deprecated A hacky, temporary solution to show some items in the UI, only for admins (since they are not ready to be shown for writers).
   */
  shouldShowAdminItems,
  /**
   * Get a list of subdivisions that the current user can edit.
   */
  getEditableSubdivisionsList,
};
