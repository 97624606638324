import { map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  toggleFavorite,
  toggleModelPreview,
  updateCurrentUser,
  updateAssetsBrowserPerPageSettings,
  updateHomepageViewpointsState,
} from './actions';
import {
  collectRoutines,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import currentUser from 'models/currentUser';
import { logError } from '@ardoq/logging';
import { alert } from '@ardoq/modal';
import currentUser$, { getFavorites } from './currentUser$';
import { APICurrentUser, PersonalSetting } from '@ardoq/api-types';
import { FavoriteAction, handleError, userApi } from '@ardoq/api';
import { ArdoqError } from '@ardoq/common-helpers';

const logErrorAndAlert = (error: ArdoqError) => {
  logError(error, 'Favorite asset failed');
  alert({
    title: 'Error',
    subtitle: 'Sorry, saving your favorites failed',
  });
};

const updateSettings = (user: APICurrentUser) => {
  currentUser.set('settings', user.settings);
  currentUser.trigger('sync');
};

const handleToggleFavorite = routine(
  ofType(toggleFavorite),
  extractPayload(),
  withLatestFrom(currentUser$),
  map(([payload, currentUser]) => {
    const favorites = getFavorites(currentUser);
    return {
      action: favorites[payload.type].includes(payload._id)
        ? FavoriteAction.REMOVE
        : FavoriteAction.ADD,
      payload,
      userId: currentUser._id,
    };
  }),
  mergeMap(userApi.updateFavorite),
  handleError(logErrorAndAlert),
  tap(updateSettings)
);

const handleToggleModelPreview = routine(
  ofType(toggleModelPreview),
  withLatestFrom(currentUser$),
  map(([, currentUser]) => {
    const settings = currentUser.settings;
    return {
      userId: currentUser._id,
      settings: {
        ...settings,
        [PersonalSetting.HIDE_MODEL_PREVIEW]:
          !settings[PersonalSetting.HIDE_MODEL_PREVIEW],
      },
    };
  }),
  mergeMap(userApi.updateSettings),
  handleError(logErrorAndAlert),
  tap(updateSettings)
);

const handleUpdateCurrentUser = routine(
  ofType(updateCurrentUser),
  tap(() => currentUser.fetch())
);

const handleChangeAssetsBrowserPerPage = routine(
  ofType(updateAssetsBrowserPerPageSettings),
  extractPayload(),
  withLatestFrom(currentUser$),
  map(([{ appModule, perPage }, currentUser]) => {
    const settings = currentUser.settings;
    return {
      userId: currentUser._id,
      settings: {
        ...settings,
        [PersonalSetting.ASSETS_BROWSER_PER_PAGE]: {
          ...settings[PersonalSetting.ASSETS_BROWSER_PER_PAGE],
          [appModule]: perPage,
        },
      },
    };
  }),
  mergeMap(userApi.updateSettings),
  handleError(logErrorAndAlert),
  tap(updateSettings)
);

const handleHomepageViewpointsState = routine(
  ofType(updateHomepageViewpointsState),
  extractPayload(),
  withLatestFrom(currentUser$),
  map(([viewpointsState, currentUser]) => {
    const settings = currentUser.settings;
    return {
      userId: currentUser._id,
      settings: {
        ...settings,
        [PersonalSetting.HOMEPAGE_VIEWPOINTS_STATE]: {
          ...settings[PersonalSetting.HOMEPAGE_VIEWPOINTS_STATE],
          ...viewpointsState,
        },
      },
    };
  }),
  switchMap(userApi.updateSettings),
  handleError(logErrorAndAlert),
  tap(updateSettings)
);

export const currentUserRoutines = collectRoutines(
  handleToggleFavorite,
  handleToggleModelPreview,
  handleUpdateCurrentUser,
  handleChangeAssetsBrowserPerPage,
  handleHomepageViewpointsState
);
