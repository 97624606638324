import { isPresentationMode } from 'appConfig';
import {
  deleteReference,
  reverseReference,
  selectReference,
  toggleLockReference,
} from './ReferenceActions';
import References from 'collections/references';
import Context from 'context';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap } from 'rxjs/operators';
import { launchDeleteComponentsAndReferencesConfirmationDialog } from 'components/Dialogs/confirmDeletion/confirmDeletion';
import { reverseReferences } from 'components/Dialogs/reverseReferences/reverseReferences';
import { selectNodeInPresentationMode } from 'presentation/slideNavigator/actions';

const selectReferenceHandler = routine(
  ofType(selectReference),
  extractPayload(),
  tap(({ cid }) => {
    const reference = References.collection.get(cid);
    if (isPresentationMode()) {
      if (reference) {
        dispatchAction(selectNodeInPresentationMode({ node: reference }));
      }
      if (!Context.isExploreMode()) {
        return;
      }
    }
    Context.setReference(reference ?? null);
  })
);

const handleDeleteReference = routine(
  ofType(deleteReference),
  extractPayload(),
  tap(id => {
    const reference = References.collection.get(id);
    if (reference) {
      launchDeleteComponentsAndReferencesConfirmationDialog({
        references: reference,
      });
    }
  })
);

const handleToggleLockReference = routine(
  ofType(toggleLockReference),
  extractPayload(),
  tap(id => {
    const reference = References.collection.get(id);
    if (reference) {
      if (reference.getLock()) {
        reference.unlock();
      } else {
        reference.lock();
      }
    }
  })
);

const handleReverseReference = routine(
  ofType(reverseReference),
  extractPayload(),
  tap(id => {
    const reference = References.collection.get(id);
    if (reference) {
      reverseReferences([reference]);
    }
  })
);

export default collectRoutines(
  selectReferenceHandler,
  handleDeleteReference,
  handleReverseReference,
  handleToggleLockReference
);
