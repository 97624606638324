import { isPresentationMode } from 'appConfig';
import {
  createSurveyFromComponent,
  deleteComponent,
  forceUpdateComponent,
  selectComponent,
  sortComponents,
  sortComponentsByReferences,
  toggleLockComponent,
} from './ComponentActions';
import Components from 'collections/components';
import Context from 'context';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap } from 'rxjs/operators';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { launchDeleteComponentsAndReferencesConfirmationDialog } from 'components/Dialogs/confirmDeletion/confirmDeletion';
import { createFromSelectedComponent } from 'surveyAdmin/surveyUtil';
import { selectNodeInPresentationMode } from 'presentation/slideNavigator/actions';

const selectComponentHandler = routine(
  ofType(selectComponent),
  extractPayload(),
  tap(({ cid }) => {
    const component = Components.collection.get(cid);
    if (component && componentInterface.isScenarioRelated(component.id)) {
      return;
    }

    if (isPresentationMode()) {
      if (component) {
        dispatchAction(selectNodeInPresentationMode({ node: component }));
      }
      if (!Context.isExploreMode()) {
        return;
      }
    }

    Context.setComponent(component, 'componentSelection');
  })
);
const forceUpdateComponentHandler = routine(
  ofType(forceUpdateComponent),
  extractPayload(),
  tap(({ cid, trackingLocation }) => {
    const component = Components.collection.get(cid);
    if (!component) {
      return;
    }
    Context.forceUpdateComponent(component, trackingLocation);
  })
);
const sortComponentsByReferencesHandler = routine(
  ofType(sortComponentsByReferences),
  extractPayload(),
  tap(sort => Context.sortComponentsByReferences(sort))
);

const sortComponentsHandler = routine(
  ofType(sortComponents),
  extractPayload(),
  tap(({ attr, name, order }) => Context.setSort(attr, name, order))
);

const handleDeleteComponent = routine(
  ofType(deleteComponent),
  extractPayload(),
  tap(id => {
    const component = Components.collection.get(id);
    if (component) {
      launchDeleteComponentsAndReferencesConfirmationDialog({
        components: component,
      });
    }
  })
);

const handleToggleLockComponent = routine(
  ofType(toggleLockComponent),
  extractPayload(),
  tap(id => {
    const component = Components.collection.get(id);
    if (component) {
      if (component.getLock()) {
        component.unlock();
      } else {
        component.lock();
      }
    }
  })
);

const handleCreateSurveyFromComponent = routine(
  ofType(createSurveyFromComponent),
  extractPayload(),
  tap(id => {
    const component = Components.collection.get(id);
    if (component) {
      createFromSelectedComponent();
    }
  })
);

export default collectRoutines(
  selectComponentHandler,
  forceUpdateComponentHandler,
  sortComponentsByReferencesHandler,
  sortComponentsHandler,
  handleDeleteComponent,
  handleToggleLockComponent,
  handleCreateSurveyFromComponent
);
