import { persistentReducedStream } from '@ardoq/rxbeach';
import { defaultState, reducers } from './reducers';

const responseLog$ = persistentReducedStream(
  'responseLog$',
  defaultState,
  reducers
);

export default responseLog$;
